import { useState } from "react";
import { useDispatch } from "react-redux";
import { addToFavorites,removeFromFavorites } from "../store/slices/favoriteSlice";
import { updateFavorite } from "../store/slices/GalleryImagesSlice";

const useGalleryImage = () => {
//FIXME need to remove
  const [toBeDragged, setToBeDragged] = useState(false);
  const dispatch = useDispatch();

  const handleMouseDown = () => {
    setToBeDragged(true);
  };

  const handleMouseUp = () => {
    setToBeDragged(false);
  };

  const handleClickFavorite = async (Image) => {
    // let newItem = sections[currentTab].data.filter((item, i) => item.id == id);
    // if (newItem[0].favourite == 0) {
      const resultAction = await dispatch(addToFavorites({ Image: Image }))
        if (addToFavorites.fulfilled.match(resultAction)) {
          dispatch(updateFavorite({id:Image.id,favorite:1}))
        }

    // } else {
    //   dispatch(removeFromFavorites({ id: id }));
    // }
    // dispatch(favoriteActions(id));
    // let obj1 = {
    //   url: "list-favourite",
    //   page: "1",
    //   slug: "desc",
    // };

    // let keyArr = Object.keys(sections);
    // if (currentTab == "all" && !keyArr.includes("favorites")) {
    //   dispatch(updateImageFunctions(obj1));
    // }
  };

  const handleRemoveFromFavorites = async (id) => {
    const resultAction = await dispatch(removeFromFavorites({id:id}))
    if (removeFromFavorites.fulfilled.match(resultAction)) {
      dispatch(updateFavorite({id:id,favorite:0}))
    }
  }

  return {
    toBeDragged,
    setToBeDragged,
    handleMouseDown,
    handleMouseUp,
    handleClickFavorite,
    handleRemoveFromFavorites
  };
};

export default useGalleryImage;
