import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import Style from "./Popup.module.scss";
import SelectMother from "./SelectMother";
import UseFamilyPopup from "../../../Logic/FamilyTree/useFamilyPopup";
import { useDimensions } from "../../../Logic/Dimensions";
import BackHeader from "../../BackHeader";
import { toast } from "react-toastify";
import SelectChild from "./SelectChild";

const Popup = (props) => {
  const {
    handleClose,
    popupData,
    data,
    currentUserData,
    handleNewNode,
    setSelectParent,
    selectParent,
    setSelectChild,
    selectChild,
    open,
    checkMotherAndFatherExist,
  } = UseFamilyPopup(props);

  const openClass = open ? Style.open : "";
  const windowDimensions = useDimensions();

  const nodePartners = useMemo(() => {
    let partners = data?.[0]?.allData?.filter((d) => {
      return d?.pids?.includes(currentUserData.id);
    });
    return partners;
  }, []);

  return ReactDOM.createPortal(
    <div className={`${Style.popupWrapper} ${openClass}`}>
      {windowDimensions.width >= 992 ? (
        <div className={Style.closeWrapper}>
          <button onClick={handleClose}>close</button>
        </div>
      ) : (
        <BackHeader onClick={handleClose} />
      )}

      <div className={Style.listWrapper}>
        <div className={Style.listTop}>
          {windowDimensions.width < 992 && (
            <div className={Style.mobileHead}>
              <h2 className={Style.mobileHead_title}>Add a family member</h2>
              <p className={Style.mobileHead_description}>
                Pick a member to add
              </p>
            </div>
          )}
          <div
            className={`${Style.listMain} ${
              selectParent && Style.parent_active
            }`}
          >
            {selectChild && (
              <SelectChild data={data} handleNewNode={handleNewNode} />
            )}
            <div className={Style.listInner}>
              {/* loop popup data */}
              {popupData.map((connection, index) => {
                {
                  /* user node */
                }
                const MainNode = ({ childData }) => {
                  return (
                    <div className={Style.nodeMainInner} key={childData.id}>
                      <div className={Style.nodeImage}>
                        <div className={Style.nodeImageContainer}>
                          <img
                            src={
                              data && currentUserData.img
                                ? currentUserData.img
                                : "https://admin-familyphotos.webc.in/public/image/default.png"
                            }
                            alt="user_image"
                          />
                        </div>
                      </div>
                      <div className={Style.nodeContent}>
                        <h3 className={Style.title}>
                          {data && currentUserData.name}
                        </h3>
                        <p className={Style.para}>
                          {data && currentUserData.family_name}
                        </p>
                      </div>
                    </div>
                  );
                };

                {
                  /* child node  parent - sibling - child - father - mother (handleNewNode function pass connection string) */
                }
                const ChildNode = ({ childData }) => {
                  const allData = data[0].allData;
                  const userData = data[0].userData[0];
                  const hasChildrenWithoutParent = allData.filter((d) => {
                    return (
                      (d.mid == userData.id && !d.fid) ||
                      (d.fid == userData.id && !d.mid)
                    );
                  });
                  return (
                    <button
                      className={`${Style.nodeItem} ${
                        childData.connection === "sibling" &&
                        checkMotherAndFatherExist?.length === 0
                          ? Style.disabled
                          : ""
                      } ${
                        (childData.connection === "children" && selectParent) ||
                        (childData.connection === "partner" && selectChild)
                          ? Style.Active
                          : ""
                      }`}
                      key={childData.id}
                      onClick={() => {
                        if (
                          childData.connection === "sibling" &&
                          checkMotherAndFatherExist?.length === 0
                        ) {
                          toast.warning("Add a parent first!", {
                            position: "bottom-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        } else {
                          if (
                            childData.connection === "children" &&
                            nodePartners?.length > 0
                          ) {
                            setSelectParent(true);
                            setSelectChild(false);
                          } else if (
                            childData.connection === "partner" &&
                            hasChildrenWithoutParent.length !== 0
                          ) {
                            setSelectChild(true);
                            setSelectParent(false);
                          } else {
                            handleNewNode({
                              connection: childData.connection,
                              connectionWithNode: childData.connectionWithNode,
                            });
                          }
                        }
                      }}
                    >
                      <div className={Style.nodeImage}>
                        <div className={Style.nodeImageContainer}>
                          <img src={childData && childData.img} alt="" />
                        </div>
                      </div>
                      <div className={Style.nodeName}>
                        {childData && childData.connectionName}
                      </div>
                    </button>
                  );
                };

                {
                  /* node wrapper structure */
                }
                const Structure = ({ connectionData }) => {
                  {
                    /* check last child */
                  }
                  const extraClass =
                    popupData.length === index + 1
                      ? Style.extraNode
                      : Style.nodeWrapper;

                  return (
                    <>
                      {connectionData.user ? (
                        <div className={Style.nodeMain} key={connectionData.id}>
                          {connectionData.data.map((child) => {
                            return (
                              <MainNode childData={child} key={child.id} />
                            );
                          })}
                        </div>
                      ) : (
                        <div className={extraClass} key={connectionData.id}>
                          <ul className={Style.nodeGroup}>
                            <li className={Style.nodeList}>
                              {connectionData.data.map((child) => {
                                return (
                                  <ChildNode childData={child} key={child.id} />
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  );
                };

                return (
                  <Structure connectionData={connection} key={connection.id} />
                );
              })}
            </div>
            {selectParent && (
              <SelectMother data={data} handleNewNode={handleNewNode} />
            )}
          </div>
        </div>
        {windowDimensions.width < 992 && (
          <div className={Style.continue_btn_wrap}>
            <button className="btn btn-secondary">Continue</button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default Popup;
