import CircularProgressBar from "../../../../../../utils/CircularProgressLoader";
import Style from "./Thumb.module.scss";
import { useItemProgressListener } from "@rpldy/uploady";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Assets from "../../../../../Layouts/Assets";

const Thumb = ({ image, invalid, duplicate }) => {
  const { completed } = useItemProgressListener(image?.id) || {
    completed: image?.thumbnail ? 100 : 0,
  };
  const src =
    image?.preview_url ?? image?.thumbnail
      ? image?.thumbnail
      : duplicate
      ? Assets?.duplicate_icon
      : Assets?.fpa_loader;

  const validImage = (props) => (
    <Tooltip id="button-tooltip" {...props} variant={"warning"}>
      {image?.file?.name} is {Math.ceil(completed)}% completed
    </Tooltip>
  );

  const duplicateImage = (props) => (
    <Tooltip id="button-tooltip" {...props} variant={"warning"}>
      {duplicate} already exists
    </Tooltip>
  );

  const invalidImage = (props) => (
    <Tooltip id="invalid-tooltip" {...props} variant={"warning"}>
      {invalid} is invalid
    </Tooltip>
  );

  return (
    <>
      {image && (
        <>
          {" "}
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 80 }}
            overlay={validImage}
          >
            <figure className={`${Style.file_upload_preview_image}`}>
              <img src={src} alt="preview_image" />
            </figure>
          </OverlayTrigger>
          {/**#FIXME  Cancel upload? */}
          {/* {i !== activeUploadIndex && a.status == "idle" && (
        <button
          // onClick={() => cancelUpload(a, i)}
          className={Style.file_upload_delete}
        >
          <Icons icon={"icon-close"} size={5} />
        </button>
        )}  */}
          {!image?.thumbnail && (
            <CircularProgressBar
              strokeWidth="4"
              sqSize="30"
              percentage={completed}
            />
          )}
          {image?.thumbnail && (
            //FIXME handle invalid files
            <div className={Style.success_indicator}>
              <span className={Style.success_check}></span>
            </div>
          )}
        </>
      )}
      {duplicate && (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 80 }}
          overlay={duplicateImage}
        >
          <figure
            className={`${Style.file_upload_preview_image} ${Style.dupliaction} `}
          >
            <img src={src} alt="preview_image" />
          </figure>
        </OverlayTrigger>
      )}
      {invalid && (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 80 }}
          overlay={invalidImage}
        >
          <figure
            className={`${Style.file_upload_preview_image} ${Style.invalidImage} `}
          >
            <img src={src} alt="preview_image" />
          </figure>
        </OverlayTrigger>
      )}
    </>
  );
};

export default Thumb;
