import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
} from "../store/slices/authSlice";


import { GetProfileUpdateInfo } from "../store/slices/profileInfoUpdateSlice";

export const ProfileUpdateInfo = () => {
  const dispatch = useDispatch();

  const [updateProfileInfo, setUpdateProfileInfo] = useState({});
  const [isProfileInfoLoading, setIsProfileInfoLoading] = useState(true);
  const profileUpdateInfo = useSelector((state) => state.profileUpdateInfo)

  useEffect(() => {
    GetProfileUpdateInfoTrigger();
  }, []);

  const GetProfileUpdateInfoTrigger = () => {
   
        dispatch(
          GetProfileUpdateInfo()
        )
        .unwrap()
        .then((res) => {
          const { success,result } = res?.data;
          if (success && success !== undefined) {
            setUpdateProfileInfo(result);
            setIsProfileInfoLoading(false);
          }
        });
    
  };

  return {
    GetProfileUpdateInfoTrigger,
    updateProfileInfo,
    isProfileInfoLoading,
    setUpdateProfileInfo,
    setIsProfileInfoLoading,
    profileUpdateInfo
  };
};
