import React, { useEffect, useState } from "react";
import Style from "./PreLoginMobileMenu.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Assets from "../../Assets";
import Icons from "../../Icons";
import SignInModal from "../../../Modals/SignInModal";
import RegisterModal from "../../../Modals/RegisterModal";
import ForgotPasswordModal from "../../../Modals/ForgotPasswordModal";
import OTPVerificationModal from "../../../Modals/OTPVerificationModal";
import LanguageMenu from "../../LanguageMenu/LanguageMenu";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  showSignInState,
  showforgotpasswordState,
  logout,
  changeScreen,
  storeCurrentAuthData,
  authModalHandler,
} from "../../../../store/slices/authSlice";
import CommonModal from "../../../Modals/commonModal";
import AuthenticationModal from "../../../Modals/AuthenticationModal";
import { getProfileDetails } from "../../../../store/slices/userprofileSlice";
import { useRegister } from "../../../../Logic/useRegister";
import SearchBar from "../../../SearchBar";
import { clearGalleryState } from "../../../../store/slices/gallerySlice";
import { useDimensions } from "../../../../Logic/Dimensions";
import { useAuthHandler } from "../../../../Logic/Auth/useAuthHandler";
import useAuthChecker from "../../../../Logic/useAuthChecker";
import { usePurchasedChecker } from "../../../../Logic/AuthChecker/usePurchaseChecker";
import { useLanguage } from "../../../../Logic/useLanguage";

const EditModals = React.lazy(() => import("../../../Modals/Modals"));

const PreLoginMobileMenu = ({
  showSignIn,
  setShowSignIn,
  showRegister,
  setShowRegister,
  showForgotPassword,
}) => {
  const {
    showModal,
    setShowModal,
    showFP,
    setShowFP,
    showOtp,
    setShowOtp,
    registerStatus,
    loginStatus,
  } = useRegister();

  let navigate = useNavigate();

  const { isAuthCompleted } = useAuthChecker();
  const { planData, isPlanActive } = usePurchasedChecker();
  const { languageSwitch } = useLanguage();

  const {
    handleClickRegister,
    handleClickLogin,
    handleClickFP,
    handleClose,
    handleLogout,
  } = useAuthHandler();

  const dispatch = useDispatch();
  const [language, setLanguage] = useState("English");

  const [showMenu, setShowMenu] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const windowDimensions = useDimensions();

  const { openAuthModal, currentScreen, registerData, loginCreateOtpStatus } =
    useSelector((state) => state.auth);
  const { userdata } = useSelector((store) => store.userprofile);

  const { i18n, t } = useTranslation(["common"]);
  let currLan = localStorage.getItem("currentLanguage");

  useEffect(() => {
    if (Object.keys(userdata).length == 0) {
      dispatch(getProfileDetails(registerData?.user_id));
    }
  }, [registerData]);

  const handleScrollToTop = (route) => {
    location.pathname === `/${route ? route : ""}` &&
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const homePage = document.querySelector(".home-page");
    const footerSec = document.querySelector(".footer-section");
    showMenu
      ? homePage?.classList.add("lock-scroll-home")
      : homePage?.classList.remove("lock-scroll-home");
    showMenu
      ? footerSec?.classList.add("lock-scroll-footer")
      : footerSec?.classList.remove("lock-scroll-footer");
  }, [showMenu]);

  useEffect(() => {
    const homePage = document.querySelector(".home-page");
    const footerSec = document.querySelector(".footer-section");
    showModal
      ? homePage?.classList.add("lock-scroll-home-modal")
      : homePage?.classList.remove("lock-scroll-home-modal");
    showModal
      ? footerSec?.classList.add("lock-scroll-footer-modal")
      : footerSec?.classList.remove("lock-scroll-footer-modal");
  }, [showModal]);

  useEffect(() => {
    showModal && setShowMenu(false);
  }, [showModal]);

  useEffect(() => {
    switch (currLan) {
      case "english":
        setLanguage("English");
        break;
      case "fr":
        setLanguage("Français");
        break;
      case "esp":
        setLanguage("Español");
        break;
      case "chi":
        setLanguage("日本語");
        break;
      case "pol":
        setLanguage("Pусский");
        break;
      case "ity":
        setLanguage("Italiano");
        break;

      default:
        setLanguage("English");
        break;
    }
  }, [currLan]);

  // const languageSwitch = (language, flag, code) => {
  //   setLanguage(language);
  //   // setFlag(flag);
  //   i18n.changeLanguage(code);
  // };

  const handleClickLogout = () => {
    setOpenModal(true);
  };

  const handleCheckPath = (path) => {
    if (isAuthCompleted === false) {
      handleClickLogin();
    } else if (isAuthCompleted && !isPlanActive) {
      navigate("/choose-plan");
    } else {
      navigate(path);
    }
  };

  const handleCloseAuthModal = () => {
    dispatch(authModalHandler(false));
    dispatch(storeCurrentAuthData({}));
  };

  const renderScreen = (page) => {
    switch (page) {
      case "register":
        return (
          <RegisterModal
            handleClickLogin={handleClickLogin}
            handleClickFP={handleClickFP}
            status={registerStatus}
            handleClose={handleClose}
          />
        );
        break;
      case "login":
        return (
          <SignInModal
            setShowSignIn={setShowSignIn}
            handleClickRegister={handleClickRegister}
            handleClickFP={handleClickFP}
            status={loginStatus}
            loginWithPhoneStatus={loginCreateOtpStatus}
          />
        );
        break;
      case "forgotPassword":
        return (
          <ForgotPasswordModal
            setShowSignIn={setShowSignIn}
            setShowFP={setShowFP}
          />
        );
      case "otp":
        return <OTPVerificationModal />;
    }
  };

  const location = useLocation();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchCategory, setSearchCategory] = useState("Photos");

  const searchCategorySwitch = (category) => {
    setSearchCategory(category);
  };

  return (
    <>
      <header className={Style.prelogin_mobile_header}>
        {showLang ? (
          <LanguageMenu
            showLang={showLang}
            setShowLang={setShowLang}
            languageSwitch={languageSwitch}
            currentLanguage={language}
            setLanguage={setLanguage}
          />
        ) : (
          <></>
        )}
        <div className="container">
          <div className={Style.prelogin_mobile_header_wrap}>
            {isSearchEnabled ? (
              <>
                <SearchBar
                  searchCategory={searchCategory}
                  searchCategorySwitch={searchCategorySwitch}
                  extraClass={"menu-search"}
                  noDark={false}
                  autoFocus={true}
                  fullscreenResults={true}
                  hasMobile={true}
                />
                <button
                  className="search-close-btn"
                  onClick={() => setIsSearchEnabled(false)}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <figure className={Style.prelogin_mobile_header_logo}>
                  <Link to={"/"} title={"Family Photos"}>
                    <img
                      src={
                        localStorage.getItem("theme") == "light"
                          ? Assets.fpa_main_logo_dark
                          : Assets.fpa_main_logo
                      }
                      alt="Family Photos"
                    />
                  </Link>
                </figure>
                <div className={Style.prelogin_mobile_header_nav}>
                  <div
                    className={Style.prelogin_mobile_header_search}
                    onClick={() => setIsSearchEnabled(true)}
                  >
                    <Icons icon={"search"} size={22} />
                  </div>
                  <button
                    className={Style.prelogin_mobile_header_hamburger}
                    onClick={() => setShowMenu(true)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <nav
          className={`${Style.prelogin_mobile_header_menu} ${
            showMenu ? Style.menu_open : ""
          }`}
        >
          {windowDimensions.width < 576 ? (
            <>
              <figure
                className={`${Style.prelogin_mobile_header_logo} ${Style.prelogin_mobile_header_logo_menu}`}
              >
                <Link to={"/"} title={"Family Photos"}>
                  <img
                    src={
                      localStorage.getItem("theme") == "light"
                        ? Assets.fpa_main_logo_dark
                        : Assets.fpa_main_logo
                    }
                    alt="Family Photos"
                  />
                </Link>
              </figure>
            </>
          ) : (
            <></>
          )}
          <button
            className={Style.close_menu}
            onClick={() => setShowMenu(false)}
          >
            <Icons icon={"icon-close"} size={12} />
          </button>
          <div className={`container ${Style.main_menu_container}`}>
            <div className={Style.prelogin_mobile_header_menu_wrap}>
              <ul className={Style.prelogin_mobile_header_links}>
                <li className={location.pathname == "/" ? Style.active : ""}>
                  <Link to={"/"} onClick={() => setShowMenu(false)}>
                    {t("home")}
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleCheckPath("/dashboard");
                    }}
                  >
                    {t("upload_photos")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleCheckPath("/dashboard/family-tree");
                    }}
                  >
                    {t("family_tree")}
                  </a>
                </li>
                <li
                  className={
                    location.pathname == "/contact" ? Style.active : ""
                  }
                >
                  <Link
                    to={"/contact"}
                    onClick={() => handleScrollToTop("contact")}
                  >
                    {t("contact")}
                  </Link>
                </li>
                <li
                  className={
                    location.pathname == "/contact" ? Style.active : ""
                  }
                >
                  <Link onClick={() => handleClickRegister()}>
                    {t("register")}
                  </Link>
                </li>
                <li className={Style.submenu}>
                  <button
                    onClick={() => {
                      setShowLang(true);
                    }}
                  >
                    {t("language")}
                  </button>
                </li>
              </ul>
              <div className={Style.prelogin_mobile_header_buttons}>
                <button className="btn btn-tertiary">
                  <Link to="/quick-demo"></Link>
                  {t("get_a_demo")}
                </button>
                {registerData?.name ? (
                  <button
                    className="btn btn-border"
                    onClick={() => handleClickLogout()}
                  >
                    {t("logout")}
                  </button>
                ) : (
                  <button
                    className="btn btn-border"
                    onClick={() => handleClickLogin()}
                  >
                    {t("login")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </nav>
        <div
          className={Style.menu_overlay}
          onClick={() => setShowMenu(false)}
        ></div>
      </header>
      <AuthenticationModal
        showModal={openAuthModal}
        setShowModal={handleCloseAuthModal}
        className="modal-fullscreen"
      >
        {renderScreen(currentScreen)}
      </AuthenticationModal>
      <CommonModal
        showModal={openModal}
        hide={() => setOpenModal(false)}
        className="modal-width logout-modal"
        content={
          <>
            <div className={Style.logoutSection}>
              <div className={`${Style.title} mb-4`}>Are You Sure?</div>

              <div
                className={`w-100 text-center ${Style.logoutsection_btn_wrap}`}
              >
                <button
                  onClick={() => handleLogout()}
                  className="btn btn-primary"
                >
                  Yes
                </button>
                <button
                  onClick={() => setOpenModal(false)}
                  className="btn btn-light"
                >
                  No
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PreLoginMobileMenu;
