import React from 'react';
import Icons from "../Layouts/Icons";
import { Dropdown } from "react-bootstrap";
import IconList from "../Layouts/Icons/selection.json";
import Style from "./StyleGuide.module.scss";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const StyleGuide = () => {
  const iconData = IconList.icons.map((data, i) => {
    return {
      name: data.properties.name,
      size: 30,
    };
  });
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    copyToast();
  };
  const copyToast = () => {
    toast.success("Copied to clipboard", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className='my-5'>
      <div className="container">
        <div className="d-flex flex-wrap align-items-center p-4 bg-light rounded">
          <div className='ms-2'>
            <button className='btn btn-primary'>Button Primary</button>
          </div>
          <div className='ms-2'>
            <button className='btn btn-white'>Button White</button>
          </div>
          <div className='ms-2'>
            <button className='btn btn-border'>Button Border</button>
          </div>
          <div className='ms-2'>
            <button className='btn btn-border-primary'>Button Border</button>
          </div>
          <div className='ms-2'>
            <button className='btn btn-secondary' disabled>Button Disabled</button>
          </div>
          <div className='ms-2'>
            <button className='btn btn-secondary'>Button Secondary</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-tertiary'>Button Tertiary</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-sm btn-red'>Button Red</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-sm btn-green'>Button Green</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-sm btn-border btn-border-grey'>Button Border Grey</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-sm btn-outline-grey'>Button outline Grey</button>
          </div>
          <div className='ms-2 pt-3'>
            <button className='btn btn-sm btn-outline-black'>Button outline Black</button>
          </div>
        </div>
        <div className='my-5'>
            <div className='container'>
              <div className='d-flex align-items-center'>
                <div className='ms-2 pt-3'>
                  <a href="#" className='btn-link'>Button Link</a>
                </div>
                <div className='ms-2 pt-3'>
                  <a href="#" className='btn-link green'>Button Link</a>
                </div>
                <div className='ms-2 pt-3'>
                  <a href="#" className='btn-link blue'>Button Link</a>
                </div>
              </div>
          </div>
        </div>
        <div className='my-5'>
            <div className='container'>
              <div className='d-flex align-items-center'>
                <div>
                  <label className="toggle-switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className='ms-2'>
                  <label className="custom-checkbox">
                    <input type="radio" />
                    <div className="checkmark"></div>
                    <span>Radio Button</span>
                  </label>
                </div>
              </div>
          </div>
        </div>
        <div className="p-4 bg-white rounded">
          <div className='row'>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="input_test">Email address</label>
                <div className="input-holder">
                  <input type="text" name="input_test" id="input_test" className='form-control' placeholder='Lorem Ipsum Dummy' />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group error">
                <label htmlFor="input_test">Email address</label>
                <div className="input-holder">
                  <input type="email" name="input_test" id="input_test" className='form-control' placeholder='Lorem Ipsum Dummy' />
                </div>
                <small>Please enter valid Email ID</small>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group error">
                <label htmlFor="input_test">Password</label>
                <div className="input-holder">
                  <input type="password" name="input_test" id="input_test" className='form-control' placeholder='Lorem Ipsum Dummy' />
                  <button className='eye'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.192" height="15.192" viewBox="0 0 15.192 15.192">
                    <g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(1.061 1.061)">
                      <path id="Path_68039" data-name="Path 68039" d="M11.564,14.282a5.983,5.983,0,0,1-3.529,1.224c-4.159,0-6.535-4.753-6.535-4.753A10.961,10.961,0,0,1,4.506,7.224M6.788,6.143A5.418,5.418,0,0,1,8.035,6c4.159,0,6.535,4.753,6.535,4.753a10.991,10.991,0,0,1-1.283,1.9m-3.992-.636A1.782,1.782,0,1,1,6.776,9.493" transform="translate(-1.5 -4.218)" fill="none" stroke="#a2a4ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path id="Path_68040" data-name="Path 68040" d="M1.5,1.5,14.57,14.57" transform="translate(-1.5 -1.5)" fill="none" stroke="#a2a4ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </svg>

                  </button>
                </div>
                <small className='error-note'>*Invalid login or password. You may need to reset your password.</small>
              </div>
            </div>
          </div>
        </div>
        <div className='my-5'>
          <div className="form-group">
            <label htmlFor="input_test">OTP Send to mobile</label>
            <div id="otp" class="otp-field">
              <input class="form-control" type="number" id="first" maxlength="1" />
              <input class="form-control" type="number" id="second" maxlength="1" />
              <input class="form-control" type="number" id="third" maxlength="1" />
              <input class="form-control" type="number" id="fourth" maxlength="1" />
            </div>
          </div>
        </div>
        <div className="my-5 bg-light p-3">
          <div className="search_bar_search">
            <div className="search_bar_search_icon">
              <Icons icon={"search"} size={17} />
            </div>
            <input type="text" placeholder="Search Here" className="search_bar_search_input" />
            <div className="search_bar_search_dropdown">
              <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle className="search_bar_toggle">
                  <p>Photos</p>
                  {/* <div className={Style.search_bar_icon}></div> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Item</Dropdown.Item>
                  <Dropdown.Item>Item</Dropdown.Item>
                  <Dropdown.Item>Item</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="my-5 bg-light p-3">
          <h6>Icons</h6>
          <div className={`${Style.section_content} row g-2`}>
            {iconData.map((data, i) => {
              return (
                <div
                  className={`${Style.ItemCardWrap} col-lg-2 col-md-3 col-sm-4 col-6`}
                  key={i}
                >
                  <div className={Style.ItemCard}>
                    <Icons size={data.size} icon={data.name} />
                    <h4
                      className={Style.ItemCard__icon}
                      onClick={() => handleCopy(data.name)}
                      title={"Click to copy class"}
                    >
                      {data.name}
                    </h4>
                    <button
                      className={Style.ItemCard__copy}
                      onClick={() =>
                        handleCopy(
                          `<Icons color='#000' size={16} icon={'${data.name}'} />`
                        )
                      }
                    >
                      <Icons color={"#fff"} size={16} icon={"copy"} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="my-4">
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
        </div>
        <div className="my-4">
          <div className="bg-dark p-5 mb-3">
            <div className='section_title_block title_block_white'>
              <h2>Title Block White</h2>
              <p>
                Sunt cupidatat voluptate labore exercitation cillum id proident laboris adipisicing eu eu. Minim laboris sint do cillum voluptate adipisicing laboris commodo amet incididunt aliqua eu veniam Lorem. Anim est qui sit culpa. Tempor duis deserunt et sunt. Commodo incididunt Lorem anim amet sit culpa.
              </p>
            </div>
          </div>
          <div className="bg-light p-5 mb-3">
            <div className='section_title_block'>
              <h2>Title Block</h2>
              <p>
                Sunt cupidatat voluptate labore exercitation cillum id proident laboris adipisicing eu eu. Minim laboris sint do cillum voluptate adipisicing laboris commodo amet incididunt aliqua eu veniam Lorem. Anim est qui sit culpa. Tempor duis deserunt et sunt. Commodo incididunt Lorem anim amet sit culpa.
              </p>
            </div>
          </div>
          <div className='section_title_block center p-5'>
            <h2>Title Centre</h2>
            <p>
              Sunt cupidatat voluptate labore exercitation cillum id proident laboris adipisicing eu eu. Minim laboris sint do cillum voluptate adipisicing laboris commodo amet incididunt aliqua eu veniam Lorem. Anim est qui sit culpa. Tempor duis deserunt et sunt. Commodo incididunt Lorem anim amet sit culpa.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StyleGuide