import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import Icons from "../Layouts/Icons";
import Style from "./SearchResults.module.scss";
import { useSelector } from "react-redux";
import { useSearch } from "../../Logic/useSearch";
import { Link, useParams } from "react-router-dom";
import { useSearchResult } from "../../Logic/useSearchResult";
import { useNavigate } from "react-router-dom";
import { DashboardLoader } from "../DashboardLoader";
import SearchLocation from "../../utils/SearchLocation";
import SearchDate from "../../utils/SearchDate";
import SearchTag from "../../utils/SearchTag";
import FullscreenPreview from "../Gallery/FullscreenPreview";
import { useFullscreenPreviewActions } from "../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import FullscreenPreviewNew from "../Gallery/FullScreenPreviewNew";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import { useQuickSlideShowActions } from "../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import { useStopWindowScroll } from "../../Logic/useStopWindowScroll";

const SearchResults = () => {
  const {
    getOptionLabel,
    listInnerRef,
    scrollWork,
    searchLocation,
    setSearchLocation,
    searchTag,
    setSearchTag,
    location,
    setLocation,
    locationsData,
    tag,
    setTag,
    tagsCollections,
    resultRef,
    observer,
    loadMore,
  } = useSearchResult();

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView,
    setShowFullView);

  // const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });

  const { query, option } = useParams();

  const navigate = useNavigate();
  const { searchResultPageQuery, results, isLoading } = useSelector(
    (state) => state.searchResultPage
  );

  const handleOpenFullViewModal = (i, data) => {
    setShowFullView({
      imageId: data.id,
      index: i,
      isOpen: true,
      type: "searchSection",
      data: data,
    });
  };

  const handleClear = () => {
    setSearchLocation("Location");
    setSearchTag("Tag");
  };

  const handleClickGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (resultRef.current) {
      const interceptConfig = {
        root: listInnerRef.current,
        rootMargin: "0px",
        threshold: 1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore();
        }
      });
      observer.current.observe(resultRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [results]);

  let previewData = {
    images: results,
    pathFrom: "searchlist",
    section: "all",
  };

  let slideData = {
    images: results,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };
  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(showFullView.isOpen);
  }, [showFullView.isOpen]);

  return (
    <div className={`${Style.search_results} search_results_wrap`}>
      <div className={Style.search_results_container}>
        <div className={Style.search_results_header}>
          <h3 className={Style.search_results_query}>
            <button
              className={`${Style.dashboard_actions_close} me-2`}
              onClick={() => handleClickGoBack()}
            >
              <Icons icon={"back-arrow"} size={20} />
            </button>{" "}
            Search results for "{searchResultPageQuery}"
          </h3>

          {
            <div className={Style.search_results_filter}>
              {locationsData?.length > 0 ? (
                <SearchLocation
                  searchLocation={searchLocation}
                  location={location}
                  setLocation={setLocation}
                  locationsData={locationsData}
                  setSearchLocation={setSearchLocation}
                />
              ) : (
                ""
              )}

              {/* <SearchDate
                searchDate={searchDate}
                setSearchDate={setSearchDate}
              /> */}
              {tagsCollections?.length > 0 && option != "albums" ? (
                <SearchTag
                  setSearchTag={setSearchTag}
                  searchTag={searchTag}
                  tagsCollections={tagsCollections}
                  setTag={setTag}
                  tag={tag}
                />
              ) : (
                ""
              )}

              {locationsData?.length > 0 ||
                (tagsCollections?.length > 0 && searchTag != "Tag") ? (
                <div>
                  <button
                    onClick={() => handleClear()}
                    className={Style.dashboard_actions_close}
                  >
                    <Icons icon={"icon-close"} size={14} />
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          }
        </div>
        <div
          ref={listInnerRef}
          className={Style.search_results_wrap}
        // onScroll={(e) => scrollWork()}
        >
          {results?.map((data, index) => {
            return (
              data.locked != 1 && (
                <div
                  className={Style.search_result}
                  key={data.id}
                  ref={resultRef}
                  onClick={() => {
                    if (option === "albums") {
                      navigate(`/dashboard/album/${data.id}`);
                    } else {
                      openPreview(index, data?.id);
                      // /handleOpenFullViewModal(index, data);
                    }
                  }}
                >
                  <figure className={Style.search_result_image}>
                    <img
                      src={
                        data.src.thumbnail === null
                          ? Assets.search_borken_placeholder
                          : data.src.thumbnail
                      }
                      alt=""
                    />
                  </figure>
                  <div className={Style.search_result_data}>
                    <h5 className={Style.search_result_match}>{data.title}</h5>
                    <div className={Style.search_result_album}>
                      {getOptionLabel(option)}
                    </div>
                  </div>
                </div>
              )
            );
          })}

          {isLoading == "succeeded" && results?.length === 0 && (
            <div className={Style.no_result_description}>
              <h4>Sorry, No Result Found :(</h4>
            </div>
          )}
        </div>

        {isLoading == "loading" ? <DashboardLoader data={results} /> : ""}
      </div>

      {/* {showFullView.isOpen ? (
        <FullscreenPreview
          setShowFullView={setShowFullView}
          showFullView={showFullView}
          data={results}
          pathFrom={"searchlist"}
        />
      ) : (
        ""
      )} */}

      {showFullView.isOpen && (
        <FullscreenPreviewNew
          previewObj={previewData}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
          total={previewData?.images?.length}
        />
      )}
      {quickSlideShow?.slideActions?.isOpen && (
        <QuickSlideShow slideObj={slideData} close={closeQuickSlideShow} />
      )}
    </div>
  );
};

export default SearchResults;
