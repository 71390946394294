import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    paymentCancellation
} from "../../store/slices/checkoutSlice";
import { updateActivePlanAfterPusrchase } from "../../store/slices/userprofileSlice";


export const useCancelPlan = () => {
    const dispatch = useDispatch();
    const [isOpenCancelPlanModal, setOpenCancelPlanModal] = useState(false)
    const { paymentCancelStatus } = useSelector((state) => state.payment);
    

    const handleOpenCancelModal = () => {
        setOpenCancelPlanModal(true)
    }

    const handleCloseCancelModal = () => {
        setOpenCancelPlanModal(false)
    }

    const handleCancelPlan = async () => {

        const resultAction = await dispatch(paymentCancellation())
        if (paymentCancellation.fulfilled.match(resultAction)) {
            dispatch(updateActivePlanAfterPusrchase([]))
           
        } 
    }

    const handleClickAfterPaymentCancel = () => {
        setOpenCancelPlanModal(false)
    }

    return {
        isOpenCancelPlanModal,
        setOpenCancelPlanModal,
        handleOpenCancelModal,
        handleCloseCancelModal,
        handleCancelPlan,
        paymentCancelStatus,
        handleClickAfterPaymentCancel
    }


}