import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  loginStatus: "idle",
  registerStatus: "idle",
  loginCreateOtpStatus: "idle",
  currentScreen: null,
  openAuthModal: false,
  showSignInComponent: false,
  showForgotComponent: false,
  registerData: {},
  registerOtpData: null,
  loginData: {},
  authData: {},
  currentAuthData: {},
  isEditMode: false,
  mobileVerifyData: {},
  mobileOtpError: "idle",
  otpStatus: "idle",
  existingUser:"idle",
  existingAccounts:{},
  loginMode:"login_email"
};

export const getRegisterData = createAsyncThunk(
  "auth/getRegisterData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("register", params);
      localStorage.setItem("userData", JSON.stringify(response?.data.data));
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("verify-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("login", params);

      localStorage.setItem("userData", JSON.stringify(response?.data.data));
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginCreateOtp = createAsyncThunk(
  "auth/loginCreateOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("create-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("forgot-password", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyForgotPasswordOtp = createAsyncThunk(
  "auth/verifyForgotPasswordOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("verify-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("reset-password", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const mobileVerify = createAsyncThunk(
  "auth/mobileVerify",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("mobile/verify", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.status = "idle";
      state.registerStatus = "idle";
      state.currentScreen = "";
      state.openAuthModal = false;
      state.showSignInComponent = false;
      state.showForgotComponent = false;
      state.registerData = {};
      state.registerOtpData = null;
      state.loginData = {};
      state.currentAuthData = {};
      state.authData = {};
      state.isEditMode = false;
      localStorage.removeItem("USER_ACCESS_TOKEN");
      storage.removeItem("persist:root");
    },

    storeCurrentAuthData: (state, action) => {
      state.currentAuthData = action.payload;
    },
    storeAuthDataProgress: (state, action) => {
      state.authData = action.payload;
    },
    editMode: (state, action) => {
      state.isEditMode = action.payload;
    },

    changeScreen: (state, action) => {
      state.currentScreen = action.payload;
    },

    authModalHandler: (state, action) => {
      state.openAuthModal = action.payload;
    },

    updateUserImage: (state, action) => {
      state.registerData = {
        ...state.registerData,
        profile_image: action.payload?.result,
      };
    },
    existingProfile: (state, action) => {
      state.existingUser = action.payload;
    },
    changeLoginMode: (state, action) => {
      state.loginMode = action.payload
    }
  },
  extraReducers: {
    [getRegisterData.pending]: (state, action) => {
      state.registerStatus = "loading";
    },
    [getRegisterData.fulfilled]: (state, action) => {
      state.registerData = action.payload.data.result;

      state.showOtpComponent = true;
      state.registerStatus = "succeded";

      localStorage.setItem(
        "USER_ACCESS_TOKEN",
        action.payload?.data?.result?.token
      );
    },
    [getRegisterData.rejected]: (state, action) => {
      state.showOtpComponent = false;
      state.registerStatus = "error";
    },

    [verifyOtp.pending]: (state, action) => {
      state.status = "loading";
      state.otpStatus = "loading";
    },
    [verifyOtp.fulfilled]: (state, action) => {
      state.registerData = {
        ...state.registerData,
        ...action.payload?.data?.result,
      };
      state.currentAuthData = {};
      state.otpStatus = "success";

      localStorage.setItem(
        "USER_ACCESS_TOKEN",
        action.payload?.data?.result?.token
      );
    },
    [verifyOtp.rejected]: (state, action) => {
      state.status = "error";
      state.otpStatus = "error";
    },

    [loginUser.pending]: (state, action) => {
      state.loginStatus = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loginStatus = "succeded";
      state.registerData = action.payload?.data?.result;
      state.loginData = action.payload?.data?.result;
      localStorage.setItem(
        "USER_ACCESS_TOKEN",
        action.payload?.data?.result?.token
      );
    },
    [loginUser.rejected]: (state, action) => {
      state.registerData = action.payload?.data?.data?.result;
      state.loginStatus = "error";
    },

    [loginCreateOtp.pending]: (state, action) => {
      state.loginCreateOtpStatus = "loading";
    },

    [loginCreateOtp.fulfilled]: (state, action) => {
      state.loginCreateOtpStatus = "succeded";
      let newData = action?.payload?.data?.data;
      state.currentAuthData = {
        ...state.currentAuthData,
        ...newData,
      };
      state.existingAccounts = action?.payload?.data?.result;
    },

    [loginCreateOtp.rejected]: (state, action) => {
      state.loginCreateOtpStatus = "error";
    },

    [forgotPassword.pending]: (state, action) => {
      state.status = "loading";
    },

    [forgotPassword.fulfilled]: (state, action) => {
      state.status = "succeded";
      state.currentAuthData = {
        ...state.currentAuthData,
        token: action?.payload.data.result.token,
      };
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status = "error";
    },

    [resetPassword.pending]: (state, action) => {
      state.status = "loading";
    },

    [resetPassword.fulfilled]: (state, action) => {
      state.status = "succeded";
      state.currentAuthData = {
        ...state.currentAuthData,
        user_id: action?.payload?.data?.data?.id,
      };
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = "error";
    },

    [mobileVerify.pending]: (state, action) => {
      state.status = "loading";
    },

    [mobileVerify.fulfilled]: (state, action) => {
      state.status = "succeded";
      // state.currentAuthData = {
      //   ...state.currentAuthData,
      //   user_id: action?.payload?.data?.data?.id,
      // };
      state.mobileVerifyData = action.payload?.data?.result;
    },
    [mobileVerify.rejected]: (state, action) => {
      state.status = "error";
      state.mobileOtpError = action.payload?.message;
    },
  },
});

export const {
  logout,
  storeCurrentAuthData,
  showSignInState,
  showforgotpasswordState,
  editMode,
  changeScreen,
  callFunction,
  authModalHandler,
  updateUserImage,
  storeAuthDataProgress,
  existingProfile,
  changeLoginMode
} = authSlice.actions;

export default authSlice.reducer;
