import React, { useEffect } from "react";
import Style from "../VideoGallery.module.scss";
import Icons from "../../Layouts/Icons";
import { useState } from "react";
import CommonModal from "../../Modals/commonModal";
import { useVideoModal } from "./useVideoModal";
import { DashboardLoader } from "../../DashboardLoader";
import { BtnLoader } from "../../../utils/BtnLoader";
import Assets from "../../Layouts/Assets";

const VideoModal = ({ open, close }) => {
  const {
    formik,
    isCreatingLink,
    thumbUrl,
    videoActions,
    handleFileChange,
    handleRemove,
    handleCloseModal,
    ValidationError,
    formError,
    isValidUrl,
  } = useVideoModal(close);

  return (
    <CommonModal
      showModal={open}
      hide={() => handleCloseModal()}
      className={Style.VideoGallery_modal_add_video}
      content={
        <>
          <div className={Style.VideoGallery_modal_container}>
            <h4>
              {`${videoActions?.mode == "edit" ? "Edit" : " "} Video Details`}
            </h4>
            <div className={Style.VideoGallery_modal_content}>
              <div className={Style.VideoGallery_modal_content_top}>
                <div className={Style.VideoGallery_modal_content_poster}>
                  <span className={Style.VideoGallery_modal_add_thumbnail}>
                    {!formik.values?.thumbnail || thumbUrl == "" ? (
                      <>
                        <label className={Style.VideoGallery_modal_add_area}>
                          <Icons
                            color="currentColor"
                            size={19}
                            icon={"icon-increment"}
                          />
                          <span>Add thumbnails</span>
                          <input
                            name="thumbnail"
                            onBlur={formik.handleBlur}
                            type="file"
                            className="d-none"
                            onChange={handleFileChange}
                          />
                        </label>
                      </>
                    ) : (
                      <>
                        <div className={Style.VideoGallery_modal_added_image}>
                          <button
                            className={Style.VideoGallery_modal_img_remove_btn}
                            onClick={handleRemove}
                          >
                            <Icons
                              color="currentColor"
                              size={10}
                              icon={"icon-close"}
                            />
                          </button>
                          <img src={thumbUrl} alt="Thumbnail" />
                        </div>
                      </>
                    )}
                  </span>
                  {formik.errors.thumbnail ? (
                    <div className={Style.error_msg}>
                      {formik.errors.thumbnail}
                    </div>
                  ) : null}
                </div>
                <div className={Style.VideoGallery_modal_content_inputs}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="input_test">Title</label>
                        <div className="input-holder">
                          <input
                            type="text"
                            name="title"
                            id="input_test"
                            value={formik?.values?.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Enter Title"
                          />
                        </div>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={Style.error_msg}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="input_test">Video URL</label>
                        <div className="input-holder">
                          <input
                            type="text"
                            name="video_url"
                            id="input_test"
                            value={formik?.values?.video_url}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Enter Video URL"
                          />
                        </div>
                        {formik.touched.video_url && formik.errors.video_url ? (
                          <div className={Style.error_msg}>
                            {formik.errors.video_url}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VideoGallery_modal_content_row}>
                <div className="form-group">
                  <label htmlFor="input_test">Description</label>
                  <div className="input-holder">
                    <textarea
                      onBlur={formik.handleBlur}
                      className="form-control"
                      name="description"
                      value={formik?.values?.description}
                      rows="3"
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                  {formik.touched.description && formik.errors.description ? (
                    <div className={Style.error_msg}>
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {ValidationError && (
              <div className={Style.validationError}>
                <span>{ValidationError}</span>
              </div>
            )}

            {formError?.description && (
              <div className={Style.validationError}>
                <span>{formError.description}</span>
              </div>
            )}

            <div className={Style.VideoGallery_modal_footer}>
              <button
                disabled={!formik?.isValid}
                className={`btn btn-primary`}
                onClick={() => formik.handleSubmit()}
              >
                {isCreatingLink == "loading" ? <BtnLoader /> : "Upload"}
              </button>
            </div>
          </div>
        </>
      }
    />
  );
};

export default VideoModal;
