import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { clearGalleryState } from "../../store/slices/gallerySlice";
import { getProfileDetails } from "../../store/slices/userprofileSlice";

export const useUserChecker = () => {
  const dispatch = useDispatch();

  const handleGetProfileDetails = async () => {
    const resultAction = await dispatch(getProfileDetails());
    if (getProfileDetails.rejected.match(resultAction)) {
      if (resultAction.payload.message == "Unauthenticated.") {
        dispatch(logout());
        dispatch(clearGalleryState());
      }
    } else if (getProfileDetails.fulfilled.match(resultAction)) {
    }
  };

  return {
    handleGetProfileDetails,
  };
};
