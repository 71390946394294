import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  handleLockAlbumApi,
  updateUnlock,
} from "../../../store/slices/albumSlice";
import { getFavoriteImages } from "../../../store/slices/favoriteSlice";
import { getGalleryImages } from "../../../store/slices/GalleryImagesSlice";
import { getStaredList } from "../../../store/slices/ProfileGallery/profileGallerySlice";

export const useAlbumUnlockModal = ({ unlockModal, setUnlockModal }) => {
  const ele_password = useRef();

  // const [isPassVisible, setIsPassVisible] = useState(false);
  // const [password, setPassword] = useState("")
  // const [message, setMessage] = useState("");
  // const [isValid, setIsValid] = useState(false);
  // const [isBlur, setIsBlur] = useState(false);

  const [formData, setFormData] = useState({
    isPassVisible: false,
    password: "",
    isValid: false,
    isBlur: false,
  });
  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      isPassVisible: !formData?.isPassVisible,
    });
    ShowPassword(1500, ele_password);
  };

  //FIXME fix the error handling (both ui and message)
  const ShowPassword = function (seconds, ele) {
    if (ele.current.type === "password") {
      ele.current.type = "text";
    } else {
      ele.current.type = "password";
    }
  };

  const handlePasswordChange = (e) => {
    var pattern = /^.*(?=.{8,20}).*$/; //FIXME check the pattern with login password
    setFormData({
      ...formData,
      password: e.target.value,
      isValid: pattern.test(e.target.value),
    });
  };

  const handleBlur = (e) => {
    setFormData({
      ...formData,
      isBlur: true,
    });
  };

  const handleClose = () => {
    setUnlockModal({ ...unlockModal, popup: !unlockModal.popup });
    setFormData({
      isPassVisible: false,
      password: "",
      isValid: false,
      isBlur: false,
      error: {},
    });
  };

  const handleUnlock = async () => {
    const resultAction = await dispatch(
      handleLockAlbumApi({
        album_id: unlockModal.album,
        password: formData?.password,
      })
    );

    if (
      handleLockAlbumApi.fulfilled.match(resultAction) &&
      resultAction.payload.data.success
    ) {
      dispatch(updateUnlock({ albumId: unlockModal.album, action: "unlock" }));
      //FIXME
      setFormData({
        isPassVisible: false,
        password: "",
        isValid: false,
        isBlur: false,
      });
      // setPassword("")
      // setIsValid(false)
      // setIsPassVisible(false)
      // setMessage("")
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getGalleryImages(obj));
      dispatch(getFavoriteImages(obj));
      dispatch(getStaredList());
      setUnlockModal({ popup: false, album: {} });
    } else {
      setFormData({
        ...formData,
        isValid: false,
        error: resultAction?.payload?.data,
      });
      setTimeout(() => {
        setFormData({
          isPassVisible: false,
          password: "",
          isValid: false,
          isBlur: false,
          error: {},
        });
      }, 2000);
    }
  };

  return {
    formData,
    ele_password,
    handlePasswordChange,
    handleBlur,
    ShowPassword,
    handleUnlock,
    handleClickShowPassword,
    handleClose,
  };
};
