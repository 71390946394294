export const Left_Arrow = ({ Style }) => {
  return (
    <svg
      className={Style.collapse_holder_image}
      xmlns="http://www.w3.org/2000/svg"
      width="95.318"
      height="138.926"
      viewBox="0 0 95.318 138.926"
    >
      <path
        id="Path_76279"
        data-name="Path 76279"
        d="M0,137.926H64.73v-12.3a28.256,28.256,0,0,1,10.625-22.08l3.8-3.033a40.34,40.34,0,0,0,.09-62.971l-3.949-3.171A28.257,28.257,0,0,1,64.73,12.345V0H0Z"
        transform="translate(0.5 0.5)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
};

export const DuplicateIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 5H7V3H21V17H19V5Z" fill="currentColor" />
      <path d="M9 13V11H11V13H13V15H11V17H9V15H7V13H9Z" fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 7H17V21H3V7ZM5 9H15V19H5V9Z"
        fill="currentColor"
      />
    </svg>
  );
};
