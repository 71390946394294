import Style from "./HomeTestimonials.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Logic/useHome";
import moment from "moment";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeTestimonials = () => {
  const { t } = useTranslation();

  const { testimonials, testimonialData } = useHome();

  useEffect(() => {
    testimonials();
  }, []);

  return (
    <div className={Style.home_testimonials}>
      <div className={Style.anim_elements}>
        <figure
          className={`${Style.anim_elem} ${Style.donut_blue} anim_element anim`}
        >
          <img src={Assets.testimonial_donut_blue} alt="donut_blue" />
        </figure>
      </div>
      <Container>
        <div className={Style.home_testimonials_wrap}>
          <div
            className={`${
              Style.home_testimonials_title
            } ${"section_title_block center"}`}
          >
            <h2 className="anim load-anim">{t("testimonial_title_1")}</h2>
            <p className="anim load-anim">{t("testimonial_title_2")}</p>
          </div>
          <div className={Style.home_testimonials_cardslider}>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              navigation={{
                prevEl: ".swiper-navigation-prev",
                nextEl: ".swiper-navigation-next",
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              speed={1200}
              className={`${Style.home_testimonials_swiper} anim load-anim`}
            >
              {testimonialData
                ? testimonialData.map((item, index) => {
                   
                    return (
                      <SwiperSlide key={item.id}>
                        <div
                          className={Style.home_testimonials_testimonialcard}
                          key={index}
                        >
                          <figure className={Style.testimonial_image}>
                            <LazyLoadImage
                              className={Style.home_testimonials_cardimg}
                              src={
                                item.large_thumbnail ? item.large_thumbnail : ""
                              }
                              alt={`testimony_image`}
                            />
                          </figure>
                          <div className={Style.testimonial_content}>
                            <p className={Style.home_testimonials_cardtext}>
                              {item.description}
                            </p>
                            <div className={Style.home_tastimonials_data}>
                              <p className={Style.home_testimonials_author}>
                                {item.name}
                              </p>
                              <p className={Style.home_testimonials_date}>
                                {moment(item.date).format("MMMM D, YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
                : [1, 2, 3].map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div
                          className={`${Style.home_testimonials_testimonialcard} data-loading`}
                          key={index}
                        >
                          <figure className={Style.testimonial_image}></figure>
                          <div className={Style.testimonial_content}>
                            <p className={Style.home_testimonials_cardtext}>
                              <span className={Style.lines}></span>
                              <span className={Style.lines}></span>
                              <span className={Style.lines}></span>
                            </p>
                            <div className={Style.home_tastimonials_data}>
                              <p className={Style.home_testimonials_author}></p>
                              <p className={Style.home_testimonials_date}></p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
            <button className="swiper-navigation-prev swiper-button-prev swiper-navigation-button anim load-anim"></button>
            <button className="swiper-navigation-next swiper-button-next swiper-navigation-button anim load-anim"></button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeTestimonials;
