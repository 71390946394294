import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
};

export const profilePhotoUpload = createAsyncThunk(
  "userprofilephotoupload/profilePhotoUpload",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { photo } = obj;

      const packet = new FormData();
      packet.append("blob_image", photo);

      const response = await api.post(`update-profile-pic`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
