import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlbumFacesItem } from "../../store/slices/gallerySlice";

export const useFacesData = () => {
  const dispatch = useDispatch();
  const [faces, setFaces] = useState([]);

  const { default_album_id } = useSelector(
    (state) => state.userprofile.userdata
  );

  useEffect(() => {
    if (default_album_id && faces?.length == 0) {
      dispatch(getAlbumFacesItem(default_album_id))
        .unwrap()
        .then((res) => setFaces(res?.data?.result?.images));
    }
  }, [default_album_id]);

  return {
    faces,
  };
};
