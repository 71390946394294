import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  error: null,
  resultData: []
};

export const frameList = createAsyncThunk(
    "frame/frameList",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.get(`frames-list`);
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const frameSlice = createSlice({
  name: "frame",
  initialState,
  reducers: {},
  extraReducers: {
    [frameList.pending]: (state, action) => {
      state.status = "loading";
    },
    [frameList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = [ ...action?.payload?.data?.result ]
    },
    [frameList.rejected]: (state, action) => {
      state.status = "failed";
    },
    

  },
});

export default frameSlice.reducer;
