import React from 'react'
import Style from './LanguageMenu.module.scss';
import Languages from '../../../constants/languages';
import { useTranslation } from "react-i18next";
import { useDimensions } from '../../../Logic/Dimensions';


const LanguageMenu = ({ availableLanguage, languageCategorySwitch, languageCategory,capitalizeFirstLetter,setShowLang }) => {
    const { i18n, t } = useTranslation(["common"]);
    const windowDimensions = useDimensions();
    return (
        <>
        {windowDimensions.width < 768 ? (
        <>
        <div className={Style.language_overlay} onClick={() => { setShowLang(false) }}></div>
        <div className={Style.language_menu_mob}>
            <div className={Style.language_menu}>
              <div className='container'>
                  <h5 className={Style.language_menu_title}><button onClick={() => { setShowLang(false) }}>Languages</button></h5>
                  <ul className={Style.language_menu_list}>
                      {
                          availableLanguage.map((lang, i) => {
                              return (
                                  <li key={lang.id} onClick={() =>languageCategorySwitch(lang)}>
                                      <input checked={lang.name == languageCategory.name} className={Style.language_radio_input} type="radio" id={lang.language} />
                                      <label className={Style.language} htmlFor={lang.name}>{capitalizeFirstLetter(lang.name)}</label>
                                  </li>
                              )
                          })
                      }
                  </ul>
              </div>
          </div>
        </div>
        </>
        ):(<></>)
        }
        </>
    )
}

export default LanguageMenu;