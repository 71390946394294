const demoData = [
  {
    name: "Create Account",
    description: "Demo video for creating an account",
    type: "demo1",
    url: "https://api.familyphotosalbum.com/video/Video_1.mp4",
  },
  {
    name: "Upload photos",
    description: "Demo video for uploading Images",
    type: "demo2",
    url: "https://api.familyphotosalbum.com/video/Video2.mp4",
  },
  {
    name: "Slideshow",
    description: "Demo video for creating slideshows",
    type: "demo3",
    url: "https://api.familyphotosalbum.com/video/Video3.mp4",
  },
  {
    name: "Family Tree",
    description: "Demo video for creating Family Tree",
    type: "demo4",
    url: "https://api.familyphotosalbum.com/video/Video4.mp4",
  },
];

export default demoData;
