import React, { Suspense, useRef, useEffect } from "react";
import Assets from "../Layouts/Assets";
import Style from "./familyTree.module.scss";
import Popup from "./Popup";
import SideDrawer from "./SideDrawer";
import DeleteMemberWarning from "./DeleteMemberWarning";
import UseFamilyTree from "../../Logic/FamilyTree/useFamilyTree";
import Icons from "../Layouts/Icons";
import { useDimensions } from "../../Logic/Dimensions";
import { useDispatch, useSelector } from "react-redux";
import { DashboardLoader } from "../DashboardLoader";
import { useProfileUpload } from "../../Logic/useProfilePhotoUpload";
import { addMemberToTree } from "../../store/slices/familyTreeSlice";
import { useState } from "react";
import FacesDrawer from "./FacesDrawer";
import { useFacesData } from "../../Logic/FamilyTree/useFacesData";
import ProfilePictureModal from "./ProfilePictureModal/ProfilePictureModal";
import { CSSTransition } from "react-transition-group";
import CommonModal from "../Modals/commonModal";
import HistoryDrawer from "./HistoryDrawer";

let dropIndex;

const FamilyTreePage = () => {
  //main data
  const divRef = useRef();
  const componentRef = useRef();
  const windowDimensions = useDimensions();
  const { newMemberStatus } = useSelector((state) => state.familyTree);
  const dispatch = useDispatch();
  const [showFacesDrawer, setShowFacesDrawer] = useState(false);
  const { faces } = useFacesData();

  const [showHistoryDrawer, setShowHistoryDrawer] = useState(false);

  const [isBtnVisible, setIsBtnVisible] = useState(false);

  const [newImage, setNewImage] = useState("");

  const {
    zoom,
    myData,
    setData,
    active,
    setActive,
    newData,
    setNewData,
    sideDrawerOpen,
    setSideDrawerOpen,
    drawerData,
    setDrawerData,
    setZoom,
    handlePrint,
    showDeleteMemberModal,
    setShowDeleteMemberModal,
    handleRecenter,
    firstTime,
    setFirstTime,
    popUpData,
    setPopUpData,
    showProfileUploadModal,
    setShowProfileUploadModal,
    acceptedRemoval,
    setAcceptedRemoval,
    setReInitTree,
    handleNodeRemove,
    removable,
    clearTree,
    clearTreeModal,
    setClearTreeModal,
    loading,
    setLoading,
    handleUpdateVersionData,
  } = UseFamilyTree({ divRef, componentRef });

  const handleDropNewImages = (e) => {
    setNewImage(e.target.currentSrc);
  };

  const propsOut = {
    data: newData,
    setNewData: setNewData,
    active: active,
    checkActive: setActive,
    checkDrawerOpen: setSideDrawerOpen,
    isDrawerOpen: sideDrawerOpen,
    dataDrawer: drawerData,
    mainData: myData,
    setMainData: setData,
    setDrawerData: setDrawerData,
    firstTime,
    setFirstTime,
    popUpData,
    setPopUpData,
    acceptedRemoval,
    setAcceptedRemoval,
    handleNodeRemove,
    removable,
    loading,
    setLoading,
  };

  const {
    imageSrc,
    saveImage,
    handleDragOver,
    handleDragOut,
    handleDrop: handleProfileDrop,
    responseImage,
    isUploading,
    setIsUploading,
    IsProfilePhotoUploading,
  } = useProfileUpload(newImage, setShowProfileUploadModal);

  useEffect(() => {
    if (typeof responseImage === "object") {
      if (Object.keys(responseImage).includes("img")) {
        let tempData = myData;
        tempData[dropIndex].img = responseImage.img;
        dispatch(addMemberToTree({ data: tempData }));
        setData(tempData);
        setIsUploading(false);
        setReInitTree((state) => !state);
      }
    }
  }, [responseImage]);

  const handleDrop = (e, id) => {
    handleProfileDrop(e);
    e?.preventDefault();
    setShowProfileUploadModal(true);
    dropIndex = myData.findIndex((a) => a.id == id);
  };

  window.handleDrop = handleDrop;

  window.openModal = () => {
    setShowDeleteMemberModal(true);
  };
  window.closeModal = () => {
    setShowDeleteMemberModal(false);
  };

  const toggleButtonVisibility = () => {
    setIsBtnVisible(!isBtnVisible);
    setShowHistoryDrawer(false);
    setShowFacesDrawer(false);
  };

  useEffect(() => {
    const userGender = myData?.filter((a) => a.id == 1 && a.gender === null);
    const userData = myData?.filter((a) => a.id == 1);
    if (userGender?.length !== 0) {
      setDrawerData(userData);
      setFirstTime(true);
      setSideDrawerOpen(true);
    }
  }, [myData]);
  useEffect(() => {
    showFacesDrawer && setIsBtnVisible(false);
  }, [showFacesDrawer]);

  const closeHistroyDrawer = () => {
    setShowHistoryDrawer(false);
  };
  const showHistroyDrawer = () => {
    setShowHistoryDrawer(true);
    setIsBtnVisible(false);
  };

  useEffect(() => {
    sideDrawerOpen || showFacesDrawer || showHistoryDrawer
      ? document.body.classList.add("sideDrawerOpen")
      : document.body.classList.remove("sideDrawerOpen");
  }, [sideDrawerOpen, showFacesDrawer, showHistoryDrawer]);

  return (
    <Suspense fallback={<div>loading...</div>}>
      {active && <Popup {...propsOut} />}
      {/* {sideDrawerOpen &&  */}
      <CSSTransition
        in={sideDrawerOpen}
        timeout={500}
        classNames={"sideDrawer"}
        unmountOnExit
      >
        <SideDrawer {...propsOut} />
      </CSSTransition>

      {/* {sideDrawerOpen &&  */}
      <CSSTransition
        in={showHistoryDrawer}
        timeout={500}
        classNames={"sideDrawer"}
        unmountOnExit
      >
        <HistoryDrawer
          handleClose={closeHistroyDrawer}
          handleUpdate={handleUpdateVersionData}
        />
      </CSSTransition>

      {/* } */}
      {showDeleteMemberModal && (
        <DeleteMemberWarning setAcceptedRemoval={setAcceptedRemoval} />
      )}
      {/* {showFacesDrawer && ( */}
      <CSSTransition
        in={showFacesDrawer}
        timeout={500}
        classNames={"sideDrawer"}
        unmountOnExit
      >
        <FacesDrawer
          showFacesDrawer={showFacesDrawer}
          setShowFacesDrawer={setShowFacesDrawer}
          handleDropNewImages={handleDropNewImages}
          faces={faces}
        />
      </CSSTransition>
      {/* )} */}
      <div ref={componentRef} className={Style.tree}>
        <div id="tree" ref={divRef}></div>
        <div
          className={`${Style.treeControls} ${
            !isBtnVisible ? Style.btnInvisible : ""
          }`}
        >
          <button
            onClick={() => handlePrint()}
            className="btn btn-curved btn-no-border-white"
          >
            <Icons icon={"icon-print"} size={19} />
            <span>Print</span>
          </button>
          <button
            onClick={showHistroyDrawer}
            className="btn btn-curved btn-no-border-white icon-primary"
          >
            <Icons icon={"history"} size={19} />
            <span>History</span>
          </button>
          <button
            onClick={handleRecenter}
            className="btn btn-curved btn-no-border-white"
          >
            <Icons icon={"icon-recenter"} size={19} />
            <span>Re-center</span>
          </button>
          {windowDimensions.width >= 1280 && (
            <div className={Style.btnDiv}>
              <button
                className={Style.btn_modify}
                onClick={() =>
                  zoom > 0.25
                    ? zoom == 0.75
                      ? setZoom(zoom - 0.25)
                      : zoom == 0.4
                      ? setZoom(zoom - 0.15)
                      : zoom == 0.5
                      ? setZoom(zoom - 0.1)
                      : setZoom(zoom - 0.25)
                    : setZoom(zoom)
                }
              >
                <Icons icon={"icon-decrement"} size={15} />
              </button>
              <input
                className={Style.zoomInput}
                value={`${zoom * 100}%`}
                readOnly
              />
              <button
                className={Style.btn_modify}
                onClick={() =>
                  zoom < 1.5
                    ? zoom == 0.25
                      ? setZoom(zoom + 0.15)
                      : zoom == 0.4
                      ? setZoom(zoom + 0.1)
                      : zoom == 0.5
                      ? setZoom(zoom + 0.25)
                      : setZoom(zoom + 0.25)
                    : setZoom(zoom)
                }
              >
                <Icons icon={"icon-increment"} size={15} />
              </button>
            </div>
          )}
          <button
            onClick={() => setShowFacesDrawer(true)}
            className="btn btn-curved btn-no-border-white"
          >
            <Icons icon={"icon-faces"} size={19} />
            <span>Faces</span>
          </button>
          {/* <button
            onClick={() => clearTree(false)}
            className="btn btn-curved btn-no-border-white clear"
          >
            <Icons icon={"icon-trash-bin"} size={19} />
            <span>Clear Tree</span>
          </button> */}
          <button
            className={`btn btn-round btn-no-border-white ${Style.btnRounded}`}
            onClick={() => toggleButtonVisibility()}
          >
            <div className={Style.ham_btn}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
      </div>

      <ProfilePictureModal
        open={showProfileUploadModal}
        close={() => setShowProfileUploadModal(false)}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        handleDragOut={handleDragOut}
        saveImage={saveImage}
        loading={IsProfilePhotoUploading}
        imageSrc={imageSrc}
      />

      <CommonModal
        showModal={clearTreeModal}
        hide={() => setClearTreeModal((state) => !state)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>Clear Tree</h3>
            <p>Are you sure you want to clear the tree?</p>
            <div className={Style.delete_btn_wrap}>
              <button className="btn-danger" onClick={() => clearTree(true)}>
                Continue
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => setClearTreeModal((state) => !state)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />
      {newMemberStatus == "loading" ? (
        <DashboardLoader data={[]} />
      ) : isUploading ? (
        <DashboardLoader data={[]} />
      ) : (
        loading && <DashboardLoader data={[]} />
      )}
    </Suspense>
  );
};

export default FamilyTreePage;
