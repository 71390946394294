import Style from "./ForgotPasswordModal.module.scss";
import { useEffect } from "react";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import { useLogin } from "../../../Logic/useLogin";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";



const ForgotPasswordModal = ({ setShowSignIn, setShowFP }) => {
  const {
    showForgotPassword,
    forgotPasswordHandler,
    loginErrors,
    setShowForgotPassword,
  } = useLogin();

  const { i18n, t } = useTranslation(["authentication"]);

  const { currentScreen, status } =
    useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      if (currentScreen === 'forgotPassword') {
        document.querySelectorAll('.modal input')[0].focus();
      }
    }, 500);
  }, [currentScreen]);


  return (
    <div className={Style.fp_modal}>
      <div className={Style.fp_modal_content}>
        <div className={Style.forgot_password}>
          <div className={Style.forgot_password_header}>
            <h3 className={Style.forgot_password_title}>{t("reset_password_title")}</h3>
            {!showForgotPassword &&
              <p className={Style.forgot_password_info}>
                {t("reset_password_heading")}.
              </p>
            }

          </div>
          <form className={Style.forgot_password_form}>
            {showForgotPassword ? (
              <ResetPassword
                setShowForgotPassword={setShowForgotPassword}
                forgotPasswordHandler={forgotPasswordHandler}

              />
            ) : (
              <VerifyEmail
                status={status}
                onError={loginErrors}
                forgotPasswordHandler={forgotPasswordHandler}
              />
            )}
          </form>

        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
