import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../../Logic/Dimensions";
import { LoadmoreAlbums } from "../../../store/slices/albumSlice";

const useGridList = () => {

    const observer = useRef();
    const imgRootRef = useRef(null);
    const imgItemRef = useRef(null);
    const dispatch = useDispatch()

    const albums = useSelector((state) => state?.album);
    const selections = useSelector((state) => state.galleryTab);

    useEffect(() => {//FIXME loadmore not working 
        if (imgItemRef.current) {
            observer.current = new IntersectionObserver((entries) => {
                if (
                    entries.every((entry) => {
                        return entry.isIntersecting;
                    })
                ) {
                    loadMore()
                }
            });
            observer.current.observe(imgItemRef.current);
            return () => {
                observer.current.disconnect();
            };
        }
    }, [albums, imgItemRef?.current]);


    const loadMore = () => {

        if (albums.resultData.current_page < albums.resultData.last_page) {
            let obj = {
                page: albums?.resultData?.current_page + 1,
                slug: selections?.ImageAlbumTab?.sort

            };
            dispatch(LoadmoreAlbums(obj));
        }
    };


    return {
        observer,
        imgRootRef,
        imgItemRef,
    }

}
export default useGridList;