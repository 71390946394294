import Assets from "../components/Layouts/Assets";

const Countries = [
  {
    country: 'India',
    flag: Assets.flag_india,
    id: "country1",
  },
  // {
  //   country: 'United Kingdom',
  //   flag: Assets.english_flag,
  //   id: "country2",
  // },
  // {
  //   country: 'Germany',
  //   flag: Assets.german_flag,
  //   id: "country3",
  // },
  // {
  //   country: 'France',
  //   flag: Assets.french_flag,
  //   id: "country4",
  // },
  // {
  //   country: 'China',
  //   flag: Assets.japanese_flag,
  //   id: "country5",
  // },
];

export default Countries;
