import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: "idle",
  searchResultPageQuery: "",
  results: [],
  currentPage: 0,
  totalPages: 1,
};

export const triggerSearchAPI = createAsyncThunk(
  "searchResultPageSlice/triggerSearchAPI",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { query, option, page, search_location, search_tag, search_date } =
        obj;

      const packet = new FormData();
      packet.append("search", query);
      packet.append("page", page);
      packet.append("type", option);
      packet.append("per_page", 10);

      if (search_location !== "" && search_location !== "Location") {
        packet.append("location", search_location);
      }

      if (search_tag !== "" && search_tag !== "Tag") {
        packet.append("tag", search_tag);
      }

      if (search_date !== "" && search_date !== "Date") {
        packet.append("date", search_date);
      }

      const response = await api.post(`search`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSearchSlice = createSlice({
  name: "searchResultPageSlice",
  initialState,
  reducers: {
    clearData: (state) => {
      state.results = [];
      state.totalPages = "";
      state.currentPage = 1;
    },
  },
  extraReducers: {
    [triggerSearchAPI.pending]: (state) => {
      state.isLoading = "loading";
    },
    [triggerSearchAPI.fulfilled]: (state, action) => {
      state.isLoading = "succeeded";

      if (action?.meta?.arg?.page > 1) {
        state.results = [
          ...state.results,
          ...action.payload.data?.result?.data,
        ];
      } else {
        state.results = action.payload.data?.result?.data;
      }

      state.searchResultPageQuery = action?.meta?.arg?.query;
      state.totalPages = action.payload?.data?.result?.last_page;
      state.currentPage = action?.meta?.arg?.page;
    },
    [triggerSearchAPI.rejected]: (state, action) => {
      state.isLoading = "error";
    },

    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state.results) {
        let imgIndex = "";
        if (action.payload.data.result.id) {
          imgIndex = current(state?.results).findIndex((obj) => obj.id === action.payload.data.result.id)

        } else {
          imgIndex = current(state?.results).findIndex((obj) => obj.id === action.payload.data.result.image_id)

        }
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags
          state.results[imgIndex].tags = tagData
        }

      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      let imgIndex = state?.results?.findIndex((obj) => obj.id === action.payload.data.result.id)
      if (imgIndex !== -1) {
        let tagData = action.payload.data.result.tags
        state.results[imgIndex].tags = tagData
      }
    }
  },
});

export const { showLoading, clearData } = userSearchSlice.actions;

export default userSearchSlice.reducer;
