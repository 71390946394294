import { useState } from "react";
import Style from "../SelectMother/SelectMother.module.scss";

const SelectChild = (props) => {
  const { data, handleNewNode } = props;

  const [selectedChildren, setSelectedChildren] = useState([]);

  const userData = data[0].userData[0];
  const getAllData = data[0].allData;

  const children = getAllData.filter((el) => {
    return (
      (el.mid == userData.id && el.fid == null && el.connection != "pet") ||
      (el.fid == userData.id && el.mid == null && el.connection != "pet")
    );
  });

  return (
    <div className={`${Style.root} ${Style.selectChild}`}>
      <div className={Style.innerWrapper}>
        <div className={Style.heading}>
          <h3>Add children</h3>
          <button
            className={Style.skip_child}
            onClick={() => handleNewNode({ connection: "partner" })}
          >
            Skip
          </button>
        </div>

        <div className={Style.listWrapper}>
          <div className={Style.listItem}>
            {children.map((item) => {
              return (
                <button
                  className={`${Style.item} ${
                    selectedChildren.findIndex((c) => c.id == item.id) != "-1"
                      ? Style.active
                      : ""
                  }`}
                  key={item.id}
                  onClick={() => {
                    if (
                      selectedChildren.findIndex((c) => c.id == item.id) != "-1"
                    ) {
                      let tempData = JSON.parse(
                        JSON.stringify(selectedChildren)
                      );
                      tempData.splice(
                        selectedChildren.findIndex((c) => c.id == item.id),
                        1
                      );
                  
                      setSelectedChildren(tempData);
                    } else {
                      setSelectedChildren((children) => [...children, item]);
                    }
                  }}
                >
                  <div className={Style.imageWrapper}>
                    <div className={Style.imageContainer}>
                      <img src={item.img} alt="" />
                    </div>
                  </div>
                  <div className={Style.textWrapper}>{item.name}</div>
                </button>
              );
            })}
          </div>
        </div>
        {selectedChildren.length != 0 && (
          <div className={Style.buttonWrap}>
            <button
              onClick={() =>
                handleNewNode({
                  connection: "partner",
                  spouse: selectedChildren,
                })
              }
              className={Style.submitChild}
            >
              <span></span>
              <span></span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectChild;
