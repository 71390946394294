import Style from "./UserProfileGallery.module.scss";
import { useEffect, useState } from "react";
import UserProfileGalleryImages from "../UserProfileGalleryImages";

import useGallery from "../../../Logic/useGallery";
import { useDispatch, useSelector } from "react-redux";
import GalleryBlankPage from "../../BlankGallery";

const UserProfileGallery = () => {
  const dispatch = useDispatch();

  const { getImagesForSection } = useGallery();

  const { sections } = useSelector((state) => state.gallery);

  const [isGalleryEmpty, setIsGalleryEmpty] = useState(true);

  const breakpointColumnsObj = {
    default: 5,
    1599: 4,
    991: 3,
    575: 2,
    374: 1,
  };

  useEffect(() => {
    if (sections["all"]?.data?.length > 0) {
      setIsGalleryEmpty(false);
    } else {
      setIsGalleryEmpty(true);
    }
  }, sections["all"]?.data);

  return (
    <>
      <div className={Style.User_profile_gallery_sec}>
        <h2 className={Style.User_profile_gallery_title}>Gallery</h2>
        {isGalleryEmpty ? (
          <GalleryBlankPage
            title={`You haven’t added any photos`}
            description={`Upload your photos now to bring your family story to life.`}
            buttonText={`Upload Photos`}
          />
        ) : (
          <UserProfileGalleryImages
            breakpointColumnsObj={breakpointColumnsObj}
          />
        )}
      </div>
    </>
  );
};
export default UserProfileGallery;
