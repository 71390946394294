import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
} from "../store/slices/authSlice";

import { contactFormDataSubmit } from "../store/slices/contactFormSlice";

export const useContactForm = () => {
  const dispatch = useDispatch();

  const [ContactFormSubmit, setContactFormSubmit] = useState([]);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const message = {
    success: "Thank you for contacting us! We'll be in touch shortly.",
    error: "Sorry, something went wrong! Please try again later.",
  };

  const { status } = useSelector((state) => state.contactform);

  const [submitButtonLabel, setSubmitButtonLabel] = useState("Submit");

  const contactFormSubmit = (FormData, resetForm) => {
    setSubmitButtonLabel("Please wait...");
    dispatch(contactFormDataSubmit(FormData))
      .unwrap()
      .then((data) => {
        const { success, message } = data.data;

        if (success) {
          resetForm();
          setSubmitButtonLabel("Submit");
          setSuccess(true);
        }
      })
      .catch(function (reason) {
        setSubmitButtonLabel("Submit");
        setFormError(true);
      });
  };

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    setTimeout(function () {
      setFormError(false);
    }, 5000);
  }, [formError]);
  // Click Event for section tab Family Members

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  return {
    contactFormSubmit,
    capitalizeFirstLetter,
    success,
    message,
    formError,
    submitButtonLabel,
    status,
  };
};
