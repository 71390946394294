import React, { useEffect, useRef, useState } from "react";
import ImageGallery from "../../Gallery/ImageGallery";
import ImageListMasonry from "../../ImageListMasonry";
import Style from "./AlbumDetailView.module.scss";
import { useAlbumView } from "./useAlbumView";
import sample1 from "../../../assets/sample1.pdf";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import Icons from "../../Layouts/Icons";
import ShareModal from "../../ShareModal";
import { DashboardLoader } from "../../DashboardLoader";
import GalleryBlankPage from "../../BlankGallery";
import QuickSlideShow1 from "../../Slideshow/QuickSlideShow1";
import Badge from "react-bootstrap/Badge";

const AlbumDetailView = () => {
  const {
    data,
    status,
    selections,
    token,
    albumAddToSelection,
    frames,
    albumId,
    openShare,
    NodataContent,
    rearrangeItems,
    loadMore,
    handleShareAlbum,
    handleDuration,
    setOpenShare,
  } = useAlbumView();

  const [showFullText, setShowFullText] = useState(false);

  const description = data?.album?.description;
  const truncatedDescription = description?.slice(0, 200);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  return (
    <div>
      {albumAddToSelection?.selction ? (
        <ImageGallery />
      ) : (
        <>
          <div className={Style.albumDetails}>
            <div className={Style.info}>
              <h2>
                {data?.album?.title}
                <span>
                  <Badge pill bg="secondary">
                    {`${data?.total ?? 0}`}
                  </Badge>
                </span>
              </h2>

              <p>
                {showFullText ? description : truncatedDescription}
                {description?.length > 200 && (
                  <small onClick={toggleText}>
                    {showFullText ? "show less" : "show more"}
                  </small>
                )}
              </p>
            </div>
            {data?.images?.length > 0 ? (
              <button
                className={`${Style.albumShare}`}
                onClick={() => handleShareAlbum()}
              >
                <Icons icon={"share2"} size={14} />
                <span>Share</span>
              </button>
            ) : (
              ""
            )}
          </div>
          <div style={{ padding: "10px" }}>
            <ImageListMasonry
              total={data?.images?.length}
              Images={data?.images}
              loadMore={loadMore}
              NodataContent={{}}
              options={selections}
              moveListItem={rearrangeItems}
              albumData={data?.album}
              frames={frames}
              path="album"
              mode="album"
              isShare={data?.album?.photofind_lock}
            />
          </div>
        </>
      )}
      {status == "loading" ? (
        <DashboardLoader data={data?.images?.length} />
      ) : (
        ""
      )}
      {data?.images?.length == 0 &&
        status == "succeeded" &&
        NodataContent?.title != undefined && (
          <GalleryBlankPage
            title={NodataContent?.title}
            description={NodataContent?.description}
          />
        )}

      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="profile"
        secretToken={token}
        path={"albumshare"}
        handleDuration={handleDuration}
        albumId={albumId}
      />
    </div>
  );
};

export default AlbumDetailView;
