import React, { useEffect, useState } from "react";
import { Slider } from "antd";
import Style from "./FitToFrame.module.scss";

const FitToFrame = ({
  frame,
  image,
  frameData,
  setFrameData,
  mode,
  selectedImageForCreate,
}) => {
  const handleCropValue = (value) => {
    setFrameData({
      ...frameData,
      cropValue: value,
      frameClass: "frameFit",
    });
  };

  let cropValue = frameData?.cropValue == 0 ? 1 : frameData?.cropValue;
  return (
    <div className={Style.fitToframeSection}>
      <figure
        className={`${
          frame?.frameName == "None" ? Style.frameFit_none : Style.frameFit
        }`}
        style={{
          borderImageSource: `url(${
            frame?.frameName == "None" ? "" : frame?.frame
          })`,
        }}
      >
        <img
          src={image}
          className={Style.frame}
          style={{ transform: `scale(${cropValue})` }}
          alt=""
        />
      </figure>

      <div className={Style.sliderSection}>
        <p className={Style.sliderHeader}>Fit Into Frame</p>
        <Slider
          min={1}
          max={2}
          step={0.1}
          //tipFormatter={volumeFormat}
          value={frameData?.cropValue ? frameData?.cropValue : 0}
          onChange={(value) => {
            handleCropValue(value);
          }}
        />
      </div>
    </div>
  );
};

//

export default FitToFrame;
