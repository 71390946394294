import Style from "./TellUsTabs.module.scss";

export const TellUsTabs = ({ setType }) => {
  return (
    <div className={Style.tellustabs}>
      <ul>
        <li
          onClick={() => {
            setType("you");
          }}
        >
          You
        </li>
        <li
          onClick={() => {
            setType("father");
          }}
        >
          Father
        </li>
        <li
          onClick={() => {
            setType("mother");
          }}
        >
          Mother
        </li>
      </ul>
    </div>
  );
};
