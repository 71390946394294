import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DarkThemeToggler from "../../components/DarkThemeToggler";
import PreLogin from "../../components/Layouts/PreLogin";
import NotFoundPage from "../../components/UtilityPages/NotFoundPage";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";

const ErrorPage = () => {
    const location = useLocation();
    const { theme, themeToggler } = useDarkmode();

    useEffect(() => {
        (location.pathname === "*") && document.body.classList.add('small-header');
        return () => {
            document.body.classList.remove('small-header');
        }
    }, [location.pathname])

    useEffect(() => {
        const allSections = document.querySelectorAll(".anim");
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const element = entry.target;
                    element.classList.add("visible");
                }
            });
        });
        allSections.forEach((section) => {
            observer.observe(section);
        });
    }, [location.pathname]);

    return (
        <>
            <PreLogin hasFooter={true}>
                <NotFoundPage />
            </PreLogin>
            <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
        </>
    );
};

export default ErrorPage;
