import { useEffect, useState } from "react";
import { updatePreferenceStatus } from "../../store/slices/preferenceSlice";
import { useDispatch } from "react-redux";

const useDarkmode = () => {
  const [theme, setTheme] = useState("dark");
  const dispatch = useDispatch();
  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme && setTheme(localTheme);
    if (localTheme === "dark") {
      setMode("dark");
      document.body.classList.add("dark");
    } else if (localTheme === "light") {
      setMode("light");
      document.body.classList.remove("dark");
    } else {
      setMode(theme);
      document.body.classList.add(theme);
    }
    //eslint-disable-next-line
  }, []);

  const setMode = (mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const themeToggler = async () => {
    await dispatch(
      updatePreferenceStatus({
        action: "background_theme_id",
        value: theme == "light" ? 1 : 2, // (1 dark mode) (2 normal mode)
      })
    );
    if (theme === "light") {
      setMode("dark");
      document.body.classList.add("dark");
    } else {
      setMode("light");
      document.body.classList.remove("dark");
    }
  };

  return {
    theme,
    themeToggler,
    setTheme,
  };
};

export default useDarkmode;
