import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaredList,
  rearrange,
  reArrangeStared
} from "../../../store/slices/ProfileGallery/profileGallerySlice";

export const useProfileGallery = () => {
  const dispatch = useDispatch();

  const profileGallery = useSelector((state) => state.profileGallery);

  useEffect(() => {
    if (profileGallery?.status == "idle") {
      dispatch(getStaredList());
    }
  }, []);

  const NodataContent = {
    title: `Your favorites are empty`,
    description: `The favorites collections you create are shown here`,
    buttonText: ``,
  };

  const rearrangeItems = (dragIndex, dropIndex) => {
    let imgData = profileGallery?.resultData?.data;
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
      },
    ];
    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrangeStared({ data: obj }));
    }
    dispatch(
      rearrange({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  return {
    profileGallery,
    NodataContent,
    rearrangeItems,
  };
};
