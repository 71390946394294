import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAlbum from "../../../Logic/useAlbum";
import useGallery from "../../../Logic/useGallery";
import {
  addAlbumListitemsHandler,
  currentSelectedAlbum,
} from "../../../store/slices/gallerySlice";
import Assets from "../../Layouts/Assets";
import Icons from "../../Layouts/Icons";
import Style from "./NewAlbumGridView.module.scss";
import loader from "../../../assets/images/loader/Spinner.svg";
import { useAlbumGridView } from "./useAlbumGridView";

export const AlbumCardItem = ({
  i,
  item,
  handleOpenEditModal,
  handleOpenDelete,
  handleOpenSlideShow,
  moveListItem,
  handleUnLockAlbum,
  selectedAlbumId,
  lastIndex,
  dataItems,
  options,
  albumItemRef,
  handleShareAlbum,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const observer = useRef();
  const someRef = useRef(null);
  const { sections, currentTab, isLocking } = useSelector(
    (state) => state.gallery
  );
  const { default_album_id } = useSelector(
    (state) => state.userprofile.userdata
  );
  const { openSingleAlbumDeleteModal } = useAlbum();
  const { albums, selections, handleClickSelection } =
    useAlbumGridView(default_album_id);

  const handleClickAlbumItem = (data) => {
    if (
      !selections?.ImageAlbumTab?.selction &&
      !data?.locked &&
      !item?.photofind_lock &&
      typeof data != "number"
    ) {
      dispatch(currentSelectedAlbum(data?.id));
      navigate(`${data?.id}`);
    }
  };

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { i },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: "item",
    spec: { index: i },
    drop: (item) => {
      moveListItem(item.i, i, albums.resultData.data);
    },
  });
  return (
    <div
      className={`${Style.grid_album_col} ${
        selections?.ImageAlbumTab?.selction &&
        item?.id != default_album_id &&
        !item?.photofind_album &&
        !item?.default &&
        !selections?.ImageAlbumTab.selectedImage?.includes(item?.id) &&
        Style.preselection
      }
       ${options?.isRearrange && Style.shake_active}`}
      key={i}
      onClick={() => handleClickSelection(item)}
      ref={options?.isRearrange ? dropRef : someRef}
    >
      <div
        className={`${Style.grid_album_card} 
        ${item?.locked || item?.photofind_lock ? Style.disabled : ""}
        `}
        onClick={() => (item?.locked ? handleUnLockAlbum(item?.id, i) : null)}
        ref={options?.isRearrange ? dragRef : someRef}
      >
        <figure
          className={`${Style.grid_album_card_image} ${
            item?.frameName == "None" ? Style.no_frame : ""
          } ${!item?.src?.thumbnail && Style.no_data} 
          ${(item?.locked || item?.photofind_lock) && Style.no_data}
          `}
          style={{
            borderImageSource: `url(${
              !(item?.frameName == "None") ? item.frame : ""
            })`,
          }}
          onClick={() => handleClickAlbumItem(item)}
          ref={lastIndex == i ? albumItemRef : null}
        >
          <img
            src={
              item?.locked || item?.photofind_lock
                ? Assets?.lock_icon
                : item?.default && item?.src?.preview
                ? item?.src?.preview
                : !(item?.src?.medium_thumbnail === undefined)
                ? item?.src?.medium_thumbnail
                : Assets.blank_folder
            }
            alt="Placeholder"
          />
        </figure>
        <div className={Style.grid_album_card_data}>
          {selections?.ImageAlbumTab.selectedImage.includes(item.id) &&
          selections?.ImageAlbumTab?.selction &&
          item?.id != default_album_id &&
          !item?.locked &&
          !item?.photofind_lock &&
          !item?.default &&
          !item?.photofind_album ? (
            <>
              <figure className={Style.gallery_select_tick}>
                <Icons icon={"icon-tick"} size={15} />
              </figure>
            </>
          ) : selections?.ImageAlbumTab?.selction &&
            item?.id != default_album_id &&
            !item?.locked &&
            !item?.default &&
            !item?.photofind_lock &&
            !item?.photofind_album ? (
            <div className={Style.selection} />
          ) : item?.locked || item?.photofind_lock ? (
            <div className={Style.lock_btn}>
              <Icons color={"currentColor"} icon={"lock"} size={18} />
            </div>
          ) : item?.id == selectedAlbumId && isLocking == "loading" ? (
            <img src={loader} className={Style.spinner} alt="" />
          ) : (
            <>
              {!options?.isRearrange && (
                <>
                  <Dropdown className={Style.grid_album_card_options}>
                    <Dropdown.Toggle
                      className={Style.grid_album_card_options_btn}
                    >
                      <Icons icon="icon-option-dots" size={12} />
                    </Dropdown.Toggle>
                    {item?.photofind_album ? (
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            handleOpenEditModal(item);
                          }}
                        >
                          Edit album
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => handleUnLockAlbum(item, i)}
                        >
                          {item?.locked ? "Unlock Album" : "Lock Album"}
                        </Dropdown.Item>

                        {item?.images?.length > 0 ? (
                          <Dropdown.Item
                            onClick={() => {
                              handleOpenSlideShow(item);
                            }}
                          >
                            Play slideshow
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}

                        <Dropdown.Item
                          disabled={true}
                          onClick={() => {
                            handleShareAlbum(item);
                          }}
                        >
                          Share
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            handleOpenEditModal(item);
                          }}
                        >
                          Edit album
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => handleUnLockAlbum(item, i)}
                        >
                          {item?.locked ? "Unlock Album" : "Lock Album"}
                        </Dropdown.Item>

                        {item?.images?.length > 0 ? (
                          <Dropdown.Item
                            onClick={() => {
                              handleOpenSlideShow(item);
                            }}
                          >
                            Play slideshow
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            handleShareAlbum(item);
                          }}
                        >
                          Share
                        </Dropdown.Item>

                        {/* for hiding delete menu from default album and photofind album */}
                        {item?.id != default_album_id &&
                        !item?.photofind_album &&
                        !item?.is_document &&
                        !item?.default ? (
                          <Dropdown.Item
                            onClick={() => handleOpenDelete(item?.id)}
                            style={{ color: "red" }}
                          >
                            Delete
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </>
              )}
            </>
          )}
          <div
            onClick={() => handleClickAlbumItem(item)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 className={Style.grid_album_card_title}>{item?.title}</h4>
            {item?.default ? (
              <img
                src={Assets.verified}
                alt="verified"
                width={20}
                style={{ marginRight: "6px" }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
