import React, { useState } from "react";
import Style from "./HomeCreateAndExplore.module.scss";
import ReactPlayer from "react-player";
import { Row, Col, Container } from "react-bootstrap";
import VideoPlayer from "../../utils/VideoPlayer";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";
import { useDimensions } from "../../Logic/Dimensions";
import { useTranslation } from "react-i18next";
import demoData from "../../utils/DemoVideosLinks/index";
import { useInView } from 'react-intersection-observer';
import { useEffect } from "react";

const HomeCreateAndExplore = () => {
  const windowDimensions = useDimensions();
  const { t } = useTranslation();
  const [play, setPlay] = useState(false);


  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setPlay(true)
    } else if (!inView) {
      setPlay(false)
    }
  }, [inView])



  return (
    <div className={Style.home_create_explore}>
      <Container>
        <Row>
          <Col>
            <div
              className={`${Style.home_create_explore_title
                } ${"section_title_block title_block_white"}`}
            >
              <h2 className="anim load-anim">{t("create&explore_title")}</h2>
              <p className="anim load-anim">{t("create&explore_desc")}</p>
            </div>
            {windowDimensions.width >= 992 ? (
              <div
                className={`${Style.home_create_explore_button} anim load-anim`}
              >
                <Link to="/get-started" className="btn btn-white">
                  {t("get_started")}
                </Link>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <div ref={ref} className="anim load-anim">
              <VideoPlayer
                play={play}
                setPlay={setPlay}
                vidUrl={demoData[3]?.url}
                autoplay={true}
                loop={true}
                isMuted={true}
              />
            </div>
          </Col>
        </Row>
        {!(windowDimensions.width >= 992) ? (
          <div className={`${Style.home_create_explore_button} anim load-anim`}>
            <Link to="/get-started" className="btn btn-white">
              {t("get_started")}
            </Link>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};

export default HomeCreateAndExplore;
