import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  changeScreen,
  authModalHandler,
  storeAuthDataProgress,
  mobileVerify,
  loginCreateOtp,
} from "../store/slices/authSlice";
import { getProfileDetails } from "../store/slices/userprofileSlice";
import { usePurchasedChecker } from "./AuthChecker/usePurchaseChecker";

export const useRegister = () => {
  const { planData, isPlanActive } = usePurchasedChecker();
  const [showModal, setShowModal] = useState(false);

  const [code, setCode] = useState("");
  const [showErrors, setShowErrors] = useState("");
  const [showOtpErrors, setshowOtpErrors] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mobileVerifyData } = useSelector((state) => state.auth);

  const {
    registerOtpData,
    registerData,
    registerStatus,
    loginStatus,
    loginData,
    currentAuthData,
    isEditMode,
  } = useSelector((state) => state.auth);

  const registerHandler = async (
    data,
    { resetForm, setShowRegister, setShowOtp }
  ) => {
    let newdata;
    if (isEditMode) {
      newdata = {
        country_dial_code: data.country_dial_code
          ? data.country_dial_code
          : "+91",
        country_code: data.country_code ? data.country_code : "IN",
        mail: data.mail,
        name: data.name,
        password: data.password,
        phone_number: data.phone_number,
        id: data.user_id,
      };
    } else {
      newdata = data;
    }

    let authDataProgress = {
      ...data,
      pageFrom: "register",
    };

    dispatch(storeAuthDataProgress(authDataProgress));

    const resultAction = await dispatch(getRegisterData(newdata));
    if (getRegisterData.fulfilled.match(resultAction)) {
      dispatch(storeCurrentAuthData(resultAction.payload.data.result));
      dispatch(changeScreen("otp"));
      resetForm();
    } else {
      if (resultAction.payload.validation) {
        let errors = resultAction.payload.validation.errors;
        Object.keys(data).forEach((key, index) => {
          if (errors[key] != undefined) {
            setShowErrors(errors[key][0]);

            setTimeout(() => {
              setShowErrors("");
            }, 3000);
          }
        });
      } else if (resultAction?.payload?.message) {
        setShowErrors(resultAction?.payload?.message);
        setTimeout(() => {
          setShowErrors("");
        }, 3000);
      }
    }
  };

  const onVerifyOtp = async (code, type) => {
    setshowOtpErrors("");
    setCode(code);
    if (code.length == 4) {
      const params = {
        otp: code,
        user_id: currentAuthData?.user_id,
        phone_number: currentAuthData?.phone_number,
      };
      const resultAction = await dispatch(verifyOtp(params));
      await dispatch(getProfileDetails());
      if (verifyOtp.fulfilled.match(resultAction)) {
        dispatch(storeAuthDataProgress({}));
        if (type == "login_otp") {
          dispatch(storeCurrentAuthData(resultAction.payload.data.result));
          dispatch(authModalHandler(false));
          dispatch(changeScreen(""));
          navigate("/choose-plan");
        }
        if (isPlanActive) {
          navigate("/dashboard");
        }
      } else {
        setshowOtpErrors(resultAction?.payload?.message);
      }
    } else {
    }
  };
  const OtpRegistration = async (values, resetForm) => {
    const mobileOtpVerification = await dispatch(mobileVerify(values));
    if (mobileOtpVerification?.payload?.data?.result?.existing_user == false) {
      dispatch(changeScreen("register"));
      resetForm();
    } else if (
      mobileOtpVerification?.payload?.data?.result?.existing_user == true &&
      mobileOtpVerification?.success !== false
    ) {
      let data = {
        country_code: mobileVerifyData?.country_code,
        country_dial_code: mobileVerifyData?.country_dial_code,
        phone_number: mobileVerifyData?.phone_number,
      };
      dispatch(loginCreateOtp(data));
      dispatch(changeScreen("otp"));
      resetForm();
    }
  };
  const handleGotoLogin = () => {
    dispatch(changeScreen("login"));
  };

  return {
    registerHandler,
    onVerifyOtp,
    registerStatus,
    loginStatus,
    registerOtpData,
    registerData,
    showModal,
    setShowModal,
    code,
    currentAuthData,
    showErrors,
    showOtpErrors,
    setshowOtpErrors,
    OtpRegistration,
    handleGotoLogin,
  };
};
