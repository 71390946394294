import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  sign_me_automatically: false,
  auto_adjust_positions: false,
  enable_edit_access: false,
  availableLanguage: [],
  selected_language: "",
  background_theme: "dark",
  is_data_loaded_from_api: false,
  default_view: "all",
  isDefaultViewActive: false,
  showLang: false,
  themeMode: "",
  defaultTheme: "",
};

export const getCurrentPreferenceStatus = createAsyncThunk(
  "userpreference/getCurrentPreferenceStatus",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user-preference`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "userpreference/getLanguages",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-languages`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePreferenceStatus = createAsyncThunk(
  "userpreference/updatePreferenceStatus",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { action, value } = obj;

      const packet = new FormData();
      packet.append("name", action);
      packet.append("value", value);

      const response = await api.post(`update-preference`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDefaultView = createAsyncThunk(
  "userpreference/updateDefaultView",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { action, value } = obj;

      const packet = new FormData();
      packet.append("name", action);
      packet.append("value", value);

      const response = await api.post(`update-preference`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkPassowrdForDeleteUserAccount = createAsyncThunk(
  "userpreference/checkPassowrdForDeleteUserAccount",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { password } = obj;

      const packet = new FormData();
      packet.append("password", password);

      const response = await api.post(`password-match`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ChangePasswordAPIcall = createAsyncThunk(
  "userpreference/ChangePasswordAPIcall",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { old_password, new_password } = obj;

      const packet = new FormData();
      packet.append("old_password", old_password);
      packet.append("new_password", new_password);

      const response = await api.post(`password-change`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteUserAccount = createAsyncThunk(
  "userpreference/deleteUserAccount",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`delete-user`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userPreferenceSlice = createSlice({
  name: "userpreference",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    isDefaultViewActive: (state, action) => {
      state.isDefaultViewActive = action.payload;
    },
    showLang: (state, action) => {
      state.showLang = action.payload;
    },
    setAvailableLanguage: (state, action) => {
      state.availableLanguage = action.payload;
    },
  },
  extraReducers: {
    [getCurrentPreferenceStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentPreferenceStatus.fulfilled]: (state, action) => {
      const {
        auto_adjust,
        background,
        enable_edit_access,
        language,
        sign_me_auto,
        default_view,
      } = action.payload.data.result;

      state.isLoading = false;
      state.sign_me_automatically = sign_me_auto;
      state.auto_adjust_positions = auto_adjust;
      state.enable_edit_access = enable_edit_access;
      state.background_theme = background;
      state.default_view = default_view;
      state.selected_language = language;
      state.is_data_loaded_from_api = true;
    },
    [getCurrentPreferenceStatus.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [updateDefaultView.pending]: (state) => {
      state.isLoading = true;
    },
    [updateDefaultView.fulfilled]: (state, action) => {
      const {
        auto_adjust,
        background,
        enable_edit_access,
        language,
        sign_me_auto,
        default_view,
      } = action.payload.data.result;

      state.isLoading = false;
      state.sign_me_automatically = sign_me_auto;
      state.auto_adjust_positions = auto_adjust;
      state.enable_edit_access = enable_edit_access;
      state.background_theme = background;
      state.default_view = default_view;
      state.selected_language = language;
      state.is_data_loaded_from_api = true;
    },
    [updateDefaultView.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getLanguages.pending]: (state) => {},
    [getLanguages.fulfilled]: (state, action) => {
      state.availableLanguage = action.payload.data?.result;
    },
    [getLanguages.rejected]: (state, action) => {},
    [updatePreferenceStatus.pending]: (state, action) => {
      // state.availableLanguage = action.payload.data?.result;
    },
    [updatePreferenceStatus.fulfilled]: (state, action) => {
      // state.availableLanguage = action.payload.data?.result;
      state.themeMode =
        action.payload?.data?.result?.background_theme_id === 1
          ? "dark"
          : "light";
      state.defaultTheme = action.payload?.data?.result?.background_theme_id;
      // value: themes == "light" ? 1 : 2, // (1 dark mode) (2 normal mode)
    },
    [updatePreferenceStatus.rejected]: (state, action) => {
      // state.availableLanguage = action.payload.data?.result;
    },
  },
});

export const {
  showLoading,
  isDefaultViewActive,
  showLang,
  setAvailableLanguage,
} = userPreferenceSlice.actions;

export default userPreferenceSlice.reducer;
