import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getFavoriteImages,
  LoadmoreImages,
  rearrangeFavorites,
  rearrange,
} from "../../store/slices/favoriteSlice";
import { endTabSession } from "../../store/slices/GalleryTabSlice";
import { getStaredList } from "../../store/slices/ProfileGallery/profileGallerySlice";

export const useFavorites = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const Images = useSelector((state) => state?.favorite);
  const selections = useSelector((state) => state.galleryTab);
  const ProfileGallery = useSelector((state) => state.profileGallery);

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  useEffect(() => {
    if (Images?.status == "idle") {
      let obj = {
        page: "1",
        slug: "pos",
      };
      dispatch(getFavoriteImages(obj));
    }
    if (ProfileGallery?.status == "idle") {
      dispatch(getStaredList());
    }

    return () => {
      dispatch(endTabSession({ tab: currentPath }));
    };
  }, []);

  const loadMore = (slug, type) => {
    if (Images?.resultData?.current_page < Images?.resultData?.last_page) {
      let obj = {
        page: Images?.resultData?.current_page + 1,
        slug: selections?.ImageFavoriteTab?.sort,
      };
      dispatch(LoadmoreImages(obj));
    }
  };

  const NodataContent = {
    title: `Your favorites are empty`,
    description: `The favorites collections you create are shown here`,
    buttonText: ``,
  };

  const rearrangeItems = (dragIndex, dropIndex) => {
    let imgData = Images?.resultData?.data;
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.fav_position,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.fav_position,
      },
    ];
    if (changedItem?.fav_position !== draggedItem?.fav_position) {

      dispatch(rearrangeFavorites({ data: obj }));
    }
    dispatch(
      rearrange({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  return {
    selections: selections?.ImageFavoriteTab,
    Images,
    NodataContent,
    status: Images.status,
    loadMore,
    rearrangeItems,
  };
};
