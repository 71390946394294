import { ErrorMessage, Field, Form, Formik } from "formik";
import { Col, Dropdown, Row } from "react-bootstrap";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import Style from "./PaymentAddress.module.scss";
import PaymentAddressDropdown from "./PaymentAddressDropdown";
import * as Yup from "yup";
import { usePlans } from "../../../Logic/ChoosePlans/usePlans";
import { useSelector } from "react-redux";
import CheckoutPlanDetails from "../../Payment/CheckoutPlanDetails";
import Assets from "../../Layouts/Assets";

let hasErrors = false;

const PaymentAddress = () => {
  let initialState = {
    name: "",
    line1: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
  };

  const nameRegExp = /^[a-zA-Z ]+$/;
  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  var numbers = /^\d+$/;

  const detailsSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegExp, "Please enter a valid full name")
      .min(3, "Names must have at least 2 characters")
      .max(100, "Names can't be longer than 100 characters")
      .required("Full name is required"),
    line1: Yup.string().required("*Address Is Required"),
    postal_code: Yup.string()
      .required("*Postal code Is Required"),
    city: Yup.string().required("*City Is Required"),

    country: Yup.string().required("*Country Is Required"),
  });
  const { status, data, selectedPlan } = useSelector((state) => state.payment);

  const { handleInitialtePayment, selectedId, country } = usePlans();

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values, { resetForm }) => {
        handleInitialtePayment(selectedPlan, values);
      }}
      validationSchema={detailsSchema}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        handleChange,
        dirty,
      }) => {
        hasErrors = Object.keys(errors).length == 0;
        return (
          <>
            <div className={Style.AddressComponent}>
              <div className={Style.AddressComponent__colLeft}>
                <CheckoutPlanDetails className={"p-0"} />
                <div className={`${Style.CheckOutPoweredBy}`}>
                  Powered by
                  <img src={Assets.stripe_logo} alt="" />
                </div>
              </div>
              <div className={Style.AddressComponent__colRight}>
                <h2 className={Style.AddressComponent_title}>
                  Billing Address
                </h2>

                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Full Name*
                      </label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="name"
                        placeholder="Full Name*"
                        name="name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="name" className="field_error_message">
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Flat No./House Name*
                      </label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="line1"
                        placeholder="Flat No./House Name*"
                        name="line1"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="line1" className="field_error_message">
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">City *</label>
                      {/* <PaymentAddressDropdown data={["city 1", "city 2", "city 3"]} /> */}
                      <input
                        className="edit_profile_form_input form-control"
                        id="city"
                        placeholder="City Name*"
                        name="city"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="city" className="field_error_message">
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">State *</label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="state"
                        placeholder="State Name*"
                        name="state"
                        type="text"
                        // onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>

                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Country *
                      </label>
                      {/* <input
                        className="edit_profile_form_input form-control"
                        id="country"
                        placeholder="Country Name*"
                        name="country"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      /> */}
                      <PaymentAddressDropdown
                        data={country}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <ErrorMessage
                      name="country"
                      className="field_error_message"
                    >
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Postal code *
                      </label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="postal_code"
                        placeholder="Postal code*"
                        name="postal_code"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage
                      name="postal_code"
                      className="field_error_message"
                    >
                      {(msg) => (
                        <div
                          style={{
                            color: "#FF6261",
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                          className="error-msg"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col md="12">
                    <button
                      className="btn btn-primary mt-3 w-100"
                      disabled={!hasErrors || !dirty}
                      onClick={handleSubmit}
                    >
                      {status == "loading" ? <InfiniteDotLoader /> : "Continue"}
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default PaymentAddress;
