import React from "react";
import { useEffect } from "react";
import CommonModal from "../../../../Modals/commonModal";
import Style from "./slideshowCreateModal.module.scss";
import { useSlideshowCreateModal } from "./useSlideshowCreateModal";
import { useSlideshow } from "../../../../Slideshow/useSlideshow";

const CreateSlideShowModal = ({
  slideshowCreateModal,
  setSlideshowCreateModal,
}) => {
  const { slideName, handleChangeName, handleSubmit, setSlideName } =
    useSlideshowCreateModal({ setSlideshowCreateModal });

  const { Selections, SlideShows, NodataContent, slideRef, status } =
    useSlideshow();
  const isExistingName = SlideShows?.resultData?.data?.some(
    (item) => item?.title?.toLowerCase() === slideName?.toLowerCase()
  );

  useEffect(() => {
    setTimeout(() => {
      if (slideshowCreateModal.popup) {
        document.querySelectorAll(".modal input")[0].focus();
      }
    }, 500);

    return () => {
      setSlideName("");
    };
  }, [slideshowCreateModal.popup]);

  const CreateElement = () => {
    return (
      <div className={Style.create_slideshow_modal_content}>
        <h3 className={Style.create_slideshow_modal_title}>Create Slideshow</h3>
        <div className="form-group">
          <label className={Style.slideshow_name_label}>Slideshow name</label>
          <input
            autoFocus={true}
            className={`${Style.slideshow_name_input} form-control`}
            placeholder="Untitled Slideshow"
            value={slideName}
            onChange={(e) => handleChangeName(e)}
          />
          {isExistingName && (
            <span className={Style.warning}>This name is already exist.</span>
          )}
        </div>
        <div className={Style.create_slideshow_modal_actions}>
          <button
            disabled={slideName?.length == 0 || isExistingName}
            onClick={handleSubmit}
            className="btn btn-sm btn-secondary"
          >
            Create
          </button>
          <button
            className="btn btn-sm btn-border-grey"
            onClick={() => setSlideshowCreateModal({ popup: false })}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <CommonModal
      showModal={slideshowCreateModal.popup}
      hide={() => setSlideshowCreateModal({ popup: false })}
      className={Style.create_slideshow_modal}
      content={<CreateElement />}
    />
  );
};

export default CreateSlideShowModal;
