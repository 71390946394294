import React, { useState } from "react";
import FullscreenPreview from "../../components/Gallery/FullscreenPreview";
import { useImageViewer } from "../../Logic/useImageViewer";

function ImageViewer() {

  const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });

  const { ImageViewerData } = useImageViewer();
  

  return (
    <>
      <FullscreenPreview
        setShowFullView={setShowFullView}
        showFullView={showFullView}
        data={ImageViewerData}
        pathFrom={"search"}
      />
    </>
  );
  
}

export default ImageViewer;
