import Style from "./FrameItem.module.scss";

const FrameItem = ({
    frameSrc,
    frameTitle, 
    frameSelected,
    onClickfun
    }) => {

    return (
        <>
        <div className={`${Style.frame_item} ${frameSelected == true ? Style.frame_selected :""}`} onClick={onClickfun}>
            <div className={Style.frame_item_container}>
                <div className={Style.frame_item_img}>
                    <img src={frameSrc} alt="" />
                </div>
                <div className={Style.frame_item_name}>
                    <h5 className={Style.frame_item_name_title}>{frameTitle}</h5>
                </div>
            </div>
        </div>
        </>
    );
};


export default FrameItem;