import Style from "./SearchTag.module.scss";
import { Dropdown } from "react-bootstrap";

const SearchTag = ({
  setSearchTag,
  tag,
  searchTag,
  tagsCollections,
  setTag
}) => {
  return (
    <Dropdown style={{ width: "100%" }}>
    <Dropdown.Toggle>{searchTag}</Dropdown.Toggle>
    <Dropdown.Menu>
      <div
        className={`${Style.search_results_filter_searchbar} form-group`}
      >
        <input
          type="Tag"
          className="form-control"
          placeholder="Tag"
          value={tag}
          onChange={(e) => {
            setTag(e.target.value);
          }}
        />
      </div>
      <div className="scrollDiv">
        {tagsCollections
          .filter((ele) => {
            if (tag !== "") {
              return ele.indexOf(tag) !== -1;
            } else {
              return true;
            }
          })
          .map((element, index, array) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={() => setSearchTag(element)}
              >
                {element}
              </Dropdown.Item>
            );
          })}
      </div>
    </Dropdown.Menu>
  </Dropdown>
  );
};

export default SearchTag;
