import { useDispatch, useSelector } from "react-redux";
import { getAllImages } from "../../../../../store/slices/Gallery/fileUploadSlce";
import { disableImageSelection } from "../../../../../store/slices/GalleryTabSlice";
import { deleteTrash } from "../../../../../store/slices/Trash/trashSlice";
import { getProfileDetails } from "../../../../../store/slices/userprofileSlice";
import { getAlbumItem } from "../../../../../store/slices/Album/AlbumDetails";
import { useParams } from "react-router-dom";

export const useDeleteTrashImageModal = ({ setTrashDeleteModal }) => {
  const dispatch = useDispatch();
  const selectedImage = useSelector((state) => state.galleryTab.ImageTrashTab);
  const { isDeleting } = useSelector((state) => state.trash);
  const { albumId } = useParams();

  const handleDelete = async () => {
    let image_id = selectedImage?.selectedImage;

    const resultAction = await dispatch(deleteTrash({ image_id }));
    if (deleteTrash.fulfilled.match(resultAction)) {
      dispatch(disableImageSelection({ tab: "trash" }));
      setTrashDeleteModal({ popup: false });
      dispatch(getProfileDetails());
      dispatch(getAllImages());
    }
  };

  return {
    selectedImage,
    handleDelete,
    status: isDeleting,
  };
};
