import ImageListMasonry from "../ImageListMasonry";
import { useTrash } from "./useTrash";

const Trash = () => {
  const { Trash, loadMore, NodataContent, status } = useTrash();
  return (
    <ImageListMasonry
      Images={Trash.resultData.data}
      loadMore={loadMore}
      NodataContent={NodataContent}
      status={status}
      total={Trash.resultData.data?.length}
    />
  );
};
export default Trash;
