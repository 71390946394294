import React, { useState } from "react";
import Style from "./ContactForm.module.scss";
import { Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import CountryObj from "../../utils/country.json";
import { useContactForm } from "../../Logic/useContactForm";
import { useTranslation } from "react-i18next";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import Select from "react-select";

const ContactForm = () => {
  const {
    contactFormSubmit,
    success,
    submitButtonLabel,
    message,
    formError,
    status,
  } = useContactForm();
  const [contactCountry, setContactCountry] = useState("INDIA");
  const { t } = useTranslation(["contact"]);
  const options = CountryObj.map((country) => ({
    label: country.name,
    value: country.name,
  }));
  const contactCountries = [
    {
      id: 1,
      name: "INDIA",
    },
    {
      id: 2,
      name: "ITALY",
    },
    {
      id: 3,
      name: "RUSSIA",
    },
    {
      id: 4,
      name: "FRANCE",
    },
  ];

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (!value) {
      error = "Required";
    } else if (!re.test(value)) {
      error = "Please enter a valid phone number";
    }
    return error;
  }

  function validateCountry(value) {
    let error;
    if (value == "") {
      error = "Required";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value === "") {
      error = "Required";
    } else if (!/^(?![\s-])[\w\s-]+$/.test(value)) {
      error = "Invalid name. Space not allowed at begining.";
    }
    return error;
  }

  function handleKeyDown(e) {
    if (
      e.key === "e" ||
      e.key === "E" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === " " ||
      e.key === "Enter"
    ) {
      e.preventDefault();
    }
  }

  const CountryComponent = ({ field, form, ...props }) => {
    const handleCountry = (selectedoption) => {
      form.setFieldValue("country", selectedoption.value);
    };
    return (
      <Dropdown
        {...field}
        className={`${Style.contact_form_input} anim header-anim`}
        data-lenis-prevent
      >
        {/* <Dropdown.Toggle>
          <span className={Style.contact_form_label}>
            {form.values.country}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {CountryObj.map((country) => {
            return (
              <Dropdown.Item
                key={country.code}
                onClick={(e) => {
                  form.setFieldValue("country", country.name);
                }}
              >
                {country.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu> */}
        <Select
          className={Style.countrySelect}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none",
              outline: "none",
              borderColor: "none",
              background: "transparent",
              color: "white",
              boxShadow: "none",
              zIndex: 999,
            }),

            singleValue: (provided, state) => ({
              ...provided,
              color: "white",
            }),
            input: (provided, state) => ({
              ...provided,
              color: "white",
              border: "none",
              outline: "none",
              boxShadow: "none",
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              border: "none",
              boxShadow: "none",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#A7ABB6",
              primary: "#50586D",
            },
          })}
          classNamePrefix="select"
          defaultValue={options.find(
            (country) => country.label.toLowerCase() === "india"
          )}
          onChange={handleCountry}
          isSearchable={true}
          name="country"
          options={options}
        />
      </Dropdown>
    );
  };

  return (
    <div className={Style.contact_form}>
      <h2 className={Style.contact_form_title}>{t("contact_form_title")}</h2>

      <div className={`${Style.contact_form_inputs}`}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            country: "INDIA",
            message: "",
          }}
          onSubmit={(values, { resetForm }) => {
            // same shape as initial values
            contactFormSubmit(values, resetForm);
          }}
        >
          {({ errors, values, touched, handleSubmit }) => {
            return (
              <>
                <div className="form-group">
                  <Field
                    name="name"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-name"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validateName}
                  />
                  {errors.name && (
                    <div className={Style.field_error}>{errors.name}</div>
                  )}
                  <label
                    className={Style.contact_form_label}
                    for="contact-name"
                  >
                    {t("full_name")}*
                  </label>
                </div>

                <div className="form-group">
                  <Field
                    name="email"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-email"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validateEmail}
                  />
                  {errors.email && (
                    <div className={Style.field_error}>{errors.email}</div>
                  )}
                  <label
                    className={Style.contact_form_label}
                    for="contact-email"
                  >
                    {t("email_address")}*
                  </label>
                </div>

                <div className="form-group">
                  <Field
                    name="phone"
                    className={`${Style.contact_form_input} form-control`}
                    id="contact-phone"
                    type="number"
                    onKeyDown={handleKeyDown}
                    inputMode="numeric"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    validate={validatePhone}
                  />
                  {errors.phone && (
                    <div className={Style.field_error}>{errors.phone}</div>
                  )}
                  <label
                    className={Style.contact_form_label}
                    for="contact-phone"
                  >
                    {t("phone_number")}*
                  </label>
                </div>

                <div className="form-group">
                  <Field name="country" component={CountryComponent} />
                </div>

                <div className="form-group textarea">
                  <Field
                    as="textarea"
                    name="message"
                    className={`${Style.contact_form_input} ${Style.textArea} form-control `}
                    id="contact-message"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) =>
                      !e.target.value && e.target.classList.remove("focused")
                    }
                    spellCheck="false"
                  ></Field>
                  {errors.message && (
                    <div className={Style.field_error}>{errors.message}</div>
                  )}
                  <label
                    className={Style.contact_form_label}
                    for="contact-message"
                  >
                    {t("Message")}
                  </label>
                </div>

                <div className={Style.contact_form_submit}>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {status == "loading" ? <InfiniteDotLoader /> : t("submit")}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
        {(success || formError) && (
          <div className={Style.success_message}>
            {success && (
              <span className={Style.success}>{message.success}</span>
            )}
            {formError && <span className={Style.error}>{message.error}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
