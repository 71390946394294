import React, { useState, useEffect, useRef } from "react";
import Style from "../EditProfilePage.module.scss";
import Assets from "../../Layouts/Assets";
import { useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import { useEmailUpdate } from "../../../Logic/useEmailUpdate";
import { useEmailUpdateOtp } from "../../../Logic/useEmailUpdateOtp";
import { ProfileUpdateInfo } from "../../../Logic/useProfileUpdateInfo";
import OtpInput from "react-otp-input";
import { useUpdateEmail } from "./useUpdateEmail";

const ComponentEmailUpdateOTP = ({ data, ClosePopup }) => {
  const [NewEmail, setNewEmail] = useState("");
  const [ConfirmEmail, setConfirmEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { EmailChangeSubmit, success, error_message, formError } =
    useEmailUpdate();

  const { verifyForgotOtp, code, showOtpResponse, verifyOtpStatus } =
    useEmailUpdateOtp(ClosePopup);

  const { otpCounter, setOtpCounter, otpResponse, resentEmailOtp, formatTime } =
    useUpdateEmail();

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [otpCounter]);

  return (
    <Formik
      initialValues={{
        new_email: "",
        confirm_email: "",
        password: "",
      }}
      onSubmit={(values, { resetForm }) => {}}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <>
            <div className={Style.edit_profile_info_form}>
              {verifyOtpStatus ? (
                <>
                  <h3 className={Style.edit_profile_info_title}>Success</h3>
                  <p>Email has been updated successfully.</p>
                </>
              ) : (
                <>
                  <div className={Style.edit_profile_otp_update_modal_wrap}>
                    <h3 className={Style.edit_profile_info_title}>
                      Enter OTP details
                    </h3>
                    <p>
                      OTP has been sent on your Email <span>{data?.mail}</span>
                    </p>

                    <div className="form-group">
                      <label htmlFor="input_test"></label>
                      <OtpInput
                        value={code}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        name="otp"
                        onChange={(e) => {
                          verifyForgotOtp(e, "forgot_password");
                          setFieldValue("otp", e);
                        }}
                        numInputs={4}
                        onBlur={handleBlur}
                        inputStyle={`${
                          showOtpResponse?.status == false
                            ? Style.input_Style_error
                            : ""
                        }`}
                        className="otp-field"
                      />
                    </div>
                    <p>
                      Didn't receive an OTP?{" "}
                      {otpCounter > 0 && formatTime(otpCounter)}
                      {otpCounter == 0 ? (
                        <span
                          className={Style.btnText}
                          onClick={() => resentEmailOtp(data?.mail)}
                        >
                          Resendy
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {
                    <span
                      style={{
                        color: `${otpResponse?.success ? "green" : "red"}`,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {otpResponse?.message ? "Otp send successfully" : ""}
                    </span>
                  }

                  <p
                    className={`${Style.otpResponse} ${
                      showOtpResponse?.status == true ? Style.success : ""
                    }`}
                  >
                    {showOtpResponse.message && showOtpResponse.message}
                  </p>
                </>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ComponentEmailUpdateOTP;
