import React, { useEffect, useState } from "react";
import Style from "./FileUploadMinimizedBox.module.scss";
import Icons from "../Layouts/Icons";
import { useDispatch } from "react-redux";
import { openMiniUploadBox } from "../../store/slices/Gallery/fileUploadSlce";
import { useRef } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { useFileUploadMini } from "./useFileUploadMini";
import ImageThumb from "./ImageThumb";
import { FixedSizeList } from "react-window";
import Assets from "../Layouts/Assets";

const FileUploadMinimizedBox = ({
  images,
  duplicateImages,
  invalidImages,
  uploadIndex,
  allFiles,
  close,
  isLimit,
  miniBoxCount,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();
  const nameRef = useRef();
  const windowDimensions = useDimensions();

  function ListItem({ data, index, style }) {
    return (
      <div className={Style.fu_body_list} style={style} data-lenis-prevent>
        {data[index]?.duplicate ? (
          <ImageThumb
            image={data?.[index]}
            name={data?.[index]?.name}
            isDupicate
          />
        ) : data[index]?.invalid ? (
          <ImageThumb name={data?.[index]?.name} />
        ) : (
          <ImageThumb
            image={data[index]}
            name={data?.[index]?.file?.name}
            isImageSuccess
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${Style.file_upload_minimized} ${
        isCollapsed && Style.file_upload_collapsed
      }`}
      data-lenis-prevent
    >
      <div className={Style.file_upload_minimized_head}>
        <h5 className={Style.fu_head_title}>
          {isLimit ? (
            <>
              Your storage is full
              <figure className={Style.limitmini}>
                <img src={Assets.limitIcon} alt="file_upload_image" />
              </figure>
            </>
          ) : miniBoxCount == uploadIndex ? (
            `Uploaded ${miniBoxCount} Photos`
          ) : (
            `Uploading ${miniBoxCount - uploadIndex} Photos`
          )}
        </h5>
        <div className={Style.fu_head_actions}>
          <button
            className={Style.collapse_upload}
            onClick={() =>
              isCollapsed ? setIsCollapsed(false) : setIsCollapsed(true)
            }
          >
            <Icons icon={"next-arrow"} size={14} />
          </button>
          <button className={Style.cancel_upload} onClick={() => close()}>
            <Icons icon={"icon-close"} size={10} />
          </button>
        </div>
      </div>
      <div
        className={`${Style.file_upload_minimized_body} ${
          isLimit && Style.limit_exceded
        }`}
      >
        <FixedSizeList
          itemData={[
            ...images?.reverse(),
            ...duplicateImages,
            ...invalidImages,
          ]}
          height={isCollapsed ? 0 : 270}
          itemCount={[...images, ...duplicateImages, ...invalidImages].length}
          itemSize={70}
          width={400}
          direction="vertical"
          style={{ flex: "1 0", width: "100%" }}
        >
          {ListItem}
        </FixedSizeList>
      </div>
    </div>
  );
};

export default FileUploadMinimizedBox;
