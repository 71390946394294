import Style from "./UploadImageModal.module.scss";
import useUploadImageModal from "../../../../../Logic/useUploadImageModal";
import CommonModal from "../../../../Modals/commonModal";
import Assets from "../../../../Layouts/Assets";
import { Swiper, SwiperSlide } from "swiper/react";
import Icons from "../../../../Layouts/Icons";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper";
import Thumb from "./Thumb";
import { FixedSizeList, areEqual } from "react-window";
import { useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import memoize from "memoize-one";
import React, { memo } from "react";
import { useItemProgressListener } from "@rpldy/uploady";

const UploadImageModal = ({
  closeUploadModalWhenDone,
  openActionModal,
  handleImageChange,
  handleDragOver,
  handleDragOut,
  handleDrop,
  openFiles,
  closeModal,
  isDraggingOver,
  imagesLength,
  images,
  duplicateImages,
  invalidImages,
  uploadIndex,
  isUploading,
  allFiles,
  newFiles,
  fileIndex,
  isLimit,
  checkData,
  uploadValidation,
}) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const sliderRef = useRef(null);

  const [isScrollLeft, setIsScrollLeft] = useState(0);

  const ListItem = ({ data, index, style }) => {
    return (
      <div
        className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}
        style={style}
      >
        {data[index]?.duplicate ? (
          <Thumb duplicate={data[index]?.name} />
        ) : data[index]?.invalid ? (
          <Thumb invalid={data[index]?.name} />
        ) : (
          <Thumb image={data[index]} />
        )}
      </div>
    );
  };

  let tempImg = images[uploadIndex] ? [images[uploadIndex]] : [];
  return (
    <CommonModal
      className={Style.file_upload_modal}
      showModal={openActionModal?.isOpen}
      hide={() => closeModal()}
      content={
        <div className={Style.file_upload_modal_form}>
          <div
            className={`${Style.file_upload_draggable_area} ${
              isLimit && Style.limit_exceded
            }`}
            style={{
              backgroundColor: !isDraggingOver
                ? ""
                : "var(--file-upload-hover-bg)",
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragOut}
          >
            {isLimit && uploadValidation?.message ? (
              <>
                <figure className={Style.file_upload_warning}>
                  <img src={Assets.limitIcon} alt="file_upload_image" />
                </figure>
                <h4 className={Style.file_upload_info}>Upload stopped!</h4>
                <p className={Style.file_upload_info_desc}>
                  {uploadValidation?.message}
                </p>
              </>
            ) : (
              <>
                <figure className={Style.file_upload_image}>
                  <img src={Assets.file_upload_image} alt="file_upload_image" />
                </figure>
                <h4 className={Style.file_upload_info}>
                  Drag and drop{" "}
                  {openActionModal?.mode == "document" ? "files" : "photos"}, or{" "}
                  <label
                    htmlFor="file_upload_input"
                    className={Style.file_upload_input_label}
                  >
                    Select/Browse
                  </label>
                  &nbsp;
                  {openActionModal?.mode == "document" ? "files" : "photos"}
                </h4>
                <p className={Style.file_upload_info_desc}>
                  Upload your{" "}
                  {openActionModal?.mode == "document" ? "files" : "photos"}{" "}
                  here to bring your stories to life.
                </p>
                <input
                  onChange={handleImageChange}
                  className={Style.file_upload_input}
                  id="file_upload_input"
                  multiple
                  type="file"
                ></input>
              </>
            )}
          </div>
          <div className={Style.file_upload_preview}>
            <>
              <div className={`${Style.file_upload_status} me-3 ms-0`}>
                <div
                  className={`${Style.add_more_btn_contents}  ${
                    !isLimit && uploadIndex !== allFiles?.length
                      ? "inprogress"
                      : ""
                  } `}
                >
                  <strong className="mb-sm-1">File Upload</strong>

                  <span>
                    {uploadIndex}/{allFiles?.length}
                  </span>

                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    variant="danger"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        <span>
                          Total - <strong>{allFiles?.length}</strong>
                        </span>
                        <span>
                          Uploaded - <strong>{uploadIndex}</strong>
                        </span>
                        <span>
                          Duplicate - <strong>{duplicateImages?.length}</strong>
                        </span>
                        <span>
                          Invalid - <strong>{invalidImages?.length}</strong>
                        </span>
                      </Tooltip>
                    }
                  >
                    <button
                      className={`btn btn-link ${Style.upload_image_info_btn}`}
                    >
                      <Icons
                        className="d-inline-block pt-1"
                        icon={"info"}
                        color={"#ffad50"}
                        size={18}
                      />
                    </button>
                  </OverlayTrigger>

                  {/* <span className="mb-sm-1">5/100</span>
                  <strong className="mb-0">File Upload</strong> */}

                  {/* <span className={Style.usage_limit_exeeded}>Usage Limit Exceeded</span> */}
                </div>
              </div>

              <div
                className={Style.file_upload_list_wrap_inneritem}
                ref={sliderRef}
                // onMouseDown={handleMouseDown}
                // onMouseLeave={handleMouseLeave}
                // onMouseUp={handleMouseUp}
                // onMouseMove={handleMouseMove}
              >
                {/* 
                <div
                  className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}

                >

                  <Thumb image={data[index]} />

                </div> */}

                <FixedSizeList
                  itemData={[
                    ...tempImg,
                    ...images
                      .slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      )
                      .reverse(),
                    ...duplicateImages
                      ?.slice(
                        duplicateImages?.length - 3 > 0
                          ? duplicateImages?.length - 3
                          : 0,
                        duplicateImages?.length
                      )
                      .reverse(),
                    ...invalidImages
                      ?.slice(
                        invalidImages?.length - 3 > 0
                          ? invalidImages?.length - 3
                          : 0,
                        invalidImages?.length
                      )
                      .reverse(),
                  ]}
                  height={70}
                  itemCount={
                    [
                      ...tempImg,
                      ...images.slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      ),
                      ...duplicateImages
                        ?.slice(
                          duplicateImages?.length - 3 > 0
                            ? duplicateImages?.length - 3
                            : 0,
                          duplicateImages?.length
                        )
                        .reverse(),
                      ...invalidImages
                        ?.slice(
                          invalidImages?.length - 3 > 0
                            ? invalidImages?.length - 3
                            : 0,
                          invalidImages?.length
                        )
                        .reverse(),
                    ].length
                  }
                  itemSize={124}
                  width={400}
                  direction="horizontal"
                  style={{ flex: "1 0", width: "100%" }}
                  className={Style.file_upload_list_wrap}
                >
                  {ListItem}
                </FixedSizeList>

                {/* {imgObj?.length == 0 && (
                  <>
                    <div
                      className={`${Style.file_upload_img_wrap}`}
                    >
                      {[...Array(4)].map((_, i) => (
                        <div
                          key={i}
                          className={`${Style.file_upload_preview_wrap} ${Style.blank_image}`}
                        >
                          <figure
                            className={Style.file_upload_preview_image}
                          ></figure>
                        </div>
                      ))}
                    </div>
                  </>
                )} */}
              </div>

              <button className={Style.add_more_btn} onClick={openFiles}>
                <div className={Style.add_more_btn_contents}>
                  <Icons icon={"add"} size={12} />
                  <span>Add more</span>
                </div>
              </button>
            </>
          </div>
          {uploadIndex === allFiles?.length &&
            uploadIndex !== 0 &&
            allFiles?.length !== 0 && (
              <div className={Style.successMsg}>
                <p>Image successfully uploaded</p>
              </div>
            )}
          {/* <div className={Style.file_upload_btn_wrap}>
            <button
              disabled={
                allFiles?.length > 0 && uploadIndex == allFiles?.length ? false : true
                  
              }
              className="btn btn-secondary"
              onClick={() => closeUploadModalWhenDone()}
            >
              Done
            </button>
          </div> */}

          {/* <div>
            {errorMessage && (
              <p className={Style.errorMessage}>{errorMessage}</p>
            )}
          </div> */}
        </div>
      }
    />
  );
};

export default UploadImageModal;

const items = new Array(5000).fill().map((_, index) => `Item ${index}`);

// style for failed items
// ${failedImageIndexs?.includes(i) && Style.uploadfail}
