import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailUpdateForm } from "../../../store/slices/emailUpdateSlice";

export const useUpdateEmail = () => {
  const dispatch = useDispatch();
  const [otpCounter, setOtpCounter] = useState(180);
  const [otpResponse, setOtpResponse] = useState({
    message: "",
    success: null,
  });
  const formatOtp = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes} minute${
      minutes !== 1 ? "s" : ""
    } and ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`;
  };
  const { emailUpdateData } = useSelector((state) => state.userEmailUpdate);

  const resentEmailOtp = async () => {
    const resultAction = await dispatch(emailUpdateForm(emailUpdateData));
    if (emailUpdateForm.fulfilled.match(resultAction)) {
      setOtpCounter(180);
      setOtpResponse({
        message: "OTP Resend Success",
        success: true,
      });
      setTimeout(() => {
        setOtpResponse({});
      }, 1500);
    } else {
      if (resultAction.payload) {
        setOtpCounter(180);
        setOtpResponse({
          message: "OTP Resend Failed",
          success: false,
        });

        setTimeout(() => {
          setOtpResponse({});
        }, 1500);
      }
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  return {
    otpCounter,
    otpResponse,
    resentEmailOtp,
    setOtpCounter,
    formatTime,
  };
};
