import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const useAudioPlayer = (musicList) => {
  // const { musicList } = useSelector((state) => state.gallery);
  const [isSelected, setIsSelected] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [mute, setMute] = useState(false);

  // const { name, file_name } = musicList[trackIndex];

  const audioRef = useRef(null);
  const progressBarRef = useRef();
  const isReadyRef = useRef(false);
  const animationRef = useRef();

  // musicList[trackIndex]?.file_name
  useEffect(() => {
    audioRef.current = new Audio(musicList[trackIndex]?.file_name);
  }, [musicList]);





  useEffect(() => {
    if (currentTime == duration) {
      audioRef.current.pause();
      setIsPlaying(false);
      cancelAnimationFrame(animationRef.current);
      timeTravel(0);
    }
  }, [currentTime]);

  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(musicList[trackIndex]?.file_name);
    audioRef.current.preload = "metadata";
    audioRef.current.loop = true;


    if (isReadyRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      // Set the isReady ref as true for the next pass
      isReadyRef.current = true;
      setIsPlaying(false);
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef?.current?.pause();
      setIsSelected(false);
    };
  }, []);

  const playTrack = (i) => {
    setIsSelected(true);
    setTrackIndex(i);
    if (trackIndex == i && isPlaying) {
      audioRef?.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef?.current?.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      audioRef.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [isPlaying]);

  const play = () => {
    audioRef?.current?.play();
    // animationRef.current = requestAnimationFrame(whilePlaying);
  };

  useEffect(() => {
    const seconds = Math.floor(audioRef.current.duration);
    setDuration(seconds);
    if (progressBarRef?.current?.max) {
      //progressBarRef.current.max = seconds;
    }
  }, [audioRef?.current?.loadeddata, audioRef?.current?.readyState]);

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${returnedMinutes}:${returnedSeconds}`;
  };

  // const whilePlaying = () => {
  //   progressBarRef.current.value = audioRef?.current?.currentTime;
  //   changePlayerCurrentTime();
  //   animationRef.current = requestAnimationFrame(whilePlaying);
  // };

  const changePlayerCurrentTime = () => {
    //progressBarRef.current.style.setProperty('--seek-before-width', `${progressBarRef.current.value / duration * 100}%`)
    setCurrentTime(progressBarRef?.current?.value);
  };

  const changeRange = () => {
    // audioRef.current.currentTime = progressBarRef.current.value;
    changePlayerCurrentTime();
  };

  const timeTravel = (newTime) => {
    if (progressBarRef?.current?.value) {
      progressBarRef.current.value = newTime;
      changeRange();
    }
  };

  const handleMute = () => {
    if (mute) {
      audioRef.current.muted = false;
      setMute(false);
    } else {
      audioRef.current.muted = true;
      setMute(true);
    }
  };

  return {
    name: musicList[trackIndex]?.name,
    trackIndex,
    setTrackIndex,
    isPlaying,
    duration,
    currentTime,
    audioRef,
    progressBarRef,
    isReadyRef,
    animationRef,
    calculateTime,
    playTrack,
    changeRange,
    isSelected,
    handleMute,
    mute,
    setMute,
    audioRef
  };
};

export default useAudioPlayer;
