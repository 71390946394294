import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
  useUploadyContext,
} from "@rpldy/uploady";

import loadImage from "blueimp-load-image";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllImages,
  openMiniUploadBox,
  openUploadModalAction,
  updateAllUploadedImages,
} from "../../../../../store/slices/Gallery/fileUploadSlce";
import imageCompression from "browser-image-compression";
import { useParams } from "react-router-dom";
import {
  getAlbumItem,
  updateAlbumUploadedImages,
} from "../../../../../store/slices/Album/AlbumDetails";
import {
  getProfileDetails,
  updatePlanPercentage,
} from "../../../../../store/slices/userprofileSlice";
import { getTrashList } from "../../../../../store/slices/Trash/trashSlice";
import { getAlbums } from "../../../../../store/slices/albumSlice";
import { getGalleryImages } from "../../../../../store/slices/gallerySlice";

var allowedExtensions = new RegExp(
  /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|HEIC|heic)$/
);
let completedImages = [];
const useImageUpload = ({ mode }) => {
  let { albumId } = useParams();
  const uploady = useUploady();
  const abortAll = useAbortAll();
  const dispatch = useDispatch();
  const fileUploadState = useSelector((state) => state.fileUpload);
  const Trash = useSelector((state) => state?.trash);
  const selections = useSelector((state) => state.galleryTab);

  const fileAlbumState = useSelector((state) => state.albumDetails);
  const { userdata } = useSelector((store) => store.userprofile);
  const { abort, upload } = useUploadyContext();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [duplicateImages, setDuplicateImages] = useState([]);
  const [invalidImages, setInvalidImages] = useState([]);
  // const [completedImages, SetCompletedImages] = useState([]);
  const [uploadAlert, setUploadAlert] = useState(false);

  const [uploadIndex, setUploadIndex] = useState(0);
  const [fileIndex, setFileIndex] = useState(0);

  const [isLimit, setIsLimit] = useState(false);
  const [uploadValidation, setUploadValidation] = useState();
  const [miniBoxCount, setMiniBoxCount] = useState();

  const imagesLength =
    images.length + duplicateImages.length + invalidImages.length;

  const QA_URL = process.env.REACT_APP_APIURL_QA;
  const TEST_URL = process.env.REACT_APP_APIURL;
  const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

  let api =
    process.env.REACT_APP_DEV_ENV == "PRODUTION"
      ? BASE_URL
      : process.env.REACT_APP_DEV_ENV == "DEMO"
      ? TEST_URL
      : QA_URL;

  //FIXME handle disabled state for the modal
  useEffect(() => {
    dispatch(getAllImages());
    dispatch(getTrashList());
  }, []);

  useEffect(() => {
    if (!isUploading) {
      compressedUpload(0);
    }
  }, [files]);

  let albumIds = albumId
    ? fileAlbumState?.resultData?.data?.[albumId]?.images?.map(
        (item) => item?.id
      )
    : [];

  let getGetAlbumImages = fileUploadState.allUploadedImages?.filter((item) =>
    albumIds?.includes(item?.id)
  );

  let currentAlbum = fileAlbumState?.resultData?.data?.[albumId];
  useEffect(() => {
    if (
      !fileUploadState.openUploadModal?.isOpen &&
      uploadIndex !== images?.length
    ) {
      dispatch(openMiniUploadBox(true));
    }
  }, [fileUploadState.openUploadModal?.isOpen]);

  useItemStartListener((item) => {
    setIsUploadingprogress(true);
  });

  useItemFinalizeListener((item) => {
    setIsUploadingprogress(false);
  });

  const addCompletedImages = (item) => {
    images.forEach((img, i) => {
      if (img?.file?.name === item?.file?.name) {
        images[i] = {
          ...images[i],
          thumbnail: item?.uploadResponse?.data?.result?.src?.thumbnail2,
        };
      }
    });
    setUploadIndex((prevState) => prevState + 1);
    setImages([...images]);
    if (
      !completedImages.some(
        (completedItem) => completedItem.file.name === item.file.name
      )
    ) {
      completedImages.push(item);
      if (mode == "album") {
        if (currentAlbum?.album?.description !== "Faces/Portraits") {
          dispatch(updateAllUploadedImages(item));
        }
        if (localStorage?.getItem("currentAlbumId") !== undefined) {
          dispatch(
            updateAlbumUploadedImages(item?.uploadResponse?.data?.result)
          );
        }
      } else {
        if (allFiles?.length > 10) {
          if (newFiles?.length < 10) {
            setNewFiles([...newFiles, item]);
          } else {
            let newFilesTmp = [...newFiles, item];
            newFilesTmp.forEach((item) => {
              dispatch(updateAllUploadedImages(item));
            });
            setNewFiles([]);
          }
        } else {
          dispatch(updateAllUploadedImages(item));
        }
      }
    }
  };
  const addDuplicate = (item) => {
    //item is file's name
    let tempArr = JSON.parse(JSON.stringify(images));
    let index = tempArr.findIndex((image) => image.file.name === item);
    tempArr.splice(index, 1);
    setImages([...tempArr]);
    setDuplicateImages((state) => [...state, { name: item, duplicate: true }]);
  };

  useBatchAddListener((batch) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      setImages((state) => [...state, ...batch?.items]);
    }
  });

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];
    tempArr.forEach((item) => {
      if (
        fileUploadState.allUploadedImages.some(
          (image) => image.name === item.name
        )
      ) {
        setDuplicateImages((state) => [
          ...state,
          { name: item?.name, duplicate: true },
        ]);
      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidImages((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);
      } else {
        setFiles((state) => [...state, item]);
        setAllFiles((state) => [...state, item]);
      }
    });
  };
  useItemStartListener((item) => {
    if (uploadValidation?.message) {
      abort(item.id);
    }
  });
  useItemFinishListener((item) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      if (fileIndex < files.length) {
        compressedUpload(fileIndex + 1);
        setFileIndex((prevState) => prevState + 1);
      }
      if (item.uploadResponse?.data?.code === 200) {
        addCompletedImages(item);
      } else if (item.uploadResponse?.data?.code === 403) {
        addDuplicate(item?.file?.name);
      } else if (item.uploadResponse?.data?.code === 402) {
        setIsLimit(true);
        setUploadValidation(item.uploadResponse?.data);
        abortAll();
      }
    }
  });

  const handleImageChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    handleFiles(e.dataTransfer.files);
  };

  const openFiles = () => {
    document.getElementById("file_upload_input").click();
  };
  const closeModal = () => {
    if (uploadIndex == images?.length) {
      closeUploadModalWhenDone();
      setAllFiles([]);
    } else {
      dispatch(openMiniUploadBox(true));
      dispatch(
        openUploadModalAction({
          data: false,
        })
      );
      setUploadIndex(0);

      setIsLimit(false);
    }
    if (uploadIndex !== images?.length) {
      dispatch(openMiniUploadBox(true));

      setMiniBoxCount(files?.length - uploadIndex);
    }
  };

  const closeUploadModalWhenDone = () => {
    if (albumId) {
      let obj = {
        id: albumId,
        page: "1",
        slug: "desc",
      };
      dispatch(getAlbumItem(obj));
    }

    setUploadIndex(0);
    setFileIndex(0);
    setImages([]);
    setFiles([]);
    setAllFiles([]);
    setDuplicateImages([]);
    setInvalidImages([]);
    setIsUploading(false);
    dispatch(openUploadModalAction({}));
    dispatch(getProfileDetails());
    setIsLimit(false);
    setUploadValidation();
  };

  /********* Compressed Upload ***********/
  const compressedUpload = async (index) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      if (files.length > 0) {
        if (isUploading === false) {
          setIsUploading(true);
        }
        if (index < files.length) {
          handleImageUpload(files[index]);
        } else {
          // setFiles([]);
          setIsUploading(false);
        }
      }
    }
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      // maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile;
    let isCompressed = false;

    // Step 1: Compress the image
    if (imageFile.size > 4199999) {
      try {
        compressedFile = await imageCompression(imageFile, {
          ...options,
          alwaysKeepResolution: true,
        });
        isCompressed = compressedFile.size >= 1100000;
      } catch (error) {
        compressedFile = imageFile;
      }
    } else {
      compressedFile = imageFile;
    }

    // Determine which file to upload
    const cmpFile =
      imageFile.size > 4199999 && isCompressed ? compressedFile : imageFile;
    // Step 2: Parse metadata and upload image
    loadImage.parseMetaData(imageFile, (data) => {
      const exifData = data?.exif ? JSON.stringify(data.exif.getAll()) : null;

      const uploadParams = {
        destination: {
          params: { album_id: albumId, isCompressed },
          filesParamName: "image",
        },
      };

      if (exifData) {
        uploadParams.destination.params.exif = exifData;
      }

      uploady.upload(cmpFile, uploadParams);
    });

    return true;
  };

  const handleClickCloseMiniModalModal = () => {
    if (uploadIndex == images?.length) {
      abortAll();

      closeUploadModalWhenDone();
      dispatch(openMiniUploadBox(false));
      dispatch(getProfileDetails());
      setIsLimit(false);
    } else {
      setUploadAlert(true);
    }
  };
  const handleQuitUploading = () => {
    abortAll();
    if (albumId) {
      let obj = {
        id: albumId,
        page: "1",
        slug: "desc",
      };
      dispatch(getAlbumItem(obj));
    }
    setUploadValidation();
    closeUploadModalWhenDone();
    dispatch(openMiniUploadBox(false));
    dispatch(getProfileDetails());
    setIsLimit(false);
    setUploadAlert(false);
  };

  return {
    handleClickCloseMiniModalModal,
    closeUploadModalWhenDone,
    handleImageChange,
    handleDragOver,
    handleDragOut,
    handleDrop,
    openFiles,
    closeModal,
    isDraggingOver,
    fileUploadState,
    imagesLength,
    images,
    duplicateImages,
    invalidImages,
    isUploading,
    uploadIndex,
    allFiles,
    newFiles,
    fileIndex,
    isUploadingProgress,
    isLimit,
    miniBoxCount,
    uploadAlert,
    setUploadAlert,
    handleQuitUploading,
    uploadValidation,
  };
};

export default useImageUpload;
