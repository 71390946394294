import React, { useState, useEffect } from "react";
import Style from "./ProfileEdit.module.scss";
import { Dropdown } from "react-bootstrap";
import CommonModal from "../Modals/commonModal";
import Icons from "../Layouts/Icons";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileDetails,
  updateProfile,
} from "../../store/slices/userprofileSlice";
import moment from "moment";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("Required")
    .matches(/^\S.*\S$/, "First name cannot start or end with a space")
    .matches(/^[a-zA-Z\s]+$/, "First name can only contain letters and spaces"),
  familyName: Yup.string().trim().nullable(),
  dob: Yup.string().nullable(),
  gender: Yup.string().notOneOf(["Select Gender"], "Required"),
  bio: Yup.string().min(4, "Bio length must be greater than three!").nullable(),
});

const ProfileEdit = ({ openEditProfile, setOpenEditProfile }) => {
  const { userdata, profileEdit } = useSelector((state) => state.userprofile);

  const [gender, setGender] = useState("Male");
  const dispatch = useDispatch();

  const getGenderbyID = (id) => {
    switch (id) {
      case 1:
        return "Male";
        break;
      case 2:
        return "Female";
        break;
      default:
        return "Other";
    }
  };

  const ConvertGenderToID = (id) => {
    switch (id) {
      case "Male":
        return 1;
        break;
      case "Female":
        return 2;
        break;
      default:
        return 3;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userdata.full_name,
      familyName: userdata.family_name,
      dob: userdata.dob ? moment(userdata?.dob).format("YYYY-MM-DD") : "",
      gender: userdata.gender_id
        ? getGenderbyID(userdata.gender_id)
        : "Select Gender",
      bio: userdata.bio,
    },
    validationSchema, // Use the Yup schema here
    onSubmit: (userdata) => {
      var userdata = Object.assign({}, userdata);
      userdata.gender = ConvertGenderToID(userdata.gender);
      dispatch(updateProfile(userdata))
        .unwrap()
        .then(function (response) {
          if (response.data.success === true) {
            setOpenEditProfile(false);
            // dispatch(getProfileDetails())
          } else {
            alert("Sorry something went wrong!");
          }
        });
    },
  });
  const handleKeyDown = (e) => {
    // if (
    //   e.key === "e" ||
    //   e.key === "E" ||
    //   e.key === "+" ||
    //   e.key === "-" ||
    //   e.key === "." ||
    //   e.key === " " ||
    //   e.key === "Enter"
    // ) {
    //   e.preventDefault();
    // }
  };
  let hasErrors = Object.keys(formik.errors).length == 0;

  return (
    <CommonModal
      showModal={openEditProfile}
      hide={() => {
        setOpenEditProfile(false);
        formik.resetForm();
      }}
      className="modal-width edit-profile-modal"
      content={
        <>
          <div className={Style.edit_profile}>
            <h2 className={Style.edit_profile_title}>My Profile</h2>
            <div className={Style.edit_profile_form}>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Full Name*
                      </label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="firstName"
                        placeholder="Full Name*"
                        name="firstName"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        onKeyDown={(e) => handleKeyDown(e)}
                      />
                      {formik.errors.firstName ? (
                        <div className={Style.error_message}>
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Family Name*
                      </label>
                      <input
                        className="edit_profile_form_input form-control"
                        id="familyName"
                        name="familyName"
                        placeholder="Family Name*"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.familyName}
                      />
                      {formik.errors.familyName && formik.touched.familyName ? (
                        <div className={Style.error_message}>
                          {formik.errors.familyName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">
                        Date of Birth*
                      </label>
                      <div className="date-input">
                        <input
                          type="date"
                          name={`dob`}
                          onBlur={formik.handleBlur}
                          max={moment().format("YYYY-MM-DD")}
                          className="edit_profile_form_input form-control"
                          value={formik.values.dob}
                          onChange={(e) => {
                            formik.setFieldValue("dob", e.target.value);
                          }}
                        />
                        <Icons icon={"icon-calender"} size={15} />
                      </div>
                      {formik.errors.dob && formik.touched.dob ? (
                        <div className={Style.error_message}>
                          {formik.errors.dob}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="edit_profile_form_label">Gender*</label>
                      <Dropdown className={Style.album_item_options}>
                        <Dropdown.Toggle
                          className={Style.album_item_options_btn}
                        >
                          {formik.values.gender}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {["Male", "Female", "Others"].map((data) => {
                            return (
                              <Dropdown.Item
                                onBlur={formik.handleBlur}
                                onClick={() => {
                                  setGender(data);
                                  formik.setFieldValue("gender", data);
                                }}
                              >
                                {data}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      {formik.errors.gender && formik.touched.gender ? (
                        <div className={Style.error_message}>
                          {formik.errors.gender}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="edit_profile_form_label">Bio*</label>
                      <textarea
                        name="bio"
                        onBlur={formik.handleBlur}
                        className="edit_profile_form_input form-control"
                        placeholder="Bio"
                        onChange={(e) => {
                          formik.setFieldValue("bio", e.target.value);
                        }}
                      >
                        {formik.values.bio}
                      </textarea>
                      {formik.errors.bio && formik.touched.bio ? (
                        <div className={Style.error_message}>
                          {formik.errors.bio}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 btn-wrap">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={!formik?.isValid || !hasErrors}
                  >
                    {profileEdit.isLoading ? (
                      <InfiniteDotLoader />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    />
  );
};

export default ProfileEdit;
