import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  mail:"",
  phone_number:"",
  country_dial_code:""
};


export const GetProfileUpdateInfo = createAsyncThunk(
  "profileupdateinfo/GetProfileUpdateInfo",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user-info`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const profileinfoUpdateSlice = createSlice({
  name: "profileupdateinfo",
  initialState,
  reducers: {
    UpdateEmail: (state,action) => {
      state.mail = action.payload;
    },
    UpdatePhone: (state,action) => {
      state.phone_number = action.payload.phone_number;
      state.country_dial_code = action.payload.country_dial_code;
    },
  },
  extraReducers: {
    [GetProfileUpdateInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [GetProfileUpdateInfo.fulfilled]: (state, action) => {
      const { mail,phone_number,country_dial_code} = action.payload.data.result;
      state.mail = mail;
      state.phone_number = phone_number;
      state.country_dial_code = country_dial_code;
      state.isLoading = false;
    },
    [GetProfileUpdateInfo.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});


export const {UpdateEmail,UpdatePhone} = profileinfoUpdateSlice.actions;

export default profileinfoUpdateSlice.reducer;
