import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAxiosInstance } from "../../api";

import {
  getAlbums,
  updateUnlock,
  rearrange,
  rearrangeAlbum,
  LoadmoreAlbums,
} from "../../store/slices/albumSlice";
import { getFavoriteImages } from "../../store/slices/favoriteSlice";
import {
  getGalleryImages,
  updateAllPageStatus,
} from "../../store/slices/GalleryImagesSlice";
import { handleLockAlbumApi } from "../../store/slices/gallerySlice";
import {
  swtichGridOption,
  endTabSession,
  disableImageSelection,
} from "../../store/slices/GalleryTabSlice";
import { getStaredList } from "../../store/slices/ProfileGallery/profileGallerySlice";

export const useAlbum = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const albums = useSelector((state) => state?.album);
  const selections = useSelector((state) => state.galleryTab);
  const [albumEditModal, setAlbumEditModal] = useState({
    popup: false,
    album: {},
  });
  const [albumDeleteModal, setAlbumDeleteModal] = useState({
    popup: false,
    album: {},
  });
  const [unlockModal, setUnlockModal] = useState(false);

  const [openShare, setOpenShare] = useState(false);
  const [seletedItemId, setSeletedItemId] = useState(null);

  const [token, setToken] = useState("");
  const [expiresAt, setExpiresAt] = useState("");

  const handleShareAlbum = (item) => {
    setSeletedItemId(item?.id);
    setOpenShare(true);
    generateToken(expiresAt);
  };

  const handleDuration = (value) => {
    setExpiresAt(value);
    generateToken(value);
  };

  // const generateToken = async (expiresAtValue) => {
  //   const api = await getAxiosInstance();
  //   const res = await api.get(`/token-generate?expired_at=${expiresAtValue}`);
  //   if (res.status == 200) {
  //     setToken(res?.data?.result)
  //   }
  // }
  const generateToken = async (expiresAtValue) => {
    const api = await getAxiosInstance();
    if (expiresAtValue) {
      const res = await api.get(
        `/token-generate?expired_at=${expiresAtValue}&album_id=${seletedItemId}`
      );
      if (res.status == 200) {
        setToken(res?.data?.result);
      }
    }
  };

  let albumView = localStorage.getItem("grid");
  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  useEffect(() => {
    let obj = {
      page: "1",
      slug: "",
    };
    getAlbumsItems(obj);
    return () => {
      dispatch(endTabSession({ tab: currentPath }));
    };
  }, []);

  const getAlbumsItems = async (obj) => {
    const resultAction = await dispatch(getAlbums(obj));
    if (getAlbums.fulfilled.match(resultAction)) {
      dispatch(updateAllPageStatus("loading"));
      loadMore(resultAction?.payload?.data?.result);
    }
  };

  const loadMore = async (Albums) => {
    if (Albums?.current_page < Albums?.last_page) {
      let obj = {
        page: Albums?.current_page + 1,
        slug: selections?.ImageAlbumTab?.sort,
      };
      const resultAction = await dispatch(LoadmoreAlbums(obj));
      if (LoadmoreAlbums.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result);
      }
    } else if (Albums?.current_page === Albums?.last_page) {
      dispatch(updateAllPageStatus("succeded"));
    }
  };

  const handleClickEdit = (data) => {
    setAlbumEditModal({ popup: true, album: data });
  };

  const handleClickDelete = (data) => {
    setAlbumDeleteModal({ popup: true, album: data });
  };

  const handleAlbumLock = async (item, index) => {
    if (item.locked != undefined) {
      const resultAction = await dispatch(
        handleLockAlbumApi({ album_id: item?.id })
      );
      if (handleLockAlbumApi.fulfilled.match(resultAction)) {
        dispatch(updateUnlock({ albumId: item?.id, action: "lock" }));
        let obj = {
          page: "1",
          slug: "",
        };
        dispatch(getGalleryImages(obj));
        dispatch(getFavoriteImages(obj));
        dispatch(disableImageSelection({ tab: "addToAlbumImage" }));
        dispatch(getStaredList());
      }
    } else {
      setUnlockModal({ popup: true, album: item });
    }
  };

  const moveAlbumItems = (dragIndex, dropIndex, data) => {
    const dragItem = data[dragIndex];
    const hoverItem = data[dropIndex];

    let changeArray = () => {
      const updatedImages = [...data];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;

      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        album_id: draggedItem?.id,
        position: changedItem?.position,
      },
      {
        album_id: changedItem?.id,
        position: draggedItem?.position,
      },
    ];
    if (changedItem?.position !== draggedItem?.position) {
      dispatch(rearrangeAlbum({ data: obj }));
    }
    dispatch(
      rearrange({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  return {
    albums,
    selections,
    albumEditModal,
    setAlbumEditModal,
    handleClickEdit,
    unlockModal,
    setUnlockModal,
    handleAlbumLock,
    albumDeleteModal,
    setAlbumDeleteModal,
    albumDeleteModal,
    setAlbumDeleteModal,
    handleClickDelete,
    moveAlbumItems,
    openShare,
    setOpenShare,
    token,
    handleShareAlbum,
    handleDuration,
    seletedItemId,
  };
};
