import React from "react";
import Style from "./SlideShowEditNew.module.scss";
import "swiper/css";

import CommonModal from "../../Modals/commonModal";
import MainSlider from "./MainSlider";
import { useSlideShowEdit1 } from "./useSlideShowEdit1";
import SlideThumbList from "./SlideThumbList";
import SlideHeader from "./SlideHeader";

export const SlideShowEditNew = () => {
  const {
    swiperRef,
    effect,
    setEffect,
    slides,
    slideData,
    slideChangeHandler,
    slideIndex,
    activeThumb,
    setActiveThumb,
    setSlideIndex,
    pausePlay,
    setPausePlay,
    time,
    setTime,
    loop,
    setLoop,
  } = useSlideShowEdit1();
  return (
    <>
      <div className={Style.slideshow_section}>
        <SlideHeader slideData={slideData} slides={slides} />
        <MainSlider
          slideData={slideData}
          slides={slides}
          effect={effect}
          setEffect={setEffect}
          swiperRef={swiperRef}
          slideChangeHandler={slideChangeHandler}
          slideIndex={slideIndex}
          activeThumb={activeThumb}
          setSlideIndex={setSlideIndex}
          setActiveThumb={setActiveThumb}
          pausePlay={pausePlay}
          setPausePlay={setPausePlay}
          time={time}
          setTime={setTime}
          loop={loop}
          setLoop={setLoop}
        />
        <SlideThumbList
          slides={slides}
          slideIndex={slideIndex}
          setActiveThumb={setActiveThumb}
          setTime={setTime}
          slideData={slideData}
        />
      </div>

      {/* <CommonModal
        showModal={slideShowAlertModal}
        hide={() => setSlideShowAlertModal(!slideShowAlertModal)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>
              {" "}
              Do You Want To Cancel These Changes?
            </h3>
            <p className={Style.delete_content}>
              {`Your Changes Won't Be Saved. Would you like to cancel ongoing Changes?`}
            </p>
            <div className={Style.delete_btn_wrap}>
              <button
                className="btn-danger"
                onClick={() => setSlideShowAlertModal(!slideShowAlertModal)}
              >
                Continue Changes
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => handleClickedBack()}
              >
                Cancel Changes
              </button>
            </div>
          </>
        }
      /> */}
    </>
  );
};
