
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import useFullscreenStatus from "../../../utils/utilityHooks/useFullScreen";


export const useFullScreen = () => {
    const fullScreenRef = useRef(null);
    let isFullscreen, setIsFullscreen;
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(fullScreenRef);
    const [fullScreenMode, setFullScreenMode] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(false);
    const [controlsHoverVisible, setControlsHoverVisible] = useState(false);


    useLayoutEffect(() => {
        openFullscreen();
    }, [fullScreenRef]);

    const openFullscreen = () => {
        let elem = fullScreenRef?.current;

        if (elem?.requestFullscreen) {
            elem?.requestFullscreen();
            setFullScreenMode(true);
        } else if (elem?.webkitRequestFullscreen) {
            /* Safari */
            elem?.webkitRequestFullscreen();
            setFullScreenMode(true);
        } else if (elem?.msRequestFullscreen) {
            /* IE11 */
            elem?.msRequestFullscreen();
            setFullScreenMode(true);
        }
    };

    const closeFullscreen = () => {
        if (document?.exitFullscreen) {
            document?.exitFullscreen();
        } else if (document?.webkitExitFullscreen) {
            /* Safari */
            document?.webkitExitFullscreen();
        } else if (document?.msExitFullscreen) {
            /* IE11 */
            document?.msExitFullscreen();
        }
    };

    // let mouseTimer = null;
    // const disableControls = () => {
    //     mouseTimer = null;
    //     setControlsVisible(false);
    // };
    // window.addEventListener("mousemove", () => {
    //     mouseTimer && window.clearTimeout(mouseTimer);
    //     !controlsVisible && setControlsVisible(true);
    //     mouseTimer =  window.setTimeout(disableControls, 3000);
    // });
    window.addEventListener("mousemove", () => {
        setControlsVisible(true);
    });
    useEffect(() => {        
        const t = setTimeout(() => {
          setControlsVisible(controlsHoverVisible)
        }, 3000);
        return () => {
          clearTimeout(t)
        }
    }, [controlsHoverVisible, controlsVisible])

    return {
        fullScreenRef,
        isFullscreen,
        setIsFullscreen,
        fullScreenMode,
        controlsVisible,
        closeFullscreen,
        setControlsHoverVisible
    }
}
