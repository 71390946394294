import { Formik } from "formik";
import { useDimensions } from "../../../Logic/Dimensions";
import Assets from "../../Layouts/Assets";
import UseFamilyDrawer from "../../../Logic/FamilyTree/useFamilyDrawer";
import Icons from "../../Layouts/Icons";
import Style from "./SideDrawer.module.scss";
import ReactDOM from "react-dom";
import BackHeader from "../../BackHeader";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useProfileUpload } from "../../../Logic/useProfilePhotoUpload";
import ProfilePictureModal from "../ProfilePictureModal/ProfilePictureModal";

const SideDrawer = (props) => {
  const { removable } = props;
  const windowDimensions = useDimensions();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const {
    handleClose,
    handleSubmit: handleFormSubmit,
    data,
    setData,
    handleValue,
    dataDrawer,
    removePerson,
    formLoading,
    firstTime,
    handleRemoveImage,
    dependencyCheck,
    showProfileUploadModal,
    setShowProfileUploadModal,
    checkDefaulImage,
  } = UseFamilyDrawer(props);

  const {
    imageSrc,
    saveImage,
    handleDragOver,
    handleDragOut,
    handleDrop: handleProfileDrop,
    responseImage,
    IsProfilePhotoUploading,
    SetImageSrc,
  } = useProfileUpload(undefined, setShowProfileModal);

  const handleDrop = (e, id) => {
    e?.preventDefault();
    e?.stopPropagation();
    setShowProfileModal(true);
    handleProfileDrop(e);
  };

  const handleFileUpload = (e) => {
    if (e?.target?.files[0]) {
      setShowProfileModal(true);
      handleProfileDrop(e);
    }
  };
  const closeProfileModal = () => {
    setShowProfileModal(false);
    SetImageSrc("");
  };

  useEffect(() => {
    if (typeof responseImage === "object") {
      if (Object.keys(responseImage).includes("img")) {
        setData((d) => {
          return { ...d, img: responseImage?.img };
        });
      }
    }
  }, [responseImage]);

  let isCustomRemovable = useMemo(() => {
    let { is_end_parent, is_end_child, is_end_partner } = dependencyCheck();
    return is_end_parent || is_end_child || (is_end_partner && removable);
  }, [data]);

  useEffect(() => {
    if (dataDrawer[0]?.name === undefined) {
      setIsEditable(true);
    }
  }, [dataDrawer[0]?.name]);

  return ReactDOM.createPortal(
    <div className={Style.root}>
      <div className={Style.sideDrawerWrapper}>
        {windowDimensions.width >= 576 ? (
          <div className={Style.closeWrapper}>
            <button onClick={handleClose}>x</button>
          </div>
        ) : (
          <BackHeader onClick={handleClose} />
        )}
        <div className={Style.drawer_display}>
          <div className={Style.drawer_display_top}>
            <Formik
              initialValues={{
                name: dataDrawer[0]?.name ? dataDrawer[0]?.name : "",
                family_name: dataDrawer[0].family_name
                  ? dataDrawer[0].family_name
                  : "",
                gender: dataDrawer[0].gender ? dataDrawer[0].gender : "",
                dob: dataDrawer[0].dob ? dataDrawer[0].dob : "",
                bio: dataDrawer[0].bio ? dataDrawer[0].bio : "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.gender) {
                  errors.gender = "Required";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit();
                setIsEditable(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  className={Style.drawerWrapper}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className={Style.drawerImage}>
                    <div
                      onDragOver={handleDragOver}
                      onDrop={(e) => {
                        isEditable && handleDrop(e, dataDrawer[0].id);
                      }}
                      className={Style.drawerImageContainer}
                    >
                      {/* "https://admin-familyphotos.webc.in/public/image/default.png1" */}
                      <img
                        onDrop={(e) => e?.preventDefault()}
                        onDragOver={handleDragOver}
                        src={
                          typeof data.img === "string"
                            ? data.img.length !== 0
                              ? data.img
                              : data.connection === "pet"
                              ? Assets.AVATAR_03
                              : data.gender == 2
                              ? Assets.AVATAR_02
                              : Assets.AVATAR
                            : `${URL.createObjectURL(data.img)}`
                        }
                        alt=""
                        style={{
                          filter: !handleDragOver ? "" : "brightness(1.2)",
                        }}
                      />
                    </div>
                    {isEditable ? (
                      <>
                        <div className={Style.drawerImageIcon}>
                          <input
                            onChange={(e) => {
                              handleFileUpload(e);
                            }}
                            id="dataDrawerProfile"
                            name="img"
                            type="file"
                          />
                          <label htmlFor="dataDrawerProfile"></label>
                        </div>
                        {data.img && !checkDefaulImage(data.img) && (
                          <button
                            className={Style.RemoveImage}
                            onClick={() => handleRemoveImage()}
                          >
                            {/* Remove */}
                            <Icons icon={"trash-fill"} size={18} />
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={Style.drawer_display_bottom}>
                    {!isEditable && dataDrawer[0].connection === "user" && (
                      <div
                        className={Style.actionButton}
                        onClick={() => setIsEditable(true)}
                      >
                        <div className={Style.deleteBtn}>
                          <button className="btn btn-grey">
                            <Icons color="#fff" icon={"edit"} size={14} />
                            <span>Edit</span>
                          </button>
                        </div>

                        {/* {firstTime && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                Add your details
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                {...triggerHandler}
                                className="info-btn d-inline-flex align-items-center"
                              >
                                <span ref={ref}></span>
                              </div>
                            )}
                          </OverlayTrigger>
                        )} */}
                      </div>
                    )}
                  </div>

                  <div className={Style.drawer_display_bottom}>
                    {isEditable && (
                      <div className={Style.actionButton}>
                        <button
                          onClick={handleSubmit}
                          className={`btn btn-primary ${
                            formLoading && "btn-loader-active"
                          }`}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {isEditable ? (
                      <div className={Style.cancel}>
                        <button
                          className="btn btn-grey"
                          onClick={() =>
                            dataDrawer[0]?.name
                              ? setIsEditable(false)
                              : handleClose()
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      dataDrawer[0].name &&
                      dataDrawer[0].connection !== "user" && (
                        <div className={Style.deleteBtnWrapper}>
                          <div className={Style.deleteBtn}>
                            <button
                              className={`btn btn-red ${
                                isCustomRemovable ? "" : Style.disabled
                              }`}
                              onClick={() => {
                                if (isCustomRemovable) {
                                  removePerson();
                                } else {
                                  toast.warning(
                                    "This user cannot be deleted. Remove the user's connections before deleting the user!",
                                    {
                                      position: "bottom-right",
                                      autoClose: 4000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                }
                              }}
                            >
                              {/* <Icons
                                color="#fff"
                                icon={"icon-trash"}
                                size={14}
                              /> */}
                              <Icons icon={"icon-trash-bin"} size={14} />
                              <span>Remove</span>
                            </button>
                          </div>
                          <div className={Style.cancel}>
                            <button
                              className="btn btn-grey"
                              onClick={() => setIsEditable(true)}
                            >
                              <Icons color="#fff" icon={"edit"} size={14} />
                              <span>Edit</span>
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className={Style.fieldWrapper}>
                    <div className={Style.fieldGroup}>
                      <label>Full Name</label>
                      <input
                        type="text"
                        value={data.name}
                        name={`name`}
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter full name"
                        autoFocus={true}
                        spellCheck={false}
                        disabled={!isEditable}
                        className={`${!isEditable ? Style.state_disabled : ""}`}
                      />
                      {errors.name && touched.name && (
                        <span className={Style.error}>{errors.name}</span>
                      )}
                    </div>

                    <div className={Style.fieldGroup}>
                      <label>Family Name</label>
                      <input
                        type="text"
                        value={data.family_name}
                        name={`family_name`}
                        onChange={(e) => {
                          handleChange(e);
                          handleValue(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter family name"
                        spellCheck={false}
                        disabled={!isEditable}
                        className={`${!isEditable ? Style.state_disabled : ""}`}
                      />
                      {errors.family_name && touched.family_name && (
                        <span className={Style.error}>
                          {errors.family_name}
                        </span>
                      )}
                    </div>

                    <div className={Style.checkBoxWrapper}>
                      <h3 className={Style.title}>Gender</h3>
                      <div className={Style.checkBox_buttons}>
                        {isEditable ? (
                          <>
                            <div
                              className={`${Style.checkBoxItem} ${
                                data.gender == 1 ? Style.checked : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name={`gender`}
                                value={1}
                                id="male"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleValue(e);
                                }}
                                onBlur={handleBlur}
                                className={`${
                                  !isEditable ? Style.state_disabled : ""
                                }`}
                              />
                              <label htmlFor="male">Male</label>
                            </div>

                            <div
                              className={`${Style.checkBoxItem} ${
                                data.gender == 2 ? Style.checked : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name={`gender`}
                                value={2}
                                id="Female"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleValue(e);
                                }}
                                onBlur={handleBlur}
                                className={`${
                                  !isEditable ? Style.state_disabled : ""
                                }`}
                              />
                              <label htmlFor="Female">Female</label>
                            </div>

                            <div
                              className={`${Style.checkBoxItem} ${
                                data.gender == 3 ? Style.checked : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name={`gender`}
                                value={3}
                                id="other"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleValue(e);
                                }}
                                onBlur={handleBlur}
                                className={`${
                                  !isEditable ? Style.state_disabled : ""
                                }`}
                              />
                              <label htmlFor="other">Others</label>
                            </div>
                          </>
                        ) : (
                          <>
                            {data.gender == 1 ? (
                              <div
                                className={`${Style.checkBoxItem} ${
                                  Style.checked
                                } ${!isEditable ? Style.state_disabled : ""}`}
                              >
                                <input
                                  type="radio"
                                  name={`gender`}
                                  value={1}
                                  id="male"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleValue(e);
                                  }}
                                  onBlur={handleBlur}
                                  className={`${
                                    !isEditable ? Style.state_disabled : ""
                                  }`}
                                />
                                <label htmlFor="male">Male</label>
                              </div>
                            ) : data.gender == 2 ? (
                              <>
                                <div
                                  className={`${Style.checkBoxItem} ${
                                    Style.checked
                                  } ${!isEditable ? Style.state_disabled : ""}`}
                                >
                                  <input
                                    type="radio"
                                    name={`gender`}
                                    value={2}
                                    id="Female"
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleValue(e);
                                    }}
                                    onBlur={handleBlur}
                                    className={`${
                                      !isEditable ? Style.state_disabled : ""
                                    }`}
                                  />
                                  <label htmlFor="Female">Female</label>
                                </div>
                              </>
                            ) : data.gender == 3 ? (
                              <>
                                <div
                                  className={`${Style.checkBoxItem} ${
                                    Style.checked
                                  } ${!isEditable ? Style.state_disabled : ""}`}
                                >
                                  <input
                                    type="radio"
                                    name={`gender`}
                                    value={3}
                                    id="other"
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleValue(e);
                                    }}
                                    onBlur={handleBlur}
                                    className={`${
                                      !isEditable ? Style.state_disabled : ""
                                    }`}
                                  />
                                  <label htmlFor="other">Others</label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>

                      {errors.gender && touched.gender && (
                        <span className={Style.error}>{errors.gender}</span>
                      )}
                      {firstTime && (
                        <span className={Style.error}>
                          Select gender to add family members.
                        </span>
                      )}
                    </div>

                    <div className={Style.fieldGroup}>
                      <label>Date of Birth</label>
                      <div className={Style.date_input}>
                        <input
                          type="date"
                          name={`dob`}
                          value={data.dob.split(" ")[0]}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => {
                            handleChange(e);
                            handleValue(e);
                          }}
                          onBlur={handleBlur}
                          disabled={!isEditable}
                          className={`${
                            !isEditable ? Style.state_disabled : ""
                          }`}
                        />
                        {isEditable ? (
                          <Icons icon={"icon-calender"} size={15} />
                        ) : (
                          ""
                        )}
                      </div>
                      {errors.dob && touched.dob && (
                        <span className={Style.error}>{errors.dob}</span>
                      )}
                    </div>

                    <div className={Style.fieldGroup}>
                      <label>Bio</label>
                      <div
                        className={`${Style.textarea} ${
                          !isEditable ? Style.state_disabled : ""
                        }`}
                      >
                        <textarea
                          type="text"
                          name={`bio`}
                          value={data.bio}
                          onChange={(e) => {
                            handleChange(e);
                            handleValue(e);
                          }}
                          onBlur={handleBlur}
                          placeholder="Add text..."
                          spellCheck={false}
                          disabled={!isEditable}
                        />
                      </div>
                      {errors.bio && touched.bio && (
                        <span className={Style.error}>{errors.bio}</span>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ProfilePictureModal
        open={showProfileModal}
        close={() => closeProfileModal(false)}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        handleDragOut={handleDragOut}
        saveImage={saveImage}
        loading={IsProfilePhotoUploading}
        imageSrc={imageSrc}
      />
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default SideDrawer;
