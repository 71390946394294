import React from "react";
import { useSelector } from "react-redux";
import Style from "./CheckoutPlanDetails.module.scss";
import parse from "html-react-parser";


const CheckoutPlanDetails = ({ className }) => {
  const { plansData } =
    useSelector((state) => state.home);

  return (
    <div className={`${Style.CheckoutPlanDetails} ${className}`}>
      <h3 className={Style.CheckoutPlanDetails__plan} >
        {plansData?.[0]?.name}</h3>
      <div className={Style.CheckoutPlanDetails__price}>
        <span>{plansData?.[0]?.plans_prices?.[0]?.symbol}</span>
        <span className={Style.price}>{plansData?.[0]?.price}</span>/ {plansData?.[0]?.plan_interval}
      </div>
      <p className={Style.CheckoutPlanDetails__description}>
        {plansData?.[0]?.description}
      </p>
      <ul className={Style.CheckoutPlanDetails__planFeatures}>
        {parse(plansData?.[0]?.features)}

      </ul>
    </div>
  );
};

export default CheckoutPlanDetails;
