import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Icons from "../../../../components/Layouts/Icons";
import Style from "./SlideThumbList.module.scss";
import { useSlideThumbList } from "./useSlideThumbList";

const SlideThumbList = ({
  slides,
  slideIndex,
  setActiveThumb,
  setTime,
  slideData,
}) => {
  const { handleDeleteSlide, handleAddImage, swiperThumbRef } =
    useSlideThumbList();

  return (
    <nav className={Style.slideshow_slides_nav}>
      <div className={Style.slideshow_slides_nav_wrap}>
        <Swiper
          loop={false}
          ref={swiperThumbRef}
          onSwiper={setActiveThumb}
          spaceBetween={23}
          slidesPerView="auto"
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={`${Style.slideshow_nav_swiper} mySwiper`}
        >
          {slides?.map?.((imgItem, index) => {
            return (
              <SwiperSlide
                key={index}
                onClick={() => setTime(slideData.duration * index)}
              >
                <figure className={`${Style.slides_nav_image}`}>
                  <img
                    // onClick={playSlideShow}
                    src={imgItem?.src?.thumbnail2}
                    className={`${index === slideIndex && Style.slider_active}`}
                    alt=""
                  />
                </figure>
                <button
                  className={Style.delete_img}
                  onClick={() => handleDeleteSlide(imgItem)}
                >
                  <Icons icon={"icon-trash-bin"} size={14} />
                </button>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <span
          className={Style.slideshow_add_file}
          onClick={() => handleAddImage(slides)}
          // onClick={() => handleClickAddImages(slides, id)}
        >
          <label>
            <Icons icon={"icon-close"} size={14} />
          </label>
        </span>
      </div>
    </nav>
  );
};

export default SlideThumbList;
