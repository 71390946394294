import React, { useState, useEffect } from "react";
import Style from "./HomeMemories.module.scss";
import { Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import HomeMemoriesSlide from "./HomeMemoriesSlide";
import { useTranslation } from "react-i18next";

const HomeMemories = () => {
  const { t } = useTranslation();
  const [sliderWidth, setSliderWidth] = useState("");

  useEffect(() => {
    var sliderColumns = document.querySelectorAll(".slider-column");
    let totalWidth = 0;
    sliderColumns.forEach((column) => {
      var style = column.currentStyle || window.getComputedStyle(column),
        width = column.offsetWidth,
        margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
        padding =
          parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
        border =
          parseFloat(style.borderLeftWidth) +
          parseFloat(style.borderRightWidth);
      let columnWidth = width + margin - padding + border;
      totalWidth += columnWidth;
    });
    setSliderWidth(totalWidth);
  }, []);

  return (
    <div className={`${Style.home_memories} anim`}>
      <div className={Style.anim_elements}>
        <figure
          className={`${Style.anim_elem} ${Style.circle_blue} anim_element anim`}
        >
          <img src={Assets.memories_circle_blue} alt="circle_blue" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}
        >
          <img src={Assets.memories_circle_green} alt="circle_green" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
        >
          <img src={Assets.memories_donut_yellow} alt="donut_yellow" />
        </figure>
      </div>
      <Container>
        <div
          className={`${
            Style.home_memories_title
          } ${"section_title_block center"}`}
        >
          <h2 className="anim load-anim">{t("mem_title")}</h2>
          <p className="anim load-anim">{t("mem_desc")}</p>
        </div>
      </Container>

      <div className={`${Style.home_memories_gallery_wrap} anim load-anim`}>
        {/* <div className={`${Style.home_memories_gallery} slider-gallery`} style={{width: sliderWidth + 'px' }}> */}
        <div
          className={`${Style.home_memories_gallery} slider-gallery`}
          style={{ "--sliderWidth": sliderWidth + "px" }}
        >
          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
          <HomeMemoriesSlide />
        </div>
      </div>
    </div>
  );
};

export default HomeMemories;
