import Style from "./Archive.module.scss";
import ImageListMasonry from "../ImageListMasonry";
import { useArchive } from "./useArchive";

const Archive = () => {
  const { Archive, loadMore, NodataContent, status } = useArchive();
  return (
    <ImageListMasonry
      Images={Archive.resultData.data}
      loadMore={loadMore}
      NodataContent={NodataContent}
      status={status}
      total={Archive.resultData.data?.length}
    />
  );
};
export default Archive;
