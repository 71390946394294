import React from "react";
import Style from "./LanguageMenu.module.scss";
// import Languages from "../../../constants/languages";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const LanguageMenu = ({
  showLang,
  setShowLang,
  languageSwitch,
  currentLanguage,
  setLanguage,
}) => {
  const { i18n, t } = useTranslation(["common"]);
  const { availableLanguage } = useSelector((state) => state.home);

  return (
    <>
      <div className={Style.language_menu}>
        <div className="container">
          <h2 className={Style.language_menu_title}>
            <button
              onClick={() => {
                setShowLang(false);
              }}
            >
              {t("language")}
            </button>
          </h2>
          <ul className={Style.language_menu_list}>
            {availableLanguage.map((lang, i) => {
              return (
                <li
                  key={lang.id}
                  onClick={() => {
                    setLanguage(lang.language);
                    languageSwitch(lang.name, lang.flag, lang.locale, lang);
                  }}
                >
                  <input
                    checked={
                      lang.name == localStorage.getItem("currentLanguage")
                    }
                    className={Style.language_radio_input}
                    type="radio"
                    id={lang.language}
                  />
                  <label className={Style.language} htmlFor={lang.language}>
                    {lang.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LanguageMenu;
