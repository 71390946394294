import React from 'react';
import Icons from '../Layouts/Icons';
import Style from './BackHeader.module.scss';

const BackHeader = ({onClick}) => {
  return (
    <div className={Style.BackHeader}>
        <button className={Style.BackHeader_btn} onClick={onClick}>
            <Icons icon={"back-arrow"} size={14} />
            <span>Back</span>
        </button>
    </div>
  )
}

export default BackHeader;