import React from "react";
import { useSelector } from "react-redux";

const useAuthChecker = () => {
  const { registerData } = useSelector((state) => state.auth);

  let isAuthCompleted = registerData?.token ? true : false;

  return { isAuthCompleted };
};

export default useAuthChecker;
