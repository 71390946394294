import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocial, getSharedData } from "../store/slices/socialSlice";
export const useSocial = () => {
  const dispatch = useDispatch();
  const [openShare, setOpenShare] = useState(false);
  const [secretToken, setSecretToken] = useState("");
  const [shareData, setShareData] = useState({});

  const fetchSocialDetails = (id) => {
    dispatch(fetchSocial(id))
      .unwrap()
      .then((res) => {
        setSecretToken(res.data.result);
      });
  };

  const sharedData = (key, type) => {
    dispatch(getSharedData({ key, type }))
      .unwrap()
      .then((res) => {
        setShareData(res.data.result);
      });
  };

  const handleShareItemOnCustomRightClick = (id) => {
    setOpenShare(!openShare);
    fetchSocialDetails(id);
  };

  return {
    fetchSocialDetails,
    secretToken,
    sharedData,
    shareData,
    openShare,
    setOpenShare,
    handleShareItemOnCustomRightClick
  };
};
