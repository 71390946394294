import { useState } from "react";
import useDebounce from "../../../utils/utilityHooks/useDebounce";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVersionTitle } from "../../../store/slices/familyTreeSlice";


export const useHistoryItem = (data) => {
    const dispatch = useDispatch()
    const { isFetchingVersionTitle } = useSelector((state) => state?.familyTree);
    const [editData, setEditData] = useState({
        showEdit: false,
        versionId: null
    });

    const [title, setTitle] = useState(data?.title ? data?.title : data?.date);
    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleDoubleClick = (id) => {
        setEditData({
            showEdit: true,
            versionId: id
        })
    }


    const debouncedSearchTerm = useDebounce(title, 700);


    useEffect(() => {
        if (debouncedSearchTerm !== "" && editData?.showEdit) {
            updateTitle(debouncedSearchTerm)
        }
    }, [debouncedSearchTerm])

    const updateTitle = async () => {
        const resultAction = await dispatch(addVersionTitle({
            title: debouncedSearchTerm,
            id: editData?.versionId
        }));
        if (addVersionTitle.fulfilled.match(resultAction)) {
            setEditData({
                showEdit: false,
                versionId: null
            })
        }
    }


    return {
        title,
        editData,
        isFetchingVersionTitle,
        setTitle,
        setEditData,
        handleChangeTitle,
        handleDoubleClick
    }
}