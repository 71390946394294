import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import ComponentEmailUpdateForm from "../../components/EditProfilePage/UpdateEmail";
import UpdateEmailOTP from "../../components/EditProfilePage/UpdateEmailOTP";
import ComponentPhoneUpdateForm from "../../components/EditProfilePage/UpdatePhoneNumber";
import UpdatePhoneNumberOTP from "../../components/EditProfilePage/UpdatePhoneNumberOTP";
import { useEmailUpdate } from "../../Logic/useEmailUpdate";
import { usePhoneUpdate } from "../../Logic/usePhoneUpdate";
import { useProfileUpload } from "../../Logic/useProfilePhotoUpload";
import { ProfileUpdateInfo } from "../../Logic/useProfileUpdateInfo";
import getCroppedImg from "../../utils/Crop/imageCrop";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import Icons from "../Layouts/Icons";
import CommonModal from "../Modals/commonModal";
import PreferenceInnerHeader from "../PreferenceInnerHeader";
import Style from "./EditProfilePage.module.scss";

const EditProfilePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [profileCrop, setProfileCrop] = useState({ x: 0, y: 0 });
  const [profileZoom, setProfileZoom] = useState(1);

  const [blob, setBlob] = useState(null);

  const {
    UploadInputElement,
    UploadInputElementForm,
    onButtonClickTriggerFileSelect,
    UploadProfile,
    IsProfilePhotoUploading,
    setIsProfilePhotoUploading,
    imgRef,
    aspect,
    cropselection,
    SetcropSelection,
    crop_apply_btn,
    SetCrop_apply_btn,
    crop_window,
    crop,
    setCrop,
    imageObj,
    imageSrc,
    openEditProfileImage,
    setOpenEditProfileImage,
    CancelButton,
    saveImage,
  } = useProfileUpload();

  useEffect(() => {
    if (!openEditProfileImage) {
      setProfileZoom(1);
    }
  }, [openEditProfileImage]);

  const { formSubmittedDetails } = useSelector(
    (state) => state.userEmailUpdate
  );

  const { phoneUpdateformSubmittedDetails } = useSelector(
    (state) => state.phoneUpdateSlice
  );

  const { profile_image } = useSelector((state) => state.userprofile.userdata);

  useEffect(() => {}, [profile_image]);

  const handleVisibility = (e) => {
    e.preventDefault();
    isVisible ? setIsVisible(false) : setIsVisible(true);
  };

  /****
   * Clearing Redux Data
   * Dashboard > Preference > Update-info email update form
   ****/

  const { clearFormResponceDataFromRedux, openEditEmail, setOpenEditEmail } =
    useEmailUpdate();
  const {
    clearFormResponceDataPhoneOTPFromRedux,
    openEditMobile,
    setOpenEditMobile,
  } = usePhoneUpdate();

  /****
   * Fetching Data from updateProfileInfo API
   ****/

  const { updateProfileInfo, isProfileInfoLoading, profileUpdateInfo } =
    ProfileUpdateInfo();

  useEffect(() => {
    if (openEditEmail === false && formSubmittedDetails?.token !== "") {
      clearFormResponceDataFromRedux();
    }
  }, [openEditEmail]);

  useEffect(() => {
    if (
      openEditMobile === false &&
      phoneUpdateformSubmittedDetails?.token !== ""
    ) {
      clearFormResponceDataPhoneOTPFromRedux();
    }
  }, [openEditMobile]);

  const ClosePopup = () => {
    setOpenEditEmail(false);
    setOpenEditMobile(false);
  };

  let [cordX, cordY] = [50, 50];

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    setBlob(croppedImage);
  };

  return (
    <>
      <div className={Style.edit_profile_page}>
        <PreferenceInnerHeader
          title={"Update Info"}
          navigatePath={"/dashboard/preferences"}
        />
        {!isProfileInfoLoading ? (
          <div className={Style.edit_profile_page_contents}>
            <div className={Style.edit_profile_page_setting}>
              <div className={Style.edit_profile_setting_left}>
                <h4 className={Style.setting_name}>Email</h4>
                <p className={Style.setting_value}>{profileUpdateInfo?.mail}</p>
              </div>
              <div className={Style.edit_profile_setting_right}>
                <button
                  className={Style.setting_action}
                  onClick={() => setOpenEditEmail(true)}
                >
                  <Icons icon={"edit"} size={16} />
                  Edit
                </button>
              </div>
            </div>

            <div className={Style.edit_profile_page_setting}>
              <div className={Style.edit_profile_setting_left}>
                <h4 className={Style.setting_name}>Mobile number</h4>
                <p className={Style.setting_value}>
                  +{profileUpdateInfo?.country_dial_code}{" "}
                  {profileUpdateInfo?.phone_number}
                </p>
              </div>
              <div className={Style.edit_profile_setting_right}>
                <button
                  className={Style.setting_action}
                  onClick={() => setOpenEditMobile(true)}
                >
                  <Icons icon={"edit"} size={16} />
                  Edit
                </button>
              </div>
            </div>

            <div className={Style.edit_profile_page_setting}>
              <div className={Style.edit_profile_setting_left}>
                <h4 className={Style.setting_name}>Update profile picture</h4>
              </div>
              <div className={Style.edit_profile_setting_right}>
                <button
                  className={Style.setting_action}
                  // onClick={() => onButtonClickTriggerFileSelect()}
                >
                  <form ref={UploadInputElementForm}>
                    <input
                      type="file"
                      id="file"
                      onChange={UploadProfile}
                      ref={UploadInputElement}
                    />
                  </form>
                  <Icons icon={"edit"} size={16} />
                  {profile_image !== undefined &&
                  profile_image?.thumbnail?.includes(".jpeg")
                    ? "Update"
                    : "Upload"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <CommonModal
        showModal={openEditEmail}
        hide={() => setOpenEditEmail(!openEditEmail)}
        className={Style.edit_profile_info_modal}
        title={""}
        content={
          formSubmittedDetails.length === 0 ? (
            <ComponentEmailUpdateForm ClosePopup={ClosePopup} />
          ) : (
            <UpdateEmailOTP
              ClosePopup={ClosePopup}
              data={formSubmittedDetails}
            />
          )
        }
      />

      <CommonModal
        showModal={openEditMobile}
        hide={() => setOpenEditMobile(!setOpenEditMobile)}
        className={Style.edit_profile_info_modal}
        title={""}
        content={
          phoneUpdateformSubmittedDetails.length === 0 ? (
            <ComponentPhoneUpdateForm
              handleVisibility={handleVisibility}
              isVisible={isVisible}
              OtpInput={OtpInput}
              ClosePopup={ClosePopup}
            />
          ) : (
            <UpdatePhoneNumberOTP ClosePopup={ClosePopup} />
          )
        }
      />

      <CommonModal
        showModal={openEditProfileImage}
        hide={() => CancelButton()}
        className={`${Style.edit_profile_info_modal} ${Style.profile_image_upload}`}
        title={""}
        content={
          <>
            <div className={Style.file_upload_modal_form}>
              <h3 className={Style.edit_profile_info_title}>
                {profile_image !== undefined && profile_image.length > 0
                  ? "Update profile picture"
                  : "Upload profile picture"}
              </h3>

              <figure className={Style.ProfileImageCrop__previewImage}>
                <Cropper
                  image={imageSrc}
                  crop={profileCrop}
                  zoom={profileZoom}
                  aspect={1 / 1}
                  cropShape="round"
                  onCropChange={setProfileCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setProfileZoom}
                />
              </figure>

              <div className={Style.ProfileImageCrop__controller}>
                <Slider
                  defaultValue={profileZoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e) => {
                    setProfileZoom(e);
                  }}
                  className={Style.zoom_slider}
                />
              </div>
              {imageSrc ? (
                <div className={Style.ProfileImageCrop__btnWrap}>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      CancelButton(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      saveImage("profile", blob);
                    }}
                    className="btn btn-tertiary"
                  >
                    {!IsProfilePhotoUploading ? (
                      "Upload"
                    ) : (
                      <InfiniteDotLoader />
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default EditProfilePage;

{
  /* <div className={Style.file_upload_modal_form}>
  <h3 className={Style.edit_profile_info_title}>
    {userdata.profile_image !== undefined &&
      userdata.profile_image.length > 0
      ? "Update profile picture"
      : "Upload profile picture"}
  </h3>

  <div className={Style.ProfileImageCrop__contents}>
    {imageSrc ? (
      <>
        <figure className={Style.ProfileImageCrop__previewImage}>
          <Cropper
            image={imageSrc}
            crop={profileCrop}
            zoom={profileZoom}
            aspect={1 / 1}
            cropShape="round"
            onCropChange={setProfileCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setProfileZoom}
          />
        </figure>

        <div className={Style.ProfileImageCrop__controller}>
          <Slider
            defaultValue={profileZoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e) => {
              setProfileZoom(e);
            }}
            className={Style.zoom_slider}
          />
        </div>
        <p className={Style.ProfileImageCrop__uploadFileLimit}>
        </p>
      </>
    ) : (
      <div
        className={Style.ProfileImageCrop__uploadBox}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragOut}
      >
        <label htmlFor="uploadImg">
          <figure>
            <img src={Assets.file_upload_image} alt="" />
          </figure>
          <p>
            Drag and drop <br /> an image, <span>or Browse</span>
          </p>
          <form ref={inputFileForm}>
            <input
              type="file"
              className="d-none"
              name="file"
              id="uploadImg"
              onChange={handleImageChange}
            />
          </form>
        </label>
      </div>
    )}
    <p className={Style.ProfileImageCrop__uploadFileLimit}>
    </p>
  </div>

  {imageSrc ? (
    <div className={Style.ProfileImageCrop__btnWrap}>
      <button
        className="btn btn-border-grey"
        onClick={() => {
          setOpenEditProfileImage(false);
        }}
      >
        Cancel
      </button>
      <button
        onClick={() => {
          saveImage("profile", blob);
        }}
        className="btn btn-tertiary"
      >
        {!IsProfilePhotoUploading ? (
          "Upload"
        ) : (
          <InfiniteDotLoader />
        )}
      </button>
    </div>
  ) : (
    ""
  )}
</div> */
}
