import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
};

export const fetchSocial = createAsyncThunk(
  "auth/fetchSocial",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`share?image_id=${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSharedData = createAsyncThunk(
  "auth/getSharedData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `share-post?uid=${params.key}&type=${params.type}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
