import { useState } from "react";
import { useDispatch, useSelector, } from "react-redux";


import { removeAlbum, updateMultipleAlbumList } from "../../../../../store/slices/albumSlice";
import { toast } from "react-toastify";


export const useDeleteAlbumModal = ({ setDeleteModal }) => {
    const dispatch = useDispatch();
    const selections = useSelector((state) => state.galleryTab.ImageAlbumTab)

    const handleDelete = async () => {
        let album_id = [...selections.selectedImage];
        const resultAction = await dispatch(removeAlbum({ album_id }));
        if (removeAlbum.fulfilled.match(resultAction)) {
            toast.success("Album deleted !", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setDeleteModal({ popup: false })
            dispatch(updateMultipleAlbumList(selections.selectedImage))
        }
    }


    return {
        selections,
        handleDelete
    };

}