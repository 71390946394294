import Style from "../ImageListMasonry.module.scss";
import HeartIcon from "../../../utils/SvgIcons/heartIcon";
import Icons from "../../Layouts/Icons";
import { useRightClickModal } from "./useRightClickModal";


const RightClickModal = ({ isMobileView, points, selectedItem, setAddToAlbum, DeleteModal,
    setDeleteModal, handleOpenShare }) => {
    const { handleAddToAlbum, handleAddToFavorite, handleDelete } = useRightClickModal({ setAddToAlbum, setDeleteModal })
    return (
        (
            <>
                <div
                    className={"customRightClick"}
                    style={{
                        top: points.y,
                        left: points.x,
                    }}
                >
                    <ul>
                        <li
                            onClick={() => handleOpenShare(selectedItem)}
                        >
                            <Icons icon={"share2"} size={14} />
                            <p>Share</p>
                        </li>
                        {isMobileView ? (
                            <li

                                className={Style.longPressFavIcon}
                            >
                                {HeartIcon}
                                <p>Add to Fav</p>
                            </li>
                        ) : (
                            ""
                        )}
                        <li
                            onClick={() => handleAddToAlbum(selectedItem)}
                        >
                            <Icons icon={"add-to-album"} size={14} />
                            <p>Add to album</p>
                        </li>
                        <li
                            onClick={() => handleDelete(selectedItem)}
                        >
                            <Icons icon={"icon-trash"} size={14} />
                            <p>Delete</p>
                        </li>
                    </ul>
                </div>
            </>
        )
    )
}
export default RightClickModal