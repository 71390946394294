import React, { useEffect, useState } from "react";
import { hdfcPayment } from "../../store/slices/checkoutSlice";
import { useDispatch, useSelector } from "react-redux";
import HdfcRedirect from "../HdfcRedirect";
import { useParams } from "react-router-dom";
import { DashboardLoader } from "../DashboardLoader";

const TestPayment = () => {
  const dispatch = useDispatch();
  const { payment_id,country_id,price } = useParams();

  const { hdfc_status, hdfc_payment } = useSelector((state) => state.payment);
  const { REACT_APP_APIURL, REACT_APP_APIURL_LIVE } = process.env;

  useEffect(() => {
          dispatch(
            hdfcPayment({
              redirect_url: `${REACT_APP_APIURL_LIVE}ccavenue/response-handler`,
              cancel_url: `${REACT_APP_APIURL_LIVE}ccavenue/response-handler`,
              plan_id: payment_id,
              country_dial_code:
                country_id,
            })
          ).unwrap()
            .then((res) => {
              let data = {
                amount: price,
                order_id: res.data.result.order_id,
              };
              localStorage.setItem("orderDetails", JSON.stringify(data));
            });
  }, []);

  if (hdfc_status == "success" && hdfc_payment?.order_id) {
    return <HdfcRedirect />;
  }

  return <DashboardLoader />;
};

export default TestPayment;
