import React, { useEffect, useRef } from "react";
import Style from "./ForgotPasswordModal.module.scss";
import { useState } from "react";
import Assets from "../../Layouts/Assets";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import OtpInput from "react-otp-input";
import { Formik, useFormik } from "formik";
import { useRegister } from "../../../Logic/useRegister";
import { useOtp } from "../../../Logic/useOtp";
import { useTranslation } from "react-i18next";
import { useResendOtp } from "../../../Logic/Auth/useResendOtp";

export default function ResetPassword({
  setShowForgotPassword,
  forgotPasswordHandler,
}) {
  const { currentAuthData } = useSelector((state) => state.auth);
  const { i18n, t } = useTranslation(["authentication"]);

  const handleChangeEmail = (e) => {
    e.preventDefault();
    setShowForgotPassword(false);
  };
  const dispatch = useDispatch();
  const {
    verifyForgotOtp,
    code,
    showForgotOtpResponse,
    otpCounter,
    setOtpCounter,
    handleResetForgotOtp,
  } = useOtp();
  
  useEffect(() => {
    const countdown = setInterval(() => {
      if (otpCounter > 0) {
        setOtpCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [otpCounter]);
  const minutes = Math.floor(otpCounter / 60);
  const seconds = otpCounter % 60;
  return (
    <div>
      <div className={`${Style.email_with_change} ${"form-group"}`}>
        <label htmlFor="fp_input_email">{`${t("register_email")} *`}</label>
        <button
          className={Style.change_email}
          onClick={(e) => handleChangeEmail(e)}
        >
          {t("reset_password_change_btn")}?
        </button>
        <div className="input-holder">
          <input
            type="email"
            name="fp_input_email"
            id="fp_input_email"
            className="form-control"
            placeholder="Enter your mail address"
            value={currentAuthData?.mail}
          />
        </div>
        <span className="field_error_message">Please enter valid Email ID</span>
      </div>
      <Formik
        initialValues={{ otp: "" }}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          if (!values.otp || values?.otp?.length < 4) {
            // setshowOtpErrors("Otp can't be empty");
          }
        }}
      >
        {({
          handleChange,
          values,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <div className="form-group">
              <label htmlFor="input_test">{`${t(
                "reset_password_title1"
              )} *`}</label>
              <OtpInput
                value={code}
                isInputNum={true}
                shouldAutoFocus={true}
                name="otp"
                onChange={(e) => {
                  verifyForgotOtp(e, "forgot_password");
                  setFieldValue("otp", e);
                }}
                numInputs={4}
                onBlur={handleBlur}
                inputStyle={`${
                  showForgotOtpResponse?.status == false
                    ? Style.input_Style_error
                    : ""
                }`}
                className="otp-field"
              />
              <div className={Style.forgot_password_resend_otp}>
                <span>{t("otp_fetch")}</span>
                {otpCounter > 0 ? (
                  <span className={Style.forgot_password_resend_otp_timer}>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                ) : (
                  ""
                )}

                {otpCounter == 0 ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleResetForgotOtp(currentAuthData?.mail);
                    }}
                  >
                    {t("otp_resend_btn")}
                  </button>
                ) : (
                  ""
                )}
              </div>
              {showForgotOtpResponse?.status ? (
                <>
                  <span className={Style.otpMessage}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"
                        fill="#23CC2B"
                      />
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <span className={Style.otpErrorMessage}>
                    {showForgotOtpResponse?.message}
                  </span>
                </>
              )}
            </div>
          );
        }}
      </Formik>
      <ChangePassword
        showForgotOtpResponse={showForgotOtpResponse}
        setShowForgotPassword={setShowForgotPassword}
      />
    </div>
  );
}
