import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLockAlbumApi,
  updateAlbumLockStatus,
} from "../../store/slices/gallerySlice";

export const useAlbumLock = () => {
  const ele_password = useRef();
  const dispatch = useDispatch();
  const [openShowAlbum, setOpenShowAlbum] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [selectedAlbumId, setSelectedAlbumID] = useState("");
  const [message, setMessage] = useState("");

  const { isLocking } = useSelector((state) => state.album);

  useEffect(() => {
    return () => {
      setIsValid(false);
      setIsBlur(false);
      setMessage("");
    };
  }, [openShowAlbum]);

  const handleUnLockAlbum = (id) => {
    setOpenShowAlbum(!openShowAlbum);
    setSelectedAlbumID(id);
  };

  const ShowPassword = function (seconds, ele) {
    if (ele.current.type === "password") {
      ele.current.type = "text";
    } else {
      ele.current.type = "password";
    }
  };

  const handlePasswordChange = (e) => {
    setMessage("");
    setPassword(e.target.value);
    var pattern = /^.*(?=.{8,20}).*$/;
    setIsValid(pattern.test(e.target.value));
  };

  const handleComparePassword = async () => {
    const resultAction = await dispatch(
      handleLockAlbumApi({
        album_id: selectedAlbumId,
        password: password,
      })
    );
    if (handleLockAlbumApi.fulfilled.match(resultAction)) {
      if (resultAction?.payload?.data?.code !== 422) {
        dispatch(updateAlbumLockStatus({ id: selectedAlbumId }));
        setOpenShowAlbum(!openShowAlbum);
      } else if (resultAction?.payload?.data?.code == 422) {
        setMessage(resultAction?.payload?.data?.message);
      }
    }
  };

  const handleLock = async (id) => {
    setSelectedAlbumID(id);
    const resultAction = await dispatch(handleLockAlbumApi({ album_id: id }));
    if (handleLockAlbumApi.fulfilled.match(resultAction)) {
      dispatch(updateAlbumLockStatus({ id: id }));
    }
  };

  const handleBlur = (e) => {
    setIsBlur(true);
  };

  return {
    openShowAlbum,
    isPassVisible,
    ele_password,
    handlePasswordChange,
    setOpenShowAlbum,
    setIsPassVisible,
    handleUnLockAlbum,
    ShowPassword,
    handleComparePassword,
    isValid,
    password,
    handleBlur,
    isBlur,
    isLocking,
    handleLock,
    selectedAlbumId,
    message,
  };
};
