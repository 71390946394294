import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addVideoLink,
  getVideoLinks,
  updateVideoLink,
} from "../../../store/slices/Videos/VideoLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHeaderFilter } from "../HeaderFilters/useHeaderFilter";
import Assets from "../../Layouts/Assets";
import { toast } from "react-toastify";

export const useVideoModal = (close) => {
  const dispatch = useDispatch();

  const { isCreatingLink, videoActions, resultData, videoCurrentSort } =
    useSelector((state) => state?.videos);
  const [thumbUrl, setThumbUrl] = useState("");
  const [changedThumb, setChangedThumb] = useState(false);
  const [formError, setFormError] = useState();
  const [ValidationError, setValidationError] = useState();
  const { dropDownSelect, currentSort } = useHeaderFilter();

  const isValidUrl = (url) => {
    const imageRegex = /\.(jpg|jpeg|png|gif)$/i;
    return imageRegex.test(url);
  };

  const videoSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    video_url: Yup.string()
      .matches(
        /^https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com)\/.+$/,
        "Invalid video URL"
      )
      .required("Video URL is required"),
    description: Yup.string().required("Description is required"),
    thumbnail: Yup.string().notRequired(),
  });
  const editVideoSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    video_url: Yup.string()
      .matches(
        /^https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com)\/.+$/,
        "Invalid video URL"
      )
      .required("Video URL is required"),
    description: Yup.string().required("Description is required"),
    thumbnail: Yup.string().nullable().required("Thumbnail is required."),
  });

  let initialData = {
    title: "",
    video_url: "",
    description: "",
    thumbnail: "",
  };
  useEffect(() => {
    if (!isValidUrl(videoActions.currentData.thumbnail)) {
      formik.setFieldValue("thumbnail", "");
      setThumbUrl("");
    } else {
      setThumbUrl(videoActions.currentData.thumbnail);
    }
    // isValidUrl(videoActions.currentData.thumbnail)
    //   ? setThumbUrl(videoActions.currentData.thumbnail)
    //   : setThumbUrl(Assets?.cover_img);

    // isValidUrl(videoActions.currentData.thumbnail);
  }, [videoActions]);

  // disappearing error messages
  useEffect(() => {
    setTimeout(() => {
      setFormError();
    }, 3000);
  }, [formError]);
  const formik = useFormik({
    validationSchema:
      videoActions?.mode == "edit" ? editVideoSchema : videoSchema,
    enableReinitialize: true,
    initialValues:
      videoActions?.mode == "edit" ? videoActions?.currentData : initialData,
    onSubmit: async (values, { setFieldError }) => {
      if (videoActions?.mode == "all") {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("video_url", values.video_url);
        formData.append("description", values.description);
        if (values.thumbnail) {
          formData.append("thumbnail", values.thumbnail);
        }
        const resultAction = await dispatch(addVideoLink(formData));
        if (addVideoLink.fulfilled.match(resultAction)) {
          handleCloseModal();
          let obj = {
            page: 1,
            slug:
              videoCurrentSort == "Latest" || videoCurrentSort == ""
                ? "desc"
                : "asc",
          };
          await dispatch(getVideoLinks(obj));
        } else {
          setFormError(resultAction?.payload?.validation?.errors);
          setValidationError(
            resultAction?.payload?.validation.errors?.video_url[0]
          );
          setTimeout(() => {
            setValidationError("");
          }, 2000);
        }
      } else {
        const formData = new FormData();
        formData.append("video_id", values.id);
        formData.append("title", values.title);
        formData.append("video_url", values.video_url);
        formData.append("description", values.description);
        if (values.thumbnail && changedThumb) {
          formData.append("thumbnail", values.thumbnail);
        }

        // formData.append('thumbnail', values.thumbnail && values.thumbnail);

        const resultAction = await dispatch(updateVideoLink(formData));
        if (updateVideoLink.fulfilled.match(resultAction)) {
          handleCloseModal();
          let obj = {
            page: 1,
            slug:
              videoCurrentSort == "Latest" || videoCurrentSort == ""
                ? "desc"
                : "asc",
          };
          await dispatch(getVideoLinks(obj));
        } else {
          setFormError(resultAction?.payload?.validation?.errors);
          setValidationError(
            resultAction?.payload?.validation.errors?.video_url[0]
          );
          setTimeout(() => {
            setValidationError("");
          }, 2000);
        }
      }
    },
  });
  const handleFileChange = (event) => {
    setChangedThumb(true);
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      formik.setFieldValue("thumbnail", file);
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setThumbUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      formik.setFieldError("thumbnail", "Please select valid image");
      toast.warning("Please select valid image", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRemove = () => {
    formik.setFieldValue("thumbnail", "");
  };

  const handleCloseModal = () => {
    close(false);
    formik.resetForm();
    setFormError("");
    setThumbUrl("");
  };

  return {
    formik,
    isCreatingLink,
    thumbUrl,
    videoActions,
    handleFileChange,
    handleRemove,
    handleCloseModal,
    ValidationError,
    formError,
    setFormError,
    isValidUrl,
  };
};
