import React from 'react'
import { useDimensions } from '../../../../Logic/Dimensions';
import Style from "../HistoryDrawer.module.scss";
import BackHeader from '../../../BackHeader';

const Header = ({ handleClose }) => {
    const windowDimensions = useDimensions();

    return (
        <>
            {windowDimensions.width >= 576 ? (
                <>
                    <h4 className={Style.HistoryDrawerWrapper_title}>
                        Version History
                    </h4>
                    <div className={Style.closeWrapper}>
                        <button onClick={handleClose}>x</button>
                    </div>
                </>
            ) : (
                <>
                    <BackHeader onClick={handleClose} />
                    <h4 className={`${Style.HistoryDrawerWrapper_title}`}>
                        Version History
                    </h4>
                </>
            )}
        </>
    )
}

export default Header