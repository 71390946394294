import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableImageSelection,
  enableImageSelection,
  callFunction,
} from "../../../store/slices/GalleryTabSlice";

export const useStared = () => {
  const dispatch = useDispatch();
  const [isOpenStared, setIsOpenStared] = useState(false);
  const profileGallery = useSelector((state) => state.profileGallery);
  const selections = useSelector((state) => state.galleryTab);


  const openFavSection = () => {
    // setIsOpenStared(true);
    let prevItems = profileGallery?.resultData?.data?.map((item) => item.id);

    const openFavoritePreSelection = (state) => {
      state.ImageProfileTab = {
        ...state.ImageProfileTab,
        openStared: true,
        selction: true,
        selectedImage: prevItems,
      };
    };
    dispatch(callFunction(openFavoritePreSelection));
  };

  const closeFavSection = () => {
    // setIsOpenStared(false);
    // dispatch(disableImageSelection({ tab: "user-profile" }));

    const closeFavoritePreSelection = (state) => {
      state.ImageProfileTab = {
        ...state.ImageProfileTab,
        openStared: false,
        selction: false,
        selectedImage: [],
      };
    };
    dispatch(callFunction(closeFavoritePreSelection));
  };

  return {
    selections,
    profileGallery,
    openFavSection,
    isOpenStared,
    closeFavSection,
  };
};
