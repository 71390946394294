import { Link, Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, ListGroup } from "react-bootstrap";
import { useDimensions } from "../../../../Logic/Dimensions";
import Assets from "../../../Layouts/Assets";
import avatar from "../../../../../src/assets/avatar.png";
import Style from "../../UserProfile.module.scss";

const ProfileContainer = ({ data }) => {
  const dispatch = useDispatch();
  const windowDimensions = useDimensions();

  const [tabLinkActive, setTabLinkActive] = useState("Overview");
  const [floater, setFloater] = useState({
    floaterWidth: 121,
    leftValue: 0,
  });
  let userData = data?.user_details?.[0] ? data?.user_details?.[0] : {};

  return (
    <>
      <div className={Style.user_profile}>
        <div className={Style.user_profile_header}>
          <figure className={Style.user_profile_cover_photo}>
            <img
              src={
                userData?.cover_photo?.large_thumbnail
                  ? userData?.cover_photo?.large_thumbnail
                  : Assets.cover_img
              }
              alt=""
            />
          </figure>
          <div className={Style.user_profile_row}>
            {windowDimensions.width >= 768 ? (
              <div className={Style.user_profile_profimg}>
                <figure>
                  <img
                    src={
                      userData?.profile_photo?.thumbnail
                        ? userData?.profile_photo?.thumbnail
                        : avatar
                    }
                    alt=""
                  />
                </figure>
              </div>
            ) : (
              <></>
            )}
            <div className={Style.user_profile_details}>
              <div className={Style.user_profile_details_row}>
                <div className={Style.user_profile_details_main}>
                  <div className={Style.user_profile_details_name_header}>
                    {windowDimensions.width < 768 ? (
                      <div className={Style.user_profile_profimg}>
                        <figure>
                          <img
                            src={
                              userData?.profile_photo?.thumbnail
                                ? userData?.profile_photo?.thumbnail
                                : avatar
                            }
                            alt=""
                          />
                        </figure>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={Style.user_profile_details_name_wrap}>
                      <h1 className={Style.user_profile_details_name}>
                        {data?.name}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              {windowDimensions.width >= 1280 ? (
                <>
                  <div className={Style.left_filtered_category_list}>
                    {routeData.map((item, index) => {
                      return (
                        <div
                          onClick={(e) => {}}
                          className={`${
                            tabLinkActive === item?.name
                              ? Style.left_filtered_category_list_active
                              : ""
                          }`}
                        >
                          <Link to={`${item.path}`} data-name={item?.name}>
                            {item?.name}
                          </Link>
                        </div>
                      );
                    })}
                    <div
                      className={Style.floater}
                      style={{
                        width: `${floater.floaterWidth}px`,
                        left: `${floater.leftValue}px`,
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Outlet context={[data.stared_image, data]} />
      </div>
    </>
  );
};

let routeData = [
  {
    name: "Overview",
    path: "",
  },
];

export default ProfileContainer;
