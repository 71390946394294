import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icons from "../components/Layouts/Icons";

import {
  customFetchImages,
  addImages,
  selectAllImages,
  unSelectAllImages,
  reArrangeImage,
  removeAllActions,
  getGalleryImages,
  filterImages,
  addToFavorites,
  removeFromFavorites,
  favoriteActions,
  reArrange,
  reArrangedItems,
  updateImageFunctions,
  addtoArchive,
  deleteImage,
  deleteImageAction,
  deleteMultipleImages,
  removeArchive,
  removeArchiveFunction,
  archiveActions,
  openPreSelect,
  closePreSelect,
  addImageTags,
  removeImageTags,
  addToStared,
  getStared,
  callFunction,
  addFavImages,
  reArrangeStared,
  reArrangeStaredImage,
  selectAllAlbumImages,
  unSelectAllAlbumImages,
} from "../store/slices/gallerySlice";

let rearrangeArray = [];

const useGallery = () => {
  const dispatch = useDispatch();
  const [isActiveOptions, setIsActiveOptions] = useState(false);
  const [preSelect, setPreSelect] = useState(false);
  const [editGallery, setEditGallery] = useState(false);
  const {
    galleryImages,
    selectedImages,
    currentPage,
    sections,
    currentTab,
    allImageStatus,
    archive,
    staredImages,
  } = useSelector((state) => state.gallery);

  const [optionDetails, setOptionDetails] = useState({
    text: "Select All",
    icon: <Icons icon={"action-select"} size={17} />,
  });

  const [slideShowOptionDetails, setSlideShowOptionDetails] = useState({
    text: "Select All",
    icon: <Icons icon={"action-select"} size={17} />,
  });

  const getUrls = (slug) => {
    switch (slug) {
      case "all":
        return "list-user-images";
        break;
      case "favorites":
        return "list-favourite";
        break;
      case "album":
        return "album-list";
        break;
      case "slideshow":
        return "list-slideshow";
        break;
      case "archive":
        return "list-archive";
        break;
    }
  };

  const textChangeHandler = () => {
    setOptionDetails((prevState) => {
      if (prevState.text == "Select All") {
        selectAllImageHandler();
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      } else {
        unSelectAllImagesHandler();
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      }
    });
  };

  useEffect(() => {
    let allImgIds = sections?.all?.data?.map((item, index) => item?.id);
    if (
      sections[currentTab]?.selectedImages?.length > 0 &&
      sections[currentTab]?.selectedImages?.length < allImgIds?.length
    ) {
      setOptionDetails((prevState) => {
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    } else if (
      sections[currentTab]?.selectedImages?.length > 0 &&
      sections[currentTab]?.selectedImages?.length == allImgIds?.length
    ) {
      setOptionDetails((prevState) => {
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      });
    }
  }, [sections[currentTab]?.selectedImages]);

  const slideShowChangeHandler = () => {
    setSlideShowOptionDetails((prevState) => {
      if (prevState.text == "Select All") {
        selectAllSlideShowHandler();
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      } else {
        unSelectAllImagesHandler();
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      }
    });
  };

  useEffect(() => {
    let allImgIds = sections?.slideshow?.data?.map((item, index) => item?.id);
    if (
      sections.slideshow?.selectedImages?.length > 0 &&
      sections.slideshow?.selectedImages < allImgIds
    ) {
      setSlideShowOptionDetails((prevState) => {
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    } else if (
      sections.slideshow?.selectedImages?.length > 0 &&
      sections.slideshow?.selectedImages?.length === allImgIds?.length
    ) {
      setSlideShowOptionDetails((prevState) => {
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    }
  }, [sections.slideshow?.selectedImages]);

  const addImageHandler = (img) => {
    dispatch(addImages(img));
  };

  const addFavImageHandler = (img) => {
    dispatch(addFavImages(img));
  };

  const selectAllImageHandler = () => {
    let imgIds = sections?.all?.data?.map((item, index) => item.id);
    dispatch(selectAllImages(imgIds));
  };

  const selectAllSlideShowHandler = () => {
    let slideIds = sections?.slideshow?.data?.map((item, index) => item.id);
    dispatch(selectAllImages(slideIds));
  };

  const unSelectAllImagesHandler = () => {
    dispatch(unSelectAllImages());
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
      },
    ];

    //rearrangeArray=[...rearrangeArray,...obj]

    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrange({ data: obj }));
    }
    dispatch(reArrangedItems(obj));
    dispatch(
      reArrangeImage({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const saveReArrange = () => { };

  const hideSelectHandler = () => {
    setPreSelect(false);
    dispatch(removeAllActions());
  };

  const getImagesForSection = (slug) => {
    let obj = {
      url: getUrls(slug),
      page: "1",
      slug: "desc",
    };

    //for rendering images to all section while at album and slideshow image selection

    let customTab =
      currentTab == "slideshow"
        ? "all"
        : currentTab == "album"
          ? "all"
          : currentTab;

    if (sections?.all?.data == undefined) {
      dispatch(getGalleryImages(obj));
    }
  };

  const getFavoritesImages = () => {
    let obj = {
      url: getUrls("favorites"),
      page: "1",
      slug: "desc",
    };

    if (sections?.favorites?.data == undefined) {
      dispatch(getGalleryImages(obj));
    }
  };

  const loadMore = (slug, type) => {
    if (type == "others") {

      if (sections["all"]?.current_page < sections["all"]?.last_page) {
        let obj = {
          url: getUrls(slug),
          page: sections["all"]?.current_page + 1,
          slug: sections["all"]?.currentActions?.filterOption
            ? sections["all"]?.currentActions?.filterOption
            : "",
        };
        dispatch(customFetchImages(obj));
      }
    } else {
      if (
        sections[currentTab]?.current_page < sections[currentTab]?.last_page
      ) {
        let obj = {
          url: getUrls(slug),
          page: sections[currentTab]?.current_page + 1,
          slug: sections[currentTab]?.currentActions?.filterOption
            ? sections[currentTab]?.currentActions?.filterOption
            : "",
        };
        dispatch(customFetchImages(obj));
      }
    }
  };

  const handleFilterImages = (slug) => {

    let obj = {
      url: getUrls(currentTab),
      page: 1,
      slug: slug,
    };
    dispatch(filterImages(obj));
  };

  const handleClickFavorite = (id) => {
    let newItem = sections[currentTab].data.filter((item, i) => item.id == id);
    if (newItem[0].favourite == 0) {
      dispatch(addToFavorites({ id: id }));
    } else {
      dispatch(removeFromFavorites({ id: id }));
    }
    dispatch(favoriteActions(id));
    let obj1 = {
      url: "list-favourite",
      page: "1",
      slug: "desc",
    };

    let keyArr = Object.keys(sections);
    if (currentTab == "all" && !keyArr.includes("favorites")) {
      dispatch(updateImageFunctions(obj1));
    }
  };

  const handleDeleteImages = (id) => {
    let image_id = [id];
    dispatch(deleteImageAction(id));
    dispatch(deleteImage({ image_id }));
  };

  const multiDelete = () => {
    let image_id = sections[currentTab].selectedImages;
    dispatch(deleteImage({ image_id }));
    dispatch(deleteMultipleImages());
  };

  const handleArchive = () => {
    let image_id = sections[currentTab].selectedImages;
    if (currentTab == "archive") {
      dispatch(removeArchive({ image_id }));
    } else {
      dispatch(addtoArchive({ image_id }));
    }
    if (archive?.data?.length > 0) {
      dispatch(archiveActions());
    }
  };

  const removeFromArchive = (id) => {
    let image_id = [id];
    dispatch(removeArchiveFunction(id));
    dispatch(removeArchive({ image_id }));
  };

  const addToTags = async (id, text) => {
    let imageIndex = sections[currentTab]?.data.findIndex(
      (item) => item.id == id
    );

    let data = {
      id: text,
      text: text,
    };

    const resultAction = await dispatch(
      addImageTags({ image_id: id, tag: [text] })
    );
    if (addImageTags.fulfilled.match(resultAction)) {
      const addTagsToState = (state) => {
        state.sections[currentTab].data[imageIndex] = {
          ...state.sections[currentTab].data[imageIndex],
          tags: [...state.sections[currentTab].data[imageIndex].tags, data],
        };
      };
      dispatch(callFunction(addTagsToState));
    }
  };

  const removeTags = async (id, text) => {
    let imageIndex = sections[currentTab].data.findIndex(
      (item) => item.id == id
    );

    const resultAction = await dispatch(
      removeImageTags({ image_id: id, tag: [text] })
    );

    if (removeImageTags.fulfilled.match(resultAction)) {
      const removeTagsToState = (state) => {
        state.sections[currentTab].data[imageIndex].tags = state.sections[
          currentTab
        ].data[imageIndex].tags.filter((item) => item.text !== text);
      };
      dispatch(callFunction(removeTagsToState));
    }
  };

  const handleAddToStaredImages = async () => {
    let image_id = sections.favorites.selectedImages;
    const resultAction = await dispatch(addToStared({ image_id }));
    if (addToStared.fulfilled.match(resultAction)) {
      toast.success("Images Add Success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const closeFavoritePreSelection = (state) => {
        state.sections.favorites = {
          ...state.sections.favorites,
          currentActions: { preselect: false },
          selectedImages: [],
        };
      };
      dispatch(callFunction(closeFavoritePreSelection));
      handleCloseFavItemsForStared();
    }
  };

  const getStaredImages = () => {
    if (staredImages?.data == undefined) {
      dispatch(getStared());
    }
  };

  const handleRemoveFromStaredImages = () => { };

  const handleClickOpenStaredActions = () => {
    let prevItems = staredImages?.data?.map((item) => item.id);

    const openFavoritePreSelection = (state) => {
      state.sections.favorites = {
        ...state.sections.favorites,
        currentActions: { preselect: true },
        selectedImages: prevItems,
      };
    };
    dispatch(callFunction(openFavoritePreSelection));
  };

  const handleFavItemsForStared = () => {
    const openFavorite = (state) => {
      state.staredImages = {
        ...state.staredImages,
        currentActions: { openFavoriteSection: true },
      };
    };
    dispatch(callFunction(openFavorite));
    handleClickOpenStaredActions();
  };

  const handleCloseFavItemsForStared = () => {
    const closeFavorite = (state) => {
      state.staredImages = {
        ...state.staredImages,
        currentActions: { openFavoriteSection: false },
      };
    };
    dispatch(callFunction(closeFavorite));
    clearFavoriteState();
  };

  const clearFavoriteState = () => {
    const clearFavoritePreSelection = (state) => {
      state.sections.favorites = {
        ...state.sections.favorites,
        currentActions: {},
        selectedImages: [],
      };
    };
    dispatch(callFunction(clearFavoritePreSelection));
  };

  const handleOpenReArrangeStaredImages = () => {
    const openStaredRearrange = (state) => {
      state.staredImages = {
        ...state.staredImages,
        currentActions: {
          openRearrange: true,
        },
      };
    };
    dispatch(callFunction(openStaredRearrange));
  };

  const handleCloseReArrangeStaredImages = () => {
    const openStaredRearrange = (state) => {
      state.staredImages = {
        ...state.staredImages,
        currentActions: {
          openRearrange: false,
        },
      };
    };
    dispatch(callFunction(openStaredRearrange));
  };

  const handleReArrangeStaredImages = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
        // album_id: albumId,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
        // album_id: albumId,
      },
    ];
    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrangeStared({ data: obj }));
    }
    dispatch(
      reArrangeStaredImage({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const clearStateAfterClosingAlbumModal = () => {
    const clearStateAfterModalClose = (state) => {
      state.sections.all = {
        ...state.sections.all,
        selectedImages: [],
      };
    };
    dispatch(callFunction(clearStateAfterModalClose));
  };

  return {
    editGallery,
    selectedImages,
    isActiveOptions,
    preSelect,
    staredImages,
    handleReArrange,
    setEditGallery,
    addImageHandler,
    hideSelectHandler,
    selectAllImageHandler,
    unSelectAllImagesHandler,
    getImagesForSection,
    setPreSelect,
    loadMore,
    // saveReArrange,
    handleFilterImages,
    handleClickFavorite,
    handleDeleteImages,
    multiDelete,
    handleArchive,
    removeFromArchive,
    allImageStatus,
    removeTags,
    addToTags,
    handleAddToStaredImages,
    handleRemoveFromStaredImages,
    getStaredImages,
    handleClickOpenStaredActions,
    handleFavItemsForStared,
    getFavoritesImages,
    addFavImageHandler,
    handleCloseFavItemsForStared,
    handleOpenReArrangeStaredImages,
    handleReArrangeStaredImages,
    handleCloseReArrangeStaredImages,
    textChangeHandler,
    optionDetails,
    setOptionDetails,
    slideShowOptionDetails,
    setSlideShowOptionDetails,
    slideShowChangeHandler,
    clearStateAfterClosingAlbumModal,
  };
};

export default useGallery;
