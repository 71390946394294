import { useDispatch, useSelector } from "react-redux";
import { removeImageFavorite } from "../../store/slices/favoriteSlice";
import { RemoveImages } from "../../store/slices/GalleryImagesSlice";
import { getStaredList } from "../../store/slices/ProfileGallery/profileGallerySlice";
import CommonModal from "../Modals/commonModal";
import Style from "./ImageDeleteModal.module.scss";
import { getProfileDetails } from "../../store/slices/userprofileSlice";

const ImageDeleteModal = ({ DeleteModal, setDeleteModal }) => {
  const dispatch = useDispatch();
  const Images = useSelector((state) => state.galleryAllTab);

  const handleDelete = async () => {
    let AllImages = Images?.resultData?.data?.filter(
      (item) => item.id == DeleteModal?.imageId
    );
    dispatch(getProfileDetails());

    const resultAction = await dispatch(
      RemoveImages({ image_id: DeleteModal?.imageId, data: AllImages })
    );

    if (RemoveImages.fulfilled.match(resultAction)) {
      if (AllImages[0]?.favourite == 1) {
        dispatch(removeImageFavorite({ image_id: DeleteModal?.imageId }));
      }
      setDeleteModal({});
      dispatch(getStaredList());
    }
  };

  return (
    <CommonModal
      showModal={DeleteModal?.open}
      hide={() => setDeleteModal(!DeleteModal?.open)}
      className={Style.delete_this_photo_modal}
      title={""}
      content={
        <>
          <h3 className={Style.delete_title}>Delete this Photo </h3>
          <p className={Style.delete_content}>
            {`This photo will be deleted from the library, it will be in the trash.`}
          </p>
          <div className={Style.delete_btn_wrap}>
            <button
              className={`${
                Images?.isDeleting == "loading" && Style.btn_loader
              } btn-danger`}
              onClick={() => handleDelete()}
            >
              {Images?.isDeleting !== "loading" ? "Continue" : ""}
            </button>
            <button
              className="btn-outline-gray"
              onClick={() => setDeleteModal(!DeleteModal?.open)}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
};

export default ImageDeleteModal;
