import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  allPageStatus: "loading",
  getTotalStatus: "idle",
  isDeleting: "idle",
  error: null,
  resultData: {
    data: [],
  },
};

export const getGalleryImages = createAsyncThunk(
  "galleryImages/getGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-user-images?page=${params.page}&&sort=${params.slug}&param=${params.param}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoadmoreImages = createAsyncThunk(
  "galleryImages/LoadmoreImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-user-images?page=${params?.page}&&sort=${params?.slug}&param=${params?.param}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const RemoveImages = createAsyncThunk(
  "galleryImages/RemoveImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllImagesTotalCount = createAsyncThunk(
  "galleryImages/getAllImagesTotalCount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-user-images?page=${params.page}&&sort=${params.slug}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const galleryImagesSlice = createSlice({
  name: "galleryImagesSlice",
  initialState,
  reducers: {
    updateFavorite: (state, action) => {
      if (state?.resultData?.data) {
        let index = state?.resultData?.data.findIndex(
          (obj) => obj.id === action?.payload?.id
        );
        if (index != -1) {
          state.resultData.data[index] = {
            ...state.resultData.data[index],
            favourite: action?.payload?.favorite,
            fav_position: action?.payload?.fav_position
          }
        }
      }
    },

    updateEditedImageToState: (state, action) => {
      state.resultData = {
        ...state.resultData,
        data: [action.payload, ...state.resultData.data],
        total: state.resultData.total + 1,
      };
    },
    removeImageFromState: (state, action) => {
      if (state?.resultData?.data) {
        let images = [...current(state?.resultData?.data)];
        action?.payload?.image_id.forEach((value, key) => {
          let objWithIdIndex = images.findIndex((obj) => obj.id == value);
          if (objWithIdIndex > -1) {
            images.splice(objWithIdIndex, 1);
            state.resultData.data = images;
            state.resultData.total = state.resultData.total - 1;
          }
        });
      }
    },

    updateAllPageStatus: (state, action) => {
      state.allPageStatus = action.payload;
    },

    updateAllImagesAfterFacesUpdate: (state, action) => {
      if (state?.resultData?.data) {
        let images = [...current(state?.resultData?.data)];
        action?.payload?.image_ids?.forEach((value, key) => {
          let objWithIdIndex = images.findIndex((obj) => obj.id == value);
          if (objWithIdIndex > -1) {
            images.splice(objWithIdIndex, 1);
            state.resultData.data = images;
            state.resultData.total = state.resultData.total - 1;
          }
        });
      }
    },
  },
  extraReducers: {
    [getGalleryImages.pending]: (state, action) => {
      state.status = "loading";
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.status = "succeeded";

      state.resultData = { ...action?.payload?.data?.result };
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getAllImagesTotalCount.fulfilled]: (state, action) => {
      state.getTotalStatus = "succeeded";
      state.resultData = {
        ...state.resultData,
        total: action?.payload?.data?.result?.total,
      };
    },

    [LoadmoreImages.fulfilled]: (state, action) => {
      state.resultData = {
        data: [
          ...state.resultData?.data,
          ...action?.payload?.data?.result?.data,
        ],
        current_page: action?.payload?.data?.result?.current_page,
        total: action?.payload?.data?.result?.total,
        sorting_options: [...state.resultData?.sorting_options],
        last_page: action?.payload?.data?.result?.last_page,
      };
    },

    [RemoveImages.pending]: (state, action) => {
      state.isDeleting = "loading";
    },

    [RemoveImages.fulfilled]: (state, action) => {
      state.isDeleting = "succeeded";
      if (state?.resultData?.data) {
        let images = [...current(state?.resultData?.data)];
        action?.meta?.arg?.image_id.forEach((value, key) => {
          let objWithIdIndex = images.findIndex((obj) => obj.id == value);
          if (objWithIdIndex > -1) {
            images.splice(objWithIdIndex, 1);
            state.resultData.data = images;
            state.resultData.total = state.resultData.total - 1;
          }
        });
      }
    },
    [RemoveImages.rejected]: (state, action) => {
      state.isDeleting = "failed";
    },

    ["tags/addTags/fulfilled"]: (state, action) => {
      let imgIndex = "";
      if (action.payload.data.result.id) {
        imgIndex = current(state?.resultData?.data).findIndex(
          (obj) => obj.id === action.payload.data.result.id
        );
      } else {
        imgIndex = current(state?.resultData?.data).findIndex(
          (obj) => obj.id === action.payload.data.result.image_id
        );
      }

      if (imgIndex !== -1) {
        let tagData = action.payload.data.result?.tags;
        state.resultData.data[imgIndex].tags = tagData;
      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      let imgIndex = state?.resultData?.data.findIndex(
        (obj) => obj.id === action.payload.data.result.id
      );
      if (imgIndex !== -1) {
        let tagData = action.payload.data.result.tags;
        state.resultData.data[imgIndex].tags = tagData;
      }
    },
    ["fileUpload/updateAllUploadedImages"]: (state, action) => {
      state.resultData = {
        ...state.resultData,
        data: [
          action.payload?.uploadResponse?.data?.result,
          ...state.resultData.data,
        ],
        total: state.resultData.total + 1,
      };
    },

    ["archive/addtoArchive/fulfilled"]: (state, action) => {
      let images = current(state.resultData.data);

      state.resultData = {
        ...state.resultData,
        data: images.filter(
          (item, index) => !action.payload.data.result.includes(item.id)
        ),
        total: state.resultData.total - action.payload.data.result?.length,
      };
    },

    ["archive/removeArchive/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        state.resultData = {
          ...state.resultData,
          data: [...action?.meta?.arg?.data, ...state.resultData.data],
          total: state.resultData.total + action?.meta?.arg?.data?.length,
        };
      }
    },

    ["trash/removeTrash/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        state.resultData = {
          ...state.resultData,
          data: [...action?.meta?.arg?.data, ...state.resultData.data],
          total: state.resultData.total + action?.meta?.arg?.data?.length,
        };
      }
    },

    ["album/editAlbum/fulfilled"]: (state, action) => {
      let images = current(state?.resultData?.data)
      if (images?.length > 0 && action?.meta?.arg?.albumData?.default) {
        state.resultData = {
          ...state.resultData,
          data: images.filter(
            (item, index) => !action.meta.arg.imageIds.includes(item.id)
          ),
          total: state.resultData.total - action?.meta?.arg?.imageIds?.length,
        };
      }
    },

    ["tags/addDescription/fulfilled"]: (state, action) => {
      let imgIndex = current(state?.resultData?.data).findIndex(
        (obj) => obj.id === action.meta.arg.image_id
      );
      if (imgIndex !== -1) {
        state.resultData.data[imgIndex].description = action.meta.arg.description;
      }
    },

    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },
  },
});

export const {
  updateFavorite,
  updateEditedImageToState,
  removeImageFromState,
  updateAllPageStatus,
  updateAllImagesAfterFacesUpdate,
} = galleryImagesSlice.actions;

export default galleryImagesSlice.reducer;
