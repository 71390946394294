import React, { useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import { useCancelPlan } from "../../Logic/ChoosePlans/useCancelPlan";
import { useDimensions } from "../../Logic/Dimensions";
import { usePreference } from "../../Logic/usePreference";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";
import DarkThemeToggler from "../DarkThemeToggler";
import Assets from "../Layouts/Assets";
import LoaderSuccessFailure from "../LoaderSuccessFailure";
import CommonModal from "../Modals/commonModal";
import Style from "./PreferenceMenu.module.scss";
import moment from "moment";
import { useSelector } from "react-redux";

const PreferenceMenu = () => {
  const {
    view,
    deleteFormObj,
    errorMessages,
    themes,
    availableLanguage,
    checkedOne,
    checkedTwo,
    checkedThree,
    deleteAccountModal,
    windowDimensions,
    languageCategory,
    changePasswordFormObj,
    setChangePasswordFormObjObj,
    setDeleteFormObj,
    setChangePasswordModal,
    setDeleteAccountModal,
    setTheme,
    setView,
    setDefaultViewActive,
    isDefaultViewActive,
    handleLanguageClick,
    handleSignMeAutomatically,
    handleAutoAdjustPositions,
    handleEnableEditAccess,
    languageCategorySwitch,
    ChangeTheme,
    deleteActionConfirmed,
    capitalizeFirstLetter,
    defaultViewChange,
    changePasswordModal,
    ShowPassword,
    ChangePasswordSubmit,
    IsPasswordChanging,
    LoginAgain,
    wasPasswordUpdated,
    handleChangeCurrentView,
    triggeruseEffect,
    isCurrentPassVisible,
    setIsCurrentPassVisible,
    isNewPassVisible,
    setIsNewPassVisible,
    isConfirmPassVisible,
    setIsConfirmPassVisible,
    openClearTreeModal,
    setOpenClearTreeModal,
    clearTree,
    isClearingTree,
    handleRemovePasswordModal,
  } = usePreference();

  const {
    isOpenCancelPlanModal,
    setOpenCancelPlanModal,
    handleOpenCancelModal,
    handleCloseCancelModal,
    handleCancelPlan,
    paymentCancelStatus,
    handleClickAfterPaymentCancel,
  } = useCancelPlan();
  const { planData } = usePurchasedChecker();
  const { userdata } = useSelector((state) => state.userprofile);

  useEffect(() => {
    triggeruseEffect();
  }, []);

  const { theme, themeToggler } = useDarkmode();

  const ele_exsting_password = useRef();
  const ele_new_password = useRef();
  const ele_confirm_password = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (deleteAccountModal || changePasswordModal) {
        document.querySelectorAll(".modal input")[0].focus();
      }
    }, 500);
  }, [deleteAccountModal, changePasswordModal]);

  return (
    <div className={Style.preference_menu}>
      <div className={Style.tab_item_title}>Preferences</div>
      <div className={Style.preference_container}>
        <div className={Style.preference_box}>
          <Link
            to={"update-info"}
            className={`${Style.preference_subtitle} ${Style.preference_link}`}
          >
            <div className={Style.pr_4}>
              <span className={Style.link_title}>Update Info</span>
              <div className={Style.preference_dis}>
                Update your basic info.
              </div>
            </div>
          </Link>
          <div></div>
        </div>

        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div className={Style.preference_subtitle}>Default View</div>
            <div className={Style.preference_dis}>
              Select default view for your gallery.
            </div>
          </div>
          <div className={`my-auto ${Style.preference_form_group}`}>
            {windowDimensions.width > 768 ? (
              <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle className={Style.language_bar_toggle}>
                  {`${capitalizeFirstLetter(view)} View`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      defaultViewChange("all");
                      handleChangeCurrentView("all");
                    }}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      defaultViewChange("album");
                      handleChangeCurrentView("album");
                    }}
                  >
                    Album
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <button
                  className={Style.language_select_btn}
                  onClick={() => {
                    setDefaultViewActive(true);
                  }}
                ></button>
              </>
            )}
          </div>
        </div>
        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div className={Style.preference_subtitle}>Language</div>
            <div className={Style.preference_dis}>
              More languages are coming soon...
            </div>
          </div>
          <div className={`my-auto ${Style.preference_form_group}`}>
            {windowDimensions.width > 768 ? (
              <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle className={Style.language_bar_toggle} disabled>
                  {/* {capitalizeFirstLetter(languageCategory?.name)} */}
                  English
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {availableLanguage.map((langObj, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => languageCategorySwitch(langObj)}
                        key={i}
                      >
                        {capitalizeFirstLetter(langObj.name)}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <button
                  className={Style.language_select_btn}
                  onClick={handleLanguageClick}
                ></button>
              </>
            )}
          </div>
        </div>
        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div className={Style.preference_subtitle}>Background Theme</div>
            <div className={Style.preference_dis}>
              Choose dark or light background theme.
            </div>
          </div>
          <div className={`my-auto ${Style.preference_form_group}`}>
            {windowDimensions.width > 768 ? (
              <>
                <div className={Style.preference_box_radio_wrap}>
                  <div
                    className={Style.preference_radio_holder}
                    onClick={() => {
                      setTheme("light");
                    }}
                    style={{ pointerEvents: themes === "light" ? "none" : "" }}
                  >
                    <label className="custom-checkbox" htmlFor="normal">
                      <input
                        type="radio"
                        id="normal"
                        value="normal"
                        checked={themes == "light"}
                        onClick={(e) => {
                          ChangeTheme(e);
                          themeToggler();
                        }}
                      ></input>
                      <div className="checkmark"></div>
                      <span
                        className={
                          themes === "light" ? Style.checkmark_checked : ""
                        }
                      >
                        Light
                      </span>
                    </label>
                  </div>
                  <div
                    className={Style.preference_radio_holder}
                    onClick={() => {
                      setTheme("dark");
                    }}
                    style={{ pointerEvents: themes === "dark" ? "none" : "" }}
                  >
                    <label className="custom-checkbox" htmlFor="dark">
                      <input
                        type="radio"
                        id="dark"
                        value="dark"
                        onClick={(e) => {
                          ChangeTheme(e);
                          themeToggler();
                        }}
                        checked={themes == "dark"}
                      ></input>
                      <div className="checkmark"></div>
                      <span
                        className={
                          themes === "dark" ? Style.checkmark_checked : ""
                        }
                      >
                        Dark
                      </span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
              </>
            )}
          </div>
        </div>
        <div className={Style.preference_box}>
          <Link
            to={"keybindings"}
            className={`${Style.preference_subtitle} ${Style.preference_link}`}
          >
            <div className={Style.pr_4}>
              <span className={Style.link_title}>Keyboard Shortcuts</span>
              <div className={Style.preference_dis}>
                View the default keybindings for easy use.
              </div>
            </div>
          </Link>
          <div></div>
        </div>
        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div
              className={`${Style.preference_subtitle} ${Style.preference_link}`}
              onClick={() => {
                setChangePasswordModal(true);
              }}
            >
              Change Password
            </div>
            <div className={Style.preference_dis}>
              Set new password for your account.
            </div>
          </div>
          <div></div>
        </div>
        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div className={Style.preference_subtitle}>Delete Account</div>
            <div className={Style.preference_dis}>
              Deleting an account means you will lose all your information
              forever.
            </div>
          </div>
          <div className={`${Style.preference_form_button} my-auto`}>
            <button
              onClick={() => setDeleteAccountModal(!deleteAccountModal)}
              className="btn btn-sm btn-red"
            >
              Delete
            </button>
          </div>
        </div>
        <div className={Style.preference_box}>
          <div className={Style.pr_4}>
            <div className={Style.preference_subtitle}>Clear Family Tree</div>
            <div className={Style.preference_dis}>
              Reset Tree to initial state
            </div>
          </div>
          <div className={`${Style.preference_form_button} my-auto`}>
            <button
              onClick={() => setOpenClearTreeModal((state) => !state)}
              className="btn btn-sm btn-red"
            >
              Clear
            </button>
          </div>
        </div>
        {userdata?.cancel_subscription && (
          <div className={Style.preference_box}>
            <div className={Style.pr_4}>
              <div className={Style.preference_subtitle}>
                Cancel Subscription
              </div>
              <div className={Style.preference_dis}>
                cancel within 7 days of subscription to get a full refund.
              </div>
            </div>
            <div className={`${Style.preference_form_button} my-auto`}>
              <button
                onClick={() => handleOpenCancelModal()}
                className="btn btn-sm btn-red"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>

      <CommonModal
        showModal={deleteAccountModal}
        hide={() => setDeleteAccountModal(!deleteAccountModal)}
        className={Style.delete_account_modal}
        title={""}
        content={
          <div className={Style.delete_account}>
            <h3 className={Style.delete_account_title}>Delete This Account</h3>
            <p className={Style.delete_account_description}>
              Once you delete your account , there's no getting it back. Make
              sure you want to do this.
            </p>

            <div
              className={`${
                deleteFormObj.error && Style.field_error
              } form-group`}
            >
              <input
                className={"form-control autofocus"}
                placeholder="Enter Your Password"
                id={"contact-name"}
                type={"text"}
                onChange={(e) => {
                  setDeleteFormObj({
                    ...deleteFormObj,
                    password: e.target.value,
                    error: false,
                  });
                }}
                value={deleteFormObj.password}
                autoFocus
              />
              {deleteFormObj.error && (
                <span className="error-msg">
                  {errorMessages[deleteFormObj.error_code]}
                </span>
              )}
            </div>

            <div className={Style.delete_account_btn_wrap}>
              <button className="btn btn-red" onClick={deleteActionConfirmed}>
                Yes, Delete it
              </button>
            </div>
          </div>
        }
      />

      <CommonModal
        showModal={isOpenCancelPlanModal}
        hide={() => handleCloseCancelModal()}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            {paymentCancelStatus == "idle" ? (
              <>
                <h3 className={Style.delete_title}>
                  Cancel your existing plan
                </h3>
                <p>Are you sure you want to Cancel your existing plan?</p>
                <div className={Style.delete_btn_wrap}>
                  <button
                    className={`"btn-danger" ${Style.btnItem} ${
                      isClearingTree ? "btn-loader-active" : ""
                    }`}
                    onClick={() => handleCancelPlan()}
                  >
                    {!isClearingTree && "Continue"}
                  </button>
                  <button
                    className="btn-outline-gray"
                    onClick={() => handleCloseCancelModal()}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <LoaderSuccessFailure status={paymentCancelStatus} />
                </div>
                <div className={Style.delete_btn_wrap}>
                  <button
                    className={`"btn-danger" ${Style.btnItem} ${
                      isClearingTree ? "btn-loader-active" : ""
                    }`}
                    onClick={() => handleClickAfterPaymentCancel()}
                  >
                    {paymentCancelStatus == "loading"
                      ? "please wait ..."
                      : "Continue"}
                  </button>
                </div>
              </>
            )}
          </>
        }
      />

      <CommonModal
        showModal={openClearTreeModal}
        hide={() => setOpenClearTreeModal((state) => !state)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>Clear Family Tree</h3>
            <p>
              Are you sure you want to clear the family tree? You can't undo
              this step!
            </p>
            <div className={Style.delete_btn_wrap}>
              <button
                className={`"btn-danger" ${Style.btnItem} ${
                  isClearingTree ? "btn-loader-active" : ""
                }`}
                onClick={() => clearTree()}
              >
                {!isClearingTree && "Continue"}
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => setOpenClearTreeModal((state) => !state)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />
      <CommonModal
        showModal={changePasswordModal}
        hide={() => handleRemovePasswordModal()}
        className={Style.delete_account_modal}
        title={""}
        content={
          !wasPasswordUpdated ? (
            <div className={Style.delete_account}>
              <h3 className={Style.delete_account_title}>Change Password</h3>
              <p className={Style.delete_account_description}></p>

              <div
                className={`${
                  changePasswordFormObj.error &&
                  changePasswordFormObj.code == 400 &&
                  Style.field_error
                } form-group`}
              >
                <label for="current-password">Current password *</label>
                <div className={Style.input_wrap}>
                  <input
                    className={"form-control autofocus"}
                    placeholder="Current Password"
                    id={"current-password"}
                    type={"password"}
                    ref={ele_exsting_password}
                    onChange={(e) => {
                      setChangePasswordFormObjObj({
                        ...changePasswordFormObj,
                        old_password: e.target.value,
                        error: false,
                      });
                    }}
                    value={changePasswordFormObj.old_password}
                    autoComplete="new-password"
                    autoFocus
                  />
                  <div
                    className={Style.icon_visibility}
                    onClick={() => {
                      setIsCurrentPassVisible(!isCurrentPassVisible);
                      ShowPassword(1500, ele_exsting_password);
                    }}
                  >
                    {!isCurrentPassVisible ? (
                      <img src={Assets.icon_eye_visible} alt="" />
                    ) : (
                      <img src={Assets.icon_eye} alt="" />
                    )}
                  </div>
                </div>
                {changePasswordFormObj.error &&
                  changePasswordFormObj.code == 400 && (
                    <span className="error-msg">
                      {errorMessages[changePasswordFormObj.error_code]}
                    </span>
                  )}
              </div>
              <div
                className={`${
                  changePasswordFormObj.error &&
                  changePasswordFormObj.code == 401 &&
                  Style.field_error
                } form-group`}
              >
                <label for="new-password">New password *</label>
                <div className={Style.input_wrap}>
                  <input
                    className={"form-control"}
                    placeholder="New Password"
                    id={"new-password"}
                    type={"password"}
                    ref={ele_new_password}
                    onChange={(e) => {
                      setChangePasswordFormObjObj({
                        ...changePasswordFormObj,
                        new_password: e.target.value,
                        error: false,
                      });
                    }}
                    value={changePasswordFormObj.new_password}
                  />

                  <div
                    className={Style.icon_visibility}
                    onClick={() => {
                      setIsNewPassVisible(!isNewPassVisible);
                      ShowPassword(1500, ele_new_password);
                    }}
                  >
                    {!isNewPassVisible ? (
                      <img src={Assets.icon_eye_visible} alt="" />
                    ) : (
                      <img src={Assets.icon_eye} alt="" />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  changePasswordFormObj.error &&
                  changePasswordFormObj.code == 401 &&
                  Style.field_error
                } form-group`}
              >
                <label for="confirm-password">Confirm password *</label>
                <div className={Style.input_wrap}>
                  <input
                    className={"form-control"}
                    placeholder="Confirm Password"
                    id={"confirm-password"}
                    type={"password"}
                    ref={ele_confirm_password}
                    onChange={(e) => {
                      setChangePasswordFormObjObj({
                        ...changePasswordFormObj,
                        confirm_password: e.target.value,
                        error: false,
                      });
                    }}
                    value={changePasswordFormObj.confirm_password}
                  />
                  <div
                    className={Style.icon_visibility}
                    onClick={() => {
                      setIsConfirmPassVisible(!isConfirmPassVisible);
                      ShowPassword(1500, ele_confirm_password);
                    }}
                  >
                    {!isConfirmPassVisible ? (
                      <img src={Assets.icon_eye_visible} alt="" />
                    ) : (
                      <img src={Assets.icon_eye} alt="" />
                    )}
                  </div>
                </div>
                {changePasswordFormObj.error &&
                  changePasswordFormObj.code == 401 && (
                    <span className="error-msg">
                      {errorMessages[changePasswordFormObj.error_code]}
                    </span>
                  )}
                <div className={Style.errors_list}>
                  <ul>
                    {!changePasswordFormObj.is_password_length_good &&
                      changePasswordFormObj.confirm_password?.length > 0 && (
                        <li>
                          {" "}
                          New password should contain at least 6 characters.
                        </li>
                      )}
                    {/* {!changePasswordFormObj.is_button_enabled &&
                      changePasswordFormObj.is_password_length_good && (
                        <li>Passwords does not match</li>
                      )} */}
                    {changePasswordFormObj.new_password !==
                      changePasswordFormObj.confirm_password &&
                      changePasswordFormObj.new_password !== "" &&
                      changePasswordFormObj.confirm_password !== "" && (
                        <li>Passwords does not match</li>
                      )}
                  </ul>
                </div>
              </div>

              <div className={Style.delete_account_btn_wrap}>
                <button
                  className="btn btn-border"
                  onClick={() => {
                    setChangePasswordModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-secondary"
                  disabled={!changePasswordFormObj.is_button_enabled}
                  onClick={ChangePasswordSubmit}
                >
                  {IsPasswordChanging ? <InfiniteDotLoader /> : "Save"}
                </button>
              </div>
            </div>
          ) : (
            <div className={Style.delete_account}>
              <h6 className={Style.delete_account_title}>Password changed</h6>
              <p className={Style.delete_account_description}>
                Your password has been successfully changed.
              </p>
              <div className={Style.delete_account_btn_wrap}>
                <button className="btn btn-green" onClick={LoginAgain}>
                  Login
                </button>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default PreferenceMenu;
