import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteSlideShowModal.module.scss";
import { useDeleteSlideShowModal } from "./useDeleteSlideShowModal";

const DeleteSlideShowModal = ({ deleteModal, setDeleteModal }) => {
  const { slideShow, handleDelete } = useDeleteSlideShowModal({
    setDeleteModal,
  });

  const DeleteModalContent = () => {
    return (
      <>
        <h3 className={Style.delete_title}>{`Delete ${
          slideShow?.selectedImage?.length > 1 ? "SlideShows" : "SlideShow"
        }`}</h3>
        <p className={Style.delete_content}>
          {`Are you sure you want to delete ${
            slideShow?.selectedImage?.length
          } 
                ${
                    slideShow?.selectedImage?.length > 1
                    ? "SlideShows"
                    : "SlideShow"
                }
                ?`}
        </p>
        <div className={Style.delete_btn_wrap}>
          <button className="btn-danger" onClick={() => handleDelete()}>
            Continue
          </button>
          <button
            className="btn-outline-gray"
            onClick={() => setDeleteModal({ popup: false })}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <CommonModal
      showModal={deleteModal.popup}
      hide={() => setDeleteModal({ popup: false })}
      className={Style.delete_this_photo_modal}
      title={""}
      content={<DeleteModalContent />}
    />
  );
};

export default DeleteSlideShowModal;
