import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useGallery from "../../Logic/useGallery";
import GalleryImage from "../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import Style from "../Gallery/ImageGallery/ImageGallery.module.scss";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";
import {
  currentDashboardPage,
  getMusicList,
  openSlideShowFullview,
} from "../../store/slices/gallerySlice";
import FullscreenPreview from "../Gallery/FullscreenPreview";
import { useSingleKey } from "../../utils/KeyBoardShortCuts";
import SlideShowFullViewTest from "../Slideshow/SlideShowFullViewTest";
import { useDispatch } from "react-redux";
import { useFavoritesImageViewer } from "../../Logic/FavoritesHooks/useFavoritesImageViewer";
import { useFavorites } from "./useFavorites";
import ImageListMasonry from "../ImageListMasonry";

const Favorites = () => {
  const {
    Images,
    selections,
    NodataContent,
    status,
    loadMore,
    rearrangeItems,
  } = useFavorites();

  return (
    <ImageListMasonry
      Images={Images?.resultData?.data}
      loadMore={loadMore}
      NodataContent={NodataContent}
      status={status}
      total={Images?.resultData?.total}
      options={selections}
      moveListItem={rearrangeItems}
    />
  );
};

export default Favorites;
