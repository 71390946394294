import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMusicList } from "../../../../store/slices/slideshow/QuickSliseShowSlice";
import { updateSlideDetails } from "../../../../store/slices/SlideShowDetailSlice";
import { transitions } from "./transitions";
import { useSlideShowEdit1 } from "../useSlideShowEdit1";
import { isEditedSlideShow } from "../../../../store/slices/slideshow/slideshowSlice";

export const useSlideShowControl = ({
  swiperRef,
  setPausePlay,
  pausePlay,
  setTime,
}) => {
  const audioRef = useRef(null);
  const [mute, setMute] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const quickSlideShow = useSelector((state) => state?.quickSlideShow);
  const slideShowDetail = useSelector((state) => state?.slideShowDetail);
 const {isEdited, setIsEdited}= useSlideShowEdit1()
  const slideDetail = useSelector(
    (state) => state.slideShowDetail.resultData.slideshow
  );
  const [openQuickSlideShow, setOpenQuickSlideShow] = useState(false);

  const closeQuickSlideShow = () => {
    setOpenQuickSlideShow(false);
  };

  const [effect, setEffect] = useState("fade-effect");
  const [inputWidth, setInputWidth] = useState(1.5);
  const dispatch = useDispatch();

  const getMusicIndex = () => {
    let musicIndex = 0;
    if (slideDetail?.music_id != null) {
      let indexI = quickSlideShow?.musicList.findIndex(
        (obj) => obj.id == slideDetail?.music_id
      );
      if (indexI > -1) {
        musicIndex = indexI;
      }
    }
    return musicIndex;
  };

  const getAudio = async () => {
    const resultAction = await dispatch(getMusicList());
    if (getMusicList.fulfilled.match(resultAction)) {
      setTrackIndex(getMusicIndex());
      audioRef.current = new Audio(
        resultAction?.payload?.data?.result[getMusicIndex()]?.file_name
      );
    }
  };

  useEffect(() => {
    //intial audio handling
    if (
      quickSlideShow?.musicList?.length == 0 ||
      slideDetail?.music_id != undefined
    ) {
      getAudio();
    } else {
      setTrackIndex(getMusicIndex());
      audioRef.current = new Audio(
        quickSlideShow?.musicList[getMusicIndex()]?.file_name
      );
    }
    return () => {
      audioRef?.current?.pause();
    };
  }, [slideDetail?.music_id]);

  useEffect(() => {
    // play and pause change handler
    if (swiperRef.current != null && audioRef.current != null) {
      if (pausePlay) {
        if (mute == false) {
          audioRef?.current?.play();
        }
      } else {
        audioRef?.current?.pause();
      }
    }
  }, [pausePlay]);

  useEffect(() => {
    // mute handler
    if (swiperRef?.current != null) {
      if (mute) {
        audioRef?.current?.pause();
      } else if (pausePlay) {
        audioRef?.current?.play();
      }
    }
  }, [mute]);

  const handleImageSelect = (i) => {
    setTrackIndex(i);
    dispatch(isEditedSlideShow(true))
    audioRef.current.src = quickSlideShow?.musicList[i].file_name;
    audioRef.current.load();
    if (pausePlay) {
      audioRef?.current?.play();
    }
    dispatch(
      updateSlideDetails({
        details: { ...slideDetail, music_id: quickSlideShow?.musicList[i].id },
      })
    );
  };

  const durationHandler = (e) => {
    setTime(0);
    dispatch(isEditedSlideShow(true))
    swiperRef?.current?.swiper?.slideTo(0);

    dispatch(
      updateSlideDetails({
        details: { ...slideDetail, duration: e.target.value },
      })
    );
    setInputWidth(e.target.value.length);
  };

  const handleIncrement = () => {
    // setIsEdited(true)
    setTime(0);
    dispatch(isEditedSlideShow(true))
    swiperRef?.current?.swiper?.slideTo(0);
    let inputVal = slideDetail?.duration;
    inputVal += 1;
    dispatch(
      updateSlideDetails({ details: { ...slideDetail, duration: inputVal } })
      );
  };

  const handleDecrement = () => {
    setTime(0);
    dispatch(isEditedSlideShow(true))
    swiperRef?.current?.swiper?.slideTo(0);
    let inputVal = slideDetail?.duration;
    if (inputVal > 1) {
      inputVal -= 1;
      dispatch(
        updateSlideDetails({ details: { ...slideDetail, duration: inputVal } })
      );
    }
  };

  const handleChangeEffect = (data) => {
   dispatch(isEditedSlideShow(true))
    dispatch(updateSlideDetails({ details: { ...slideDetail, effect: data } }));
  };

  const handlePausePlay = () => {
    setPausePlay(!pausePlay);
  };

  return {
    mute,
    setMute,
    musicList: quickSlideShow?.musicList,
    handleImageSelect,
    trackIndex,
    transitions,
    effect,
    inputWidth,
    durationHandler,
    handleIncrement,
    handleDecrement,
    handleChangeEffect,
    openQuickSlideShow,
    setOpenQuickSlideShow,
    closeQuickSlideShow,
    handlePausePlay,
    slideShowData: slideShowDetail.resultData.slideshow,
  };
};
