import { Formik, Form, Field } from "formik";
// import DatePicker from "react-datepicker";
import { useState } from "react";
import Style from "./TellUsForm.module.scss";
// import "react-datepicker/dist/react-datepicker.css";

const validateName=(value)=> {
  let error;
  if (!value) {
    error = "Required";
  }
  return error;
}
const validateFamilyName=(value)=> {
  let error;
  if (!value) {
    error = "Required";
  }
  return error;
}
const validateDate=(value)=> {
  let error;
  if (!value) {
    error = "Required";
  }
  return error;
}

export const TellUsForm = ({ type }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={Style.tellusform}>
      <div className={Style.formcontent}>
        <div className="h2">Tell Us About Your Family</div>
        <div className="h5">{type.toUpperCase()}</div>
        <div>
          <Formik
            initialValues={{
              fullname: "",
              familyname: "",
              dateofbirth: "",
              bio: "",
            }}
            onSubmit={(values) => {
              return values;
            }}
          >
            {({ errors, touched, isValidating }) => (
              <Form>
                <div>
                  <label htmlFor="fullname">Full Name</label>

                  <Field
                    placeholder="Enter full name"
                    name="fullname"
                    validate={validateName}
                    width="100%"
                  />
                  {errors.fullname && touched.fullname && (
                    <div>{errors.fullname}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="familyname">Family Name</label>

                  <Field
                    placeholder="Enter family name"
                    name="familyname"
                    validate={validateFamilyName}
                  />
                  {errors.familyname && touched.familyname && (
                    <div>{errors.familyname}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="dateofbirth">Date of Birth</label>

                  {/* <DatePicker placeholderText="DD/MM/YY"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  /> */}

                  {errors.dateofbirth && touched.dateofbirth && (
                    <div>{errors.dateofbirth}</div>
                  )}
                </div>
                <div>
                  <label htmlFor="bio">Bio</label>

                  <Field placeholder="Add bio" name="bio" />
                </div>

                <div><button className="commonBtn" type="submit">
                  Continue
                </button></div>

                <div className={Style.skipperson}>Skip Person</div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
