import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
import { useSelector } from "react-redux";
import CheckoutForm from "../../components/Checkout/CheckoutForm";
import Gpay from "./Gpay";
import CheckoutPlanDetails from "./CheckoutPlanDetails";
import Style from "./CheckOutComponent.module.scss";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import Assets from "../Layouts/Assets";
import PaymentAddress from "../Modals/PaymentAddress";

const CheckOutComponent = () => {
  const { REACT_APP_PUBLISHABLE_KEY, REACT_APP_PUBLISHABLE_TEST_KEY } = process.env;

  const stripePromise = loadStripe(REACT_APP_PUBLISHABLE_KEY);
  const { data } = useSelector((state) => state.payment);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: data?.details?.client_secret,
    appearance,
  };


  return (
    <>
      {data.details?.client_secret ? (
        <>
          <div className={Style.CheckOutComponent}>
            <div className={Style.CheckOutComponent__colLeft}>
              <CheckoutPlanDetails />
              <div className={Style.CheckOutPoweredBy}>
                Powered by
                <img src={Assets.stripe_logo} alt="" />
              </div>
            </div>
            <div className={Style.CheckOutComponent__colRight}>
              <div className={Style.CheckOutComponent__CheckoutForm}>
                {stripePromise && (
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                  </Elements>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <PaymentAddress />
        </>
      )}
    </>
  );
};

export default CheckOutComponent;

