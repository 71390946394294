import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
} from "../store/slices/authSlice";

import { emailUpdateForm, clearFormResult, updateFormSubmitState } from "../store/slices/emailUpdateSlice";

export const useEmailUpdate = () => {
  const dispatch = useDispatch();

  const [ContactFormSubmit, setContactFormSubmit] = useState([]);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [openEditEmail, setOpenEditEmail] = useState(false);

  const messageObj = {
    success: "Email has been updated successfully.",
    error: "Sorry, something went wrong! please try again later.",
  };

  const [error_message, setMessage] = useState(messageObj);
  const [submitButtonLabel, setSubmitButtonLabel] = useState("Save");

  const EmailChangeSubmit = (FormData, resetForm) => {
    setSubmitButtonLabel("Please wait...");
    dispatch(emailUpdateForm(FormData))
      .unwrap()
      .then((data) => {
        dispatch(updateFormSubmitState(FormData))
        const { success, result, message, code } = data.data;
        if (success) {
          resetForm();
          setSubmitButtonLabel("Save");
          setSuccess(true);
        } else {
          if (code === 400) {
            setMessage((state) => {
              return { ...state, ...{ error: "The entered mail id already exist." } }
            })
            setSubmitButtonLabel("Save");
            setFormError(true);
          } else if (code === 422) {
            setMessage((state) => {
              return { ...state, ...{ error: "Password is incorrect." } }
            })
            setSubmitButtonLabel("Save");
            setFormError(true);
          }


        }
      })
      .catch(function (reason) {

        setSubmitButtonLabel("Save");
        setFormError(true);

      });
  };

  const clearFormResponceDataFromRedux = () => {
    dispatch(clearFormResult());
  }

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    setTimeout(function () {
      setFormError(false);
    }, 5000);
  }, [formError]);
  // Click Event for section tab Family Members

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  return {
    EmailChangeSubmit,
    capitalizeFirstLetter,
    success,
    error_message,
    formError,
    submitButtonLabel,
    clearFormResponceDataFromRedux,
    openEditEmail,
    setOpenEditEmail
  };
};
