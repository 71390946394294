import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
} from "../store/slices/authSlice";

import { phoneUpdateForm, clearFormResult, updatePhoneData } from "../store/slices/PhoneUpdateSlice";

export const usePhoneUpdate = () => {
  const dispatch = useDispatch();

  const [openEditMobile, setOpenEditMobile] = useState(false);
  const [ContactFormSubmit, setContactFormSubmit] = useState([]);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  // const messageObj = {
  //   success: "Phone number has been updated successfully.",
  //   error: "Sorry, something went wrong! please try again later.",
  // };

  const [error_message, setMessage] = useState({
    success: "",
    error: ""
  });
  const [submitButtonLabel, setSubmitButtonLabel] = useState("Save");

  const PhoneChangeSubmit = async (filedData, { resetForm }) => {
    setSubmitButtonLabel("Please wait...");
    await dispatch(updatePhoneData(filedData))
    dispatch(phoneUpdateForm(filedData))
      .unwrap()
      .then((data) => {

        const { success, result, message, code } = data.data;

        if (code === 400) {
          setMessage((state) => {
            return { ...state, ...{ error: "Phone number already taken." } }
          })
          setSubmitButtonLabel("Save");
          setFormError(true);
          setTimeout(() => {
            setMessage({
              success: "",
              error: ""
            })
          }, 3000);
        } else if (code === 422) {
          setMessage((state) => {
            return { ...state, ...{ error: "Password is incorrect." } }
          })
          setSubmitButtonLabel("Save");
          setFormError(true);
          setTimeout(() => {
            setMessage({
              success: "",
              error: ""
            })
          }, 3000)
        }
        // } else {


        //   resetForm();
        //   setSubmitButtonLabel("Save");
        //   setSuccess(true);
        // }




      })
      .catch(function (reason) {

        setSubmitButtonLabel("Save");
        setFormError(true);

      });
  };

  const clearFormResponceDataPhoneOTPFromRedux = () => {
    dispatch(clearFormResult());
  }

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 5000);
  }, [success]);

  useEffect(() => {
    setTimeout(function () {
      setFormError(false);
    }, 5000);
  }, [formError]);
  // Click Event for section tab Family Members

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  return {
    PhoneChangeSubmit,
    capitalizeFirstLetter,
    success,
    error_message,
    formError,
    submitButtonLabel,
    clearFormResponceDataPhoneOTPFromRedux,
    openEditMobile,
    setOpenEditMobile,
    setMessage
  };
};
