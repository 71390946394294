import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Autoplay,
  Lazy,
  EffectFade,
} from "swiper";
import Style from "./QuickSlideShow1.module.scss";
import Icons from "../../Layouts/Icons";
import { useEffect } from "react";
import useAudioPlayer from "../../../Logic/useAudioPlayer";
import { useSingleKey } from "../../../utils/KeyBoardShortCuts";
import { ControlTab } from "./ControlTab";
import { useFullScreen } from "./useFullScreen";
import { useControls } from "./useControls";

import "swiper/css/effect-fade";
import { useLayoutEffect } from "react";

const QuickSlideShow1 = ({ slideObj, close, initialIndex }) => {
  //FIXME quickslideshow slider-delay issue pending
  // let musicData =
  //   slideObj?.music?.length > 0
  //     ? slideObj?.music
  //     : [
  //       {
  //         file_name:
  //           "https://api.familyphotosalbum.com/storage/music/20221230_63ae637e1d21d.mpeg",
  //         id: 1,
  //         name: "Music 1",
  //         path: "20221230_63ae637e1d21d.mpeg",
  //       },
  //     ];
  const [activeIndex, setActiveIndex] = useState(
    initialIndex ? initialIndex : 0
  );
  //FIXME set slideObj and initialIndex

  const [activeKey, setActiveKey] = useState(
    initialIndex
      ? slideObj?.images?.[initialIndex]?.id
      : slideObj?.images?.[0]?.id
  );
  const [limit, setLimit] = useState(initialIndex > 8 ? initialIndex + 5 : 8);
  const [playPause, setPlayPause] = useState(false);

  const { fullScreenRef, isFullscreen, fullScreenMode, controlsVisible, closeFullscreen, setControlsHoverVisible } =
    useFullScreen();
  const { trackIndex, playTrack, handleMute, mute, audioRef } = useAudioPlayer(slideObj?.music);

  useEffect(() => {

    return () => {
      audioRef?.current?.pause();
    }
  }, [])


  useEffect(() => {
    playTrack(trackIndex);
  }, []);

  useEffect(() => {
    if (!isFullscreen && fullScreenMode) {
      close();
      audioRef?.current?.pause();
    }
  }, [isFullscreen]);

  const handleEscapeFullScreen = () => {
    closeFullscreen();
    close()
    audioRef?.current?.pause();
  };


  const pauseToggle = () => {
    setPlayPause(!playPause);
    playTrack(trackIndex);
  };

  const previousHandler = () => {
    if (activeIndex !== 0) {
      setActiveIndex((prev) => {
        setActiveKey(slideObj?.images[prev - 1].id);
        if (limit > 8) {
          limit - 5 > prev && setLimit((l) => l - 1);
        }
        return prev - 1;
      });
    }
  };

  const nextHandler = () => {
    getImage();
  };

  const timer = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (!playPause) {
      timer.current = setTimeout(() => {
        getImage();
      }, 3000);
    }

    return () => clearTimeout(timer.current);
  });

  const getImage = () => {
    if (
      activeIndex < slideObj?.images?.length - 1 &&
      slideObj?.images.length > 0
    ) {
      setActiveIndex((prev) => {
        setActiveKey(slideObj?.images[prev + 1].id);
        prev + 3 > limit && setLimit((l) => l + 5);
        return prev + 1;
      });
    } else if (activeIndex == slideObj?.images?.length - 1) {
      setActiveIndex((prev) => {
        setActiveKey(slideObj?.images[0].id);
        setLimit(8);
        return 0;
      });
    }
  };


  useSingleKey("Escape", handleEscapeFullScreen);
  useSingleKey("ArrowLeft", previousHandler);
  useSingleKey("ArrowRight", nextHandler);

  // slideObj?.images
  return ReactDOM.createPortal(
    <div className={`${Style.slideshow_fullscreen} ${!controlsVisible && Style.hide_pointer}`} ref={fullScreenRef} >
      <div className={Style.back_btn} onClick={close}>
        <Icons icon={"back-arrow"} size={20} color="#ffff" />
      </div>

      <div>
        {slideObj?.images?.slice(limit - 8, limit).map((img, index) => (
          <figure
            className={`${Style.slideshow_fullscreen_image} ${img.id === activeKey ? "slide-in1" : "slide-out"
              } `}
            key={index}
          >
            <img src={img.src.original} alt="" ref={imageRef} />
          </figure>
        ))}
      </div>

      <ControlTab
        playPause={playPause}
        onPreviousHandler={previousHandler}
        onNextHandler={nextHandler}
        // onPauseToggle={pauseToggle}
        onPlayToggle={pauseToggle}
        onHandleMute={handleMute}
        onClose={close}
        isVisible={controlsVisible}
        setControlsHoverVisible={setControlsHoverVisible}
        // showPlayPause={showPlayPause}
        isMute={mute}
      />
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default QuickSlideShow1;
