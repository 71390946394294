
import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteAlbumModal.module.scss";
import { useDeleteAlbumModal } from "./useDeleteAlbumModal";

const DeleteAlbumModal = ({ deleteModal, setDeleteModal }) => {

    const { selections, handleDelete } = useDeleteAlbumModal({ setDeleteModal });

    const DeleteModalContent = () => {
        return (
            <>
                <h3 className={Style.delete_title}>{`Delete ${selections?.selectedImage?.length > 1
                    ? "Albums"
                    : "Album"
                    }`}</h3>
                <p className={Style.delete_content}>
                    {`Are you sure you want to delete ${selections?.selectedImage?.length
                        } 
                ${selections?.selectedImage?.length > 1
                            ? "Albums"
                            : "Album"
                        // currentTab == "album"
                        // ? "album"
                        // : `${
                        //     sections?.[currentTab]?.selectedImages?.length > 1
                        //         ? "photos"
                        //         : "photo"//FIXME delete album
                        //     }`
                        }
                ?`}
                </p>
                <div className={Style.delete_btn_wrap}>
                    <button className="btn-danger"
                        onClick={() => handleDelete()}
                    >
                        Continue
                    </button>
                    <button
                        className="btn-outline-gray"
                        onClick={() => setDeleteModal({ popup: false })}
                    >
                        Cancel 
                    </button> 
                </div>
            </>
        )

    }




    return (
        <CommonModal
            showModal={deleteModal.popup}
            hide={() => setDeleteModal({ popup: false })}
            className={Style.delete_this_photo_modal}
            title={""}
            content={<DeleteModalContent />}
        />
    )
}



export default DeleteAlbumModal;