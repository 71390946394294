import React, { useEffect, useRef, useState } from "react";
import Style from "../../Gallery/FullscreenPreview/FullscreenPreview.module.scss";
import Icons from "../../Layouts/Icons";

let MAX_ZOOM = 3;

const Zoom = ({
  imgData,
  currentIndex,
  allData,
  handleClose,
}) => {


  
  const scrollRef = useRef();
  const [zoom, setZoom] = useState(1);
  const [zoomHeight, setZoomHeight] = useState();
  const [objectFit, setObjectFit] = useState("contain");

  const handleZoom = (e) => {
    let scrollHeight = 0;
    let scrollWidth = 0;
    let { clientX, clientY } = e;
    let imgWidth = e.target.clientWidth;
    let imgHeight = e.target.clientHeight;
    if (zoom <= 1) {
      setObjectFit("cover");
      setZoomHeight(imgHeight * MAX_ZOOM);
      setZoom(MAX_ZOOM);
      clientY >= imgHeight / 2
        ? (scrollHeight = clientY * 1.3)
        : (scrollHeight = clientY / 1.3);
      clientX >= imgWidth / 2
        ? (scrollWidth = clientX * 1.3)
        : (scrollWidth = clientX / 1.3);
      setTimeout(() => {
        scrollRef.current.scrollTop = clientY + scrollHeight / 1.2;
        scrollRef.current.scrollLeft = clientX + scrollWidth / 1.2;
      }, 1);
    } else {
      setObjectFit("contain");
      setZoomHeight(imgHeight / MAX_ZOOM);
      setZoom(1);
    }
  };

  return (
    <div
      className={`${zoom >= MAX_ZOOM ? "zoomed_max" : ""} zoom_wrap ${
        Style.zoom_component
      }`}
    >
      <div className={Style.zoom_back_btn_wrap}>
        <button className={Style.zoom_back_btn} onClick={handleClose}>
          <Icons icon={"icon-close"} size={20} color="#ffff" />
        </button>
      </div>
      <figure className={Style.zoom_component_image} ref={scrollRef}>
        <img
          src={imgData?.original}
          alt=""
          style={{ height: `${zoomHeight}px`, objectFit: `${objectFit}` }}
          onClick={(e) => handleZoom(e)}
        />
      </figure>
      <p className={Style.zoom_images_count}>
        {currentIndex + 1 + "/" + allData?.length}
      </p>
    </div>
  );
};
export default Zoom;
