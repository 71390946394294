import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  triggerSearchAPI,
  clearData,
  showLoading,
} from "../store/slices/searchResultPageSlice";

let tempData = [];
let tags = [];
let locationDetail = [];

export const useSearchResult = () => {
  const [locationsData, setLocationsData] = useState([]);
  const [location, setLocation] = useState("");
  const [tag, setTag] = useState("");
  const [tagsCollections, setTagsCollections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [searchLocation, setSearchLocation] = useState("Location");
  const [searchTag, setSearchTag] = useState("Tag");
  const [searchDate, setSearchDate] = useState("Date");

  const [tagDataCollections, setTagDataCollections] = useState([]);

  const dispatch = useDispatch();
  const { query, option } = useParams();

  const { currentPage, totalPages, isLoading, results } = useSelector(
    (state) => state.searchResultPage
  );

  //INFINITY SCROLL

  const listInnerRef = useRef();
  const resultRef = useRef(null);
  const observer = useRef();

  // const scrollWork = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       if (
  //         isLoading == "idle" &&
  //         totalPages >= currentPage &&
  //         currentPage !== totalPages
  //       ) {
  //         setTimeout(function () {
  //           callSearchAPI(query, option, currentPage + 1);
  //         }, 1000);
  //       }
  //     }
  //   }
  // };

  const loadMore = () => {
    if (currentPage < totalPages) {
      setTimeout(function () {
        callSearchAPI(query, option, currentPage + 1);
      }, 1000);
    }
  };

  const getOptionLabel = function (option) {
    if (option != "") {
      option = option.toLowerCase();
      switch (option) {
        case "photos":
          return "Image";
          break;
        case "albums":
          return "Album";
          break;
        default:
          return "";
      }
    }
  };

  
  useEffect(() => {
    tempData?.map((ele) => {
      if (ele?.metadata?.locationDetail !== undefined) {
        locationDetail = [...locationDetail, ele?.metadata?.locationDetail];
      }
    });
    
    tempData?.map((ele) => {
      if (ele?.tags) {
        tags = [...tags, ...ele?.tags];
      }
    });

    const getUnique = (items, value) => {
      return [
        ...new Map(items.map((item) => [item[value], item.text])).values(),
      ];
    };

    let getUniqueLoactions = [...new Set(locationDetail)];

    setLocationsData(getUniqueLoactions);
    setTagsCollections(getUnique(tags, "text"));
  }, [results]);

  useEffect(() => {
    setSearchQuery(query);
    setSearchOption(option);
    callSearchAPI(query, option, 1);
  }, [query, option]);

  useEffect(() => {
    callSearchAPI(query, option, 1);
  }, [searchLocation, searchTag, searchDate]);

  const callSearchAPI = (query, option, page) => {
    if (
      searchLocation != "Location" ||
      searchTag != "Tag" ||
      searchDate != "Date"
    ) {
      dispatch(
        triggerSearchAPI({
          query: query,
          option: option,
          page: page,
          search_location: searchLocation,
          search_tag: searchTag,
          search_date: searchDate,
        })
      )
        .unwrap()
        .then((res) => {
          const { status } = res?.data;
          if (status && status !== undefined) {
          }
        });
    } else {
      dispatch(
        triggerSearchAPI({
          query: query,
          option: option,
          page: page,
          search_location: searchLocation,
          search_tag: searchTag,
          search_date: searchDate,
        })
      )
        .unwrap()
        .then((res) => {
          const { status } = res?.data;
          tempData = res?.data?.result?.data;

          if (status && status !== undefined) {
          }
        });
    }
  };

  return {
    searchQuery,
    setSearchQuery,
    searchOption,
    setSearchOption,
    getOptionLabel,
    query,
    option,
    listInnerRef,
    // scrollWork,
    searchLocation,
    setSearchLocation,
    searchTag,
    setSearchTag,
    searchDate,
    setSearchDate,
    tagDataCollections,
    setTagDataCollections,
    location,
    setLocation,
    locationsData,
    tag,
    setTag,
    tagsCollections,
    setTagsCollections,
    resultRef,
    observer,
    loadMore,
  };
};
