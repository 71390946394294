import React from "react";
import Assets from "../Layouts/Assets";
import Style from "./GalleryBlankPage.module.scss";

const GalleryBlankPage = ({ title, description, buttonText, handleClick, className, imagePath }) => {
  return (
    <div className={`${Style.gallery_blank_page} ${className}`}>
      <div className={Style.gallery_blank_page_wrap}>
        <figure className={Style.gallery_blank_page_image}>
          <img src={imagePath ? imagePath : Assets.file_upload_image} alt="photo_thumb" />
        </figure>
        <h2 className={Style.gallery_blank_page_title}>{title}</h2>
        <p className={Style.gallery_blank_page_description}>{description}</p>
        {buttonText ? (
          <button
            className={`${Style.gallery_blank_page_btn} btn btn-secondary`}
            onClick={handleClick}
          >
            {buttonText}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GalleryBlankPage;
