import { Dropdown } from "react-bootstrap";
import Style from "./SlideShowControl.module.scss";
// import { useSlideShowControl } from './useSlideShowControl';
import Icons from "../../../../components/Layouts/Icons";
import { useSlideShowControl } from "./useSlideShowControl";
import visualizer from "../../../../assets/musicVisualizer.gif";
import SlideShowFullView1 from "../../SlideShowFullView1";
import useAudioPlayer from "../../../../Logic/useAudioPlayer";

const SlideShowControl = ({
  swiperRef,
  setEffect,
  effect,
  slideData,
  slides,
  slideIndex,
  setSlideIndex,
  setPausePlay,
  pausePlay,
  setTime,
  loop,
  setLoop,
}) => {
  const {
    mute,
    setMute,
    musicList,
    handleImageSelect,
    trackIndex,
    transitions,
    inputWidth,
    durationHandler,
    handleIncrement,
    handleDecrement,
    handleChangeEffect,
    openQuickSlideShow,
    setOpenQuickSlideShow,
    closeQuickSlideShow,
    handlePausePlay,
    slideShowData,
  } = useSlideShowControl({ swiperRef, setPausePlay, pausePlay, setTime });

  const { playTrack } = useAudioPlayer(musicList);

  return (
    <ul className={Style.slideshow_options_list}>
      <li>
        <div
          className={Style.slideshow_options_audio}
          onClick={() => setMute(!mute)}
          title="mute"
        >
          <span>
            <Icons icon={mute ? "icon_mute" : "icon-volume"} size={15} />
          </span>
        </div>
      </li>
      <li>
        <Dropdown
          className={`${Style.slideshow_options_music} dropdown-lg`}
          autoClose="outside"
          title="music"
        >
          <Dropdown.Toggle
            className={`${Style.music_dropdown} ${"btn btn-border-grey"}`}
          >
            <div className={Style.music_icon}>
              <Icons icon={"icon-music"} size={15} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h4 className="dropdown_title">Music</h4>
            <div className={Style.listItems}>
              {musicList?.map((item, i) => {
                return (
                  <Dropdown.Item
                    key={item.id}
                    className={`${
                      slideShowData?.music_id == item?.id
                        ? Style.listItems_active
                        : ""
                    }`}
                    onClick={() => {
                      handleImageSelect(i);
                    }}
                  >
                    {item.name}
                    {
                      <span
                      // onClick={() => {playTrack(i)}}
                      >
                        <Icons
                          icon={
                            trackIndex == i && pausePlay
                              ? "icon-slideshow-pause"
                              : "icon-slideshow-play"
                          }
                          size={15}
                        />
                      </span>
                    }
                    {trackIndex == i && pausePlay && (
                      <img
                        className={Style.musicVisualizer}
                        src={visualizer}
                        alt=""
                      />
                    )}
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li>
        <Dropdown
          className={`${Style.slideshow_options_filter} dropdown-lg`}
          title="transitions"
        >
          <Dropdown.Toggle
            className={`${Style.filter_dropdown} ${"btn btn-border-grey"}`}
          >
            <div className={Style.filter_icon}>
              <Icons icon={"icon-filter"} size={15} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h4 className="dropdown_title">Transitions</h4>
            <div className="scroll-dropdown">
              {transitions?.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleChangeEffect(item?.effect)}
                    className={`${
                      slideShowData?.effect == item?.effect &&
                      Style.listItems_active
                    }`}
                  >
                    {item?.name}
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li>
        <div
          className={Style.slideshow_options_play}
          title={pausePlay ? "pause" : "play"}
        >
          {/* {!showPlayPause ? ( */}
          <Icons
            icon={pausePlay ? "icon-slideshow-pause" : "icon-slideshow-play"}
            size={15}
            onClick={() => handlePausePlay()}
          />
          {/* ) : (
                        <Icons
                            icon={"icon-slideshow-pause"}
                            size={15}
                            onClick={() => playSlideShow()}
                        />
                    )} */}
        </div>
      </li>
      <li className={`${Style.loopWrap} ${!loop ? Style.loopOff : ""}`}>
        <div
          className={Style.slideshow_options_loop}
          title="loop"
          onClick={() => setLoop(!loop)}
        >
          <Icons icon={"icon-loop"} size={15} />
        </div>
      </li>
      <li>
        <div className={Style.slideshow_options_fullscreen} title="fullscreen">
          {/* {showSlideShow ? ( */}
          <span onClick={() => setOpenQuickSlideShow(true)}>
            <Icons icon={"icon-fullscreen"} size={15} />
          </span>
          {/* ) : (
                        <span>
                            <Icons
                                icon={"icon-miniscreen"}
                                size={15}
                                onClick={() => setShowSlideShow(true)}
                            // onClick={() => document.exitFullscreen()}
                            />
                        </span>
                    )} */}
        </div>
      </li>
      <li>
        <Dropdown
          className={`${Style.slideshow_options_slide_duration} dropdown-lg`}
          title="duration"
        >
          <Dropdown.Toggle
            className={`${
              Style.slide_duration_dropdown
            } ${"btn btn-border-grey"}`}
          >
            <div>
              <Icons icon={"icon-duration"} size={15} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h4 className="dropdown_title">Image duration</h4>
            <div className="scroll-dropdown">
              <div className={Style.duration_inputs}>
                <button
                  className={`${Style.duration_decrement} ${Style.duration_modifier_btn}`}
                  onClick={() => {
                    handleDecrement();
                    // handleClickReset();
                  }}
                >
                  <Icons icon={"icon-decrement"} size={13} />
                </button>
                <div className={Style.duration_input}>
                  <input
                    type="number"
                    style={{ width: 1.5 + 0.5 + "ch" }}
                    value={slideData?.duration}
                    disabled
                    onChange={(e) => durationHandler(e)}
                  />
                  &nbsp;sec
                </div>
                <button
                disabled={slideData?.duration == 99}
                  className={`${Style.duration_increment} ${Style.duration_modifier_btn} ${slideData?.duration == 99 && Style.disabled_btn}`}
                  onClick={() => {
                    handleIncrement();
                    // handleClickReset();
                  }}
                >
                  <Icons icon={"icon-increment"} size={13} />
                </button>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      {openQuickSlideShow && (
        <SlideShowFullView1
          slideIndex={slideIndex}
          setSlideIndex={setSlideIndex}
          data={slideData}
          images={slides}
          close={closeQuickSlideShow}
          play={pausePlay}
          setPlay={setPausePlay}
          mute={mute}
          setMute={setMute}
        />
      )}
    </ul>
  );
};

export default SlideShowControl;
