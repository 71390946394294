import React from "react";
import DemoCard from "../DemoCard";
import Assets from "../Layouts/Assets";
import Style from "./QuickDemo.module.scss";

const QuickDemo = () => {
  return (
    <>
      <main className={Style.quick_demo}>
        <section className={Style.quick_demo_banner}>
          <figure className={Style.quick_demo_banner_image}>
            <img src={Assets.demo_banner} alt="demo-banner" />
          </figure>
          <div className="container">
            <h1 className={Style.quick_demo_banner_title}>
              View your demo to learn More about FPA
            </h1>
            <p className={Style.quick_demo_banner_description}>
              Watch a demo and know more about FPA
            </p>
          </div>
        </section>
        <section className={Style.quick_demo_videos}>
          <div className="container">
            <h2 className={Style.quick_demo_videos_title}>
              Choose a demo to start
            </h2>
            <div className={Style.quick_demo_videos_wrap}>
              {demoVideoData.map((data) => {
                return (
                  <DemoCard
                    key={data.id}
                    thumb={data.thumb}
                    title={data.title}
                    description={data.description}
                    path={data.path}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default QuickDemo;

const demoVideoData = [
  {
    id: 1,
    thumb: Assets.demo_1_thumb,
    title: "Create Account",
    description: "Demo video for creating an account",
    path: "demo1",
  },
  {
    id: 2,
    thumb: Assets.demo_2_thumb,
    title: "Upload photos",
    description: "Demo video for uploading Images",
    path: "demo2",
  },
  {
    id: 3,
    thumb: Assets.demo_3_thumb,
    title: "Slideshow",
    description: "Demo video for creating slideshows",
    path: "demo3",
  },
  {
    id: 4,
    thumb: Assets.demo_4_thumb,
    title: "Family Tree",
    description: "Demo video for creating Family Tree",
    path: "demo4",
  },
];
