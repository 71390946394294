
// import Style from "./UserProfileViewOnTree.module.scss";

const UserProfileViewOnTree = () => {
    
    
    return(
        <>
        View On Tree
        </>
    );
};
export default UserProfileViewOnTree;