import React from "react";
import CommonModal from "../../Modals/commonModal";
import Style from "./AlbumDeleteModal.module.scss";
import { useAlbumDeleteModal } from "./useAlbumDeleteModal";

const AlbumDeleteModal = ({ albumDeleteModal, setAlbumDeleteModal }) => {
  const { handleDeleteAlbum } = useAlbumDeleteModal({
    albumDeleteModal,
    setAlbumDeleteModal,
  });
  return (
    <CommonModal
      showModal={albumDeleteModal?.popup}
      hide={() => setAlbumDeleteModal({ popup: false, album: {} })}
      className={Style.delete_this_photo_modal}
      title={""}
      content={
        <>
          <h3 className={Style.delete_title}>Delete this Album</h3>
          <p className={Style.delete_content}>
            {`Are you sure you want to delete this album ?`}
          </p>
          <div className={Style.delete_btn_wrap}>
            <button
              className="btn-danger"
              onClick={(e) => handleDeleteAlbum(e)}
            >
              Continue
            </button>
            <button
              className="btn-outline-gray"
              onClick={() => setAlbumDeleteModal({ popup: false, album: {} })}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
};

export default AlbumDeleteModal;
