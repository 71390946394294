import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Style from "./country.module.scss";
import { useTranslation } from "react-i18next";
import countryWithFlag from "../country-code.json";
import Select from "react-select";

import countryData from "../../utils/countryData/index";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";

const CountryCodeInput = ({
  onNumChange,
  touched,
  errors,
  onBlur,
  values,
  placeholder,
  handleChangeHandler,
  handleChangeCode,
  handleChangeCountryCode,
  setFieldValue,
  currentAuthData,
  valueFilled,
}) => {
  const { i18n, t } = useTranslation(["authentication"]);
  const [countryDialCode, setCountryDialCode] = useState("+91");
  const [countryCode, setCountryCode] = useState("IN");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneChanged, setIsphoneChanged] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    label: "+91",
    value: "+91",
  });
  const { mobileVerifyData } = useSelector((state) => state.auth);
  const [cursorPosition, setCursorPosition] = useState(0);

  // useEffect(() => {
  //   setPhoneNumber(values?.phone_number);
  // }, [values]);

  useEffect(() => {
    handleChangeCode(countryDialCode);
    handleChangeCountryCode(countryCode);
  }, [countryDialCode]);
  useEffect(() => {
    if (
      mobileVerifyData?.phone_number &&
      mobileVerifyData?.country_dial_code &&
      valueFilled
    ) {
      let data = countryData?.filter(
        (item) => item?.value == mobileVerifyData?.country_dial_code
      );
      const userMobile = mobileVerifyData?.phone_number;
      setCurrentCountry(data[0]);
      setFieldValue("country_code", mobileVerifyData?.country_dial_code);
      setCountryDialCode(mobileVerifyData?.country_dial_code);
      setFieldValue("phone_number", userMobile);
      setFieldValue("country_dial_code", mobileVerifyData?.country_dial_code);
    }

    // if (currentAuthData?.phone_number && currentAuthData?.country_dial_code) {
    //   let data = countryData?.filter(
    //     (item) => item?.value == currentAuthData?.country_dial_code
    //   );

    //   setCurrentCountry(data[0]);
    //   setFieldValue("phone_number", currentAuthData?.phone_number);
    // }
  }, [values]);

  const [isSelectFocus, setIsSelectFocus] = useState(false);
  const [isInputFoucs, setIsInputFoucs] = useState(false);

  const handleChangeSelect = (e) => {
    let data = countryData?.filter((item) => item?.value == e.value);
    setCountryDialCode(e.value);
    setCurrentCountry(data[0]);
    setCountryCode(e.code);
  };

  return (
    <div
      className={`${errors && touched ? "form-group error" : "form-group"} ${
        isInputFoucs || isSelectFocus ? Style.countryCode_focused : ""
      }`}
    >
      <label htmlFor="signin_input_mobile">{`${t("register_phone")} *`}</label>
      <div className={`${Style.mobile_input} phone-number-input-wrap`}>
        <input
          type="number"
          name="phone_number"
          placeholder={placeholder}
          id="phone_number"
          value={phoneNumber}
          className="form-control"
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "+" ||
              e.key === "-" ||
              e.key === "." ||
              e.key === " " ||
              e.key === "Enter"
            ) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            handleChangeHandler(e);
            setCursorPosition(e.target.selectionStart);
            setPhoneNumber(e.target?.value);
            // setIsphoneChanged(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setIsInputFoucs(false);
          }}
          onFocus={() => setIsInputFoucs(true)}
        />
        <div className={Style.select_wrap}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={countryData[0]}
            value={currentCountry}
            isSearchable={true}
            options={countryData}
            onChange={handleChangeSelect}
            onFocus={() => setIsSelectFocus(true)}
            onBlur={() => setIsSelectFocus(false)}
            formatOptionLabel={(country) => (
              <div className="country-option">
                <ReactCountryFlag countryCode={country.code} svg />
                <span className={"country_code_name"}>{country.name}</span>
                <span>{country.value}</span>
              </div>
            )}
          />
        </div>
      </div>
      {errors && touched ? (
        <span className="field_error_message">{errors}</span>
      ) : null}
    </div>
  );
};

export default CountryCodeInput;
