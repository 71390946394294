
import React from "react";
import Style from "../FullscreenPreview.module.scss";
import moment from "moment";
import Icons from "../../../Layouts/Icons";
import sizeConverter from "../../../../utils/utilityHooks/sizeConverter";

const Details = ({ data, fullViewThumbIndex }) => {
    return (
        <div className={`${Style.info_details} ${Style.info_sec}`}>
            <h4
                className={`${Style.info_details_title} ${Style.info_sec_title}`}
            >
                Details
            </h4>

            <table className={Style.info_sec_main_table}>

                {data[fullViewThumbIndex]?.metadata?.file_date_time ? (
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon} colSpan={2}>

                            <Icons icon={"icon-calender"} size={18} />
                            <h5 className={Style.info_title}>
                                {" "}
                                {moment(
                                    data[fullViewThumbIndex]?.metadata?.file_date_time,
                                ).format("DD MMM YYYY hh:mm A")}
                            </h5>
                            <p className={Style.info_description}>
                                {/* Sun 10:14 AM &nbsp;&nbsp; GMT+05:30 */}
                            </p>

                        </td>
                    </tr>
                ) : (
                    ""
                )}

                {data[fullViewThumbIndex]?.original_name ? (
                    <>
                        <tr className={Style.info_details_item}>
                            <td className={Style.info_details_icon} colSpan={2}>

                                <Icons icon={"icon-image"} size={18} />

                                <h5 className={Style.info_title}>
                                    {data[fullViewThumbIndex]?.original_name}
                                </h5>

                            </td>
                        </tr>
                        <tr>
                            <td className={Style.info_details_icon} colSpan={2} align="left">

                                {data[fullViewThumbIndex]?.metadata?.file_size ? (

                                    <p className={Style.info_description}>
                                        {sizeConverter(
                                            data[fullViewThumbIndex]?.metadata?.file_size
                                        )}
                                    </p>

                                ) : (
                                    ""
                                )}
                            </td>
                        </tr>
                    </>
                ) : (
                    ""
                )}

                {data[fullViewThumbIndex]?.metadata?.location ? (
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            <Icons icon={"location"} size={18} />
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.location}
                            </h5>
                        </td>
                    </tr>
                ) : (
                    ""
                )}


                {data[fullViewThumbIndex]?.metadata?.make ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Make
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.make}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }
                {data[fullViewThumbIndex]?.metadata?.device ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Device
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.device}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[fullViewThumbIndex]?.width ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Dimensions
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.width} x {data[fullViewThumbIndex]?.height}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }


                {data[fullViewThumbIndex]?.metadata?.exposure_time ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            ExposureTime
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.exposure_time}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[fullViewThumbIndex]?.metadata?.iso ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            ISO
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.iso}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[fullViewThumbIndex]?.metadata?.aperture ?

                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Aperture
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[fullViewThumbIndex]?.metadata?.aperture}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[fullViewThumbIndex]?.metadata?.focal_length ?

                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Focal Length
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {eval(data[fullViewThumbIndex]?.metadata?.focal_length)} mm

                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }
            </table>
            <ul
                className={`${Style.info_details_list} ${Style.info_sec_content} ${Style.info_sec_content_table}`}
            >
            </ul>
        </div>
    )
}

export default Details;

