import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMusicList,
  openSearchSlideShow,
} from "../../../store/slices/slideshow/QuickSliseShowSlice";

export const useQuickSlideShowActions = (showFullView, setShowFullView) => {
  const dispatch = useDispatch();
  const quickSlideShow = useSelector((state) => state?.quickSlideShow);
  const galleryTab = useSelector((state) => state.galleryTab);
  const galleryAllTab = useSelector((state) => state.galleryAllTab);
  const [selectedImageForSlideShow, setSelectedImagesForSlideShow] = useState(
    []
  );

  useEffect(() => {
    if (quickSlideShow?.musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, []);

  useEffect(() => {
    if (
      quickSlideShow.searchSlideActions.isOpen == true &&
      quickSlideShow.searchSlideActions.type == "selected"
    ) {
      const imageIds = galleryTab.ImageAllTabSelection.selectedImage;
      setSelectedImagesForSlideShow(
        galleryAllTab?.resultData?.data?.filter((d) => {
          return imageIds?.includes(d?.id);
        })
      );
    }
  }, [quickSlideShow.searchSlideActions.isOpen]);

  const openQuickSlideShow = () => {
    if (quickSlideShow?.searchSlideActions?.type == "all") {
      setShowFullView({
        ...showFullView,
        isOpen: false,
      });
    }
    dispatch(openSearchSlideShow({ open: true, type: "all" }));
  };

  const closeQuickSlideShow = () => {
    if (quickSlideShow?.searchSlideActions?.type == "all") {
      setShowFullView({
        ...showFullView,
        isOpen: true,
      });
    }
    dispatch(openSearchSlideShow({ open: false, type: "all" }));
  };


  return {
    selectedImageForSlideShow,
    quickSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  };
};
