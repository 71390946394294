import React, { useEffect, useLayoutEffect } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loader from "../../../assets/images/loader/Spinner.svg";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";
import { handleSelectedAlbum } from "../../../store/slices/Album/AlbumDetails";
import { getMusicList } from "../../../store/slices/gallerySlice";
import { currentAlbumId } from "../../../store/slices/GalleryTabSlice";
import FullscreenPreviewNew from "../../Gallery/FullScreenPreviewNew";
import { useFullscreenPreviewActions } from "../../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { usePDFViewer } from "../../ImageListMasonry/usePDFViwer";
import ImageListMasonryAlbum from "../../ImageListMasonryForAlbum";
import Icons from "../../Layouts/Icons";
import PDFViewer from "../../PDFViewer";
import { useQuickSlideShowActions } from "../../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import QuickSlideShow1 from "../../Slideshow/QuickSlideShow1";
import Style from "./NewAlbumListView.module.scss";
import { useAlbumListView } from "./useAlbumListView";

const NewAlbumListView = ({
  data,
  handleOpenEditModal,
  handleOpenDelete,
  handleOpenSlideShow,
  handleUnLockAlbum,
  setOpenShowAlbum,
  setIsPassVisible,
  handleLock,
  selectedAlbumId,
  handleShareAlbum,
  studio_name,
}) => {
  const {
    collapseList,
    handleCollapse,
    selections,
    handleClickSelection,
    default_album_id,
    albumItemRef,
  } = useAlbumListView(data);

  const location = useLocation();

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();
  const { pdfOptions, handleOpenPdf, handleClosePdf } = usePDFViewer();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView, setShowFullView);

  const CustomToggle = ({ children, eventKey, className }) => {
    return (
      <button
        type="button"
        onClick={() => handleCollapse(eventKey)}
        className={className}
      >
        {children}
      </button>
    );
  };

  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(showFullView.isOpen);
  }, [showFullView.isOpen]);

  //old code
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { musicList, isLocking } = useSelector((state) => state.gallery);

  useLayoutEffect(() => {
    if (musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, []);

  const handleClickAlbumItem = (data) => {
    if (
      !selections?.ImageAlbumTab?.selction &&
      !data?.locked &&
      !data?.photofind_lock
    ) {
      dispatch(handleSelectedAlbum(data?.id));
      dispatch(currentAlbumId(data?.id));
      navigate(`/dashboard/album/${data?.id}`);
    }
  };

  const handleOpenPreview = (a, b, c, item, i) => {
    if (c?.file_type == 2) {
      handleOpenPdf(c);
    } else {
      if (item?.images?.some((item) => item.file_type == 2)) {
        let filterAlbumsData = item?.images?.filter(
          (item) => item?.file_type !== 2
        );
        let tempAlbumIndex = filterAlbumsData?.findIndex((a) => a.id == b);
        openPreview(tempAlbumIndex, b, item, i);
      } else {
        openPreview(a, b, item, i);
      }
    }
  };

  let slideData = {
    images:
      quickSlideShow?.slideActions.type == "all"
        ? showFullView?.imgArry
        : selectedImageForSlideShow,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };

  let previewData = {
    images: data?.[showFullView?.album_index]?.images?.filter(
      (item) => item?.file_type !== 2
    ),
    pathFrom: "gallery",
    section: "all",
  };

  let framedata = {
    id: data?.[showFullView?.album_index]?.frame_id,
    name: data?.[showFullView?.album_index]?.frameName,
    image: data?.[showFullView?.album_index]?.frame,
  };

  const DropdownMenu = ({ AlbumIndex, item }) => {
    return (
      <>
        <CustomToggle
          eventKey={`${AlbumIndex}`}
          className={`${Style.list_album_card_collapse} list-album-card-collapse`}
        >
          <Icons icon="icon-dropdown-arrow" size={12} />
        </CustomToggle>
        {!selections?.AddSlideShowSelection?.selction && (
          <Dropdown className={Style.list_album_card_options}>
            <Dropdown.Toggle className={Style.list_album_card_options_btn}>
              <Icons icon="icon-option-dots" size={12} />
            </Dropdown.Toggle>
            {item?.photofind_album ? (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleOpenEditModal(item);
                  }}
                >
                  Edit album
                </Dropdown.Item>

                {item?.images?.length > 0 ? (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        handleOpenSlideShow(item);
                      }}
                    >
                      Play slideshow
                    </Dropdown.Item>
                  </>
                ) : (
                  ""
                )}

                <Dropdown.Item
                  onClick={() => handleUnLockAlbum(item, AlbumIndex)}
                >
                  {item?.locked ? "Unlock Album" : "Lock Album"}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    handleShareAlbum(item);
                  }}
                >
                  Share
                </Dropdown.Item>

                {item?.images?.length > 0 ? (
                  <>
                    {/* <Dropdown.Item
                    onClick={() => {
                      handleOpenSlideShow(item);
                    }}
                  >
                    Play slideshow
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      handleShareAlbum(item);
                    }}
                  >
                    Share
                  </Dropdown.Item> */}
                  </>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            ) : (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleOpenEditModal(item);
                  }}
                >
                  Edit album
                </Dropdown.Item>

                {item?.images?.length > 0 ? (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        handleOpenSlideShow(item);
                      }}
                    >
                      Play slideshow
                    </Dropdown.Item>
                  </>
                ) : (
                  ""
                )}

                <Dropdown.Item
                  onClick={() => handleUnLockAlbum(item, AlbumIndex)}
                >
                  {item?.locked ? "Unlock Album" : "Lock Album"}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    handleShareAlbum(item);
                  }}
                >
                  Share
                </Dropdown.Item>

                {item?.id != default_album_id && !item?.is_document && (
                  <Dropdown.Item
                    onClick={() => handleOpenDelete(item?.id)}
                    style={{ color: "red" }}
                  >
                    Delete
                  </Dropdown.Item>
                )}
                {item?.images?.length > 0 ? (
                  <>
                    {/* <Dropdown.Item
                    onClick={() => {
                      handleOpenSlideShow(item);
                    }}
                  >
                    Play slideshow
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      handleShareAlbum(item);
                    }}
                  >
                    Share
                  </Dropdown.Item> */}
                  </>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        )}
      </>
    );
  };

  const CollapseHeadSideMenu = (i, item) => {
    return selections?.ImageAlbumTab?.selction ? (
      <>
        {item?.id != default_album_id &&
          !item?.locked &&
          !item?.photofind_album &&
          (selections?.ImageAlbumTab.selectedImage.includes(item.id) != true ? (
            <div className={Style.selection} />
          ) : (
            <figure className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={15} />
            </figure>
          ))}
      </>
    ) : (
      <>
        <DropdownMenu AlbumIndex={i} item={item} />
      </>
    );
  };

  return (
    //FIXME lock screen fix
    <div className={Style.list_album}>
      <Accordion
        defaultActiveKey={collapseList}
        activeKey={collapseList}
        flush
        alwaysOpen
      >
        {data?.map((item, i) => {
          const lastIndex = data?.length - 1;
          return (
            <div
              className={Style.list_album_item}
              key={i}
              ref={lastIndex == i ? albumItemRef : null}
              onClick={() => handleClickSelection(item)}
            >
              <Accordion.Collapse
                eventKey={`${i}`}
                className={`${
                  item?.images?.length === 0 || item?.images?.length == null
                    ? "no-images"
                    : ""
                }
                    ${
                      selections?.ImageAlbumTab?.selction &&
                      selections?.ImageAlbumTab.selectedImage.includes(
                        item.id
                      ) != true &&
                      item?.id != default_album_id &&
                      !item?.photofind_album &&
                      `${Style.preselection}`
                    }
                    `}
              >
                <div className={Style.list_album_images}>
                  {!item?.photofind_lock && (
                    <ImageListMasonryAlbum
                      Images={item?.images}
                      loadMore={{}}
                      openFullView={(a, b, c) => {
                        handleOpenPreview(a, b, c, item, i);
                      }}
                      albumData={item}
                    />
                  )}
                </div>
              </Accordion.Collapse>

              <div
                onClick={() =>
                  item?.locked ? handleUnLockAlbum(item?.id, i) : null
                }
                className={`${Style.list_album_card} 
                  ${
                    selections?.ImageAlbumTab?.selction &&
                    selections?.ImageAlbumTab.selectedImage.includes(item.id) !=
                      true &&
                    item?.id != default_album_id &&
                    !item?.photofind_album &&
                    `${Style.preselection}`
                  }
                  ${item?.locked || item?.photofind_lock ? Style.disabled : ""} 
                
                    list-album-card  `}
              >
                <CustomToggle
                  eventKey={`${i}`}
                  className={`${Style.accordion_toggle_overlay} ${"d-none"}`}
                />
                <div
                  className={Style.list_album_card_data}
                  onClick={() => handleClickAlbumItem(item)}
                >
                  <h4 className={Style.list_album_card_title}>
                    {item?.title?.length == 1
                      ? item?.title?.toUpperCase()
                      : item?.title}

                    <span>
                      {`${item?.images?.length ? item?.images?.length : 0}`}
                    </span>
                  </h4>
                </div>
                <div className={Style.list_album_card_menu}>
                  {studio_name ? <span>{studio_name}</span> : ""}

                  {/* {item?.photofind_album && item?.photofind_logo ? (
                    <img
                      src={item?.photofind_logo}
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    ""
                  )} */}
                  {item?.locked ? (
                    <div className={Style.lock_btn}>
                      <Icons icon={"lock"} size={18} />
                    </div>
                  ) : item?.id == selectedAlbumId && isLocking == "loading" ? (
                    <img src={loader} className={Style.spinner} alt="" />
                  ) : (
                    ""
                  )}
                  {item?.photofind_lock ? (
                    <div className={Style.lock_btn}>
                      <Icons icon={"lock"} size={18} />
                    </div>
                  ) : (
                    ""
                  )}

                  {CollapseHeadSideMenu(i, item)}
                </div>
              </div>
            </div>
          );
        })}
      </Accordion>

      {showFullView.isOpen && (
        <FullscreenPreviewNew
          total={previewData?.images?.length}
          previewObj={previewData}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
          frames={framedata}
          isShare={data[showFullView?.album_index]?.photofind_lock}
          isPhotoFindAlbum={data[showFullView?.album_index]?.photofind_album}
        />
      )}

      {quickSlideShow?.slideActions?.isOpen && (
        <QuickSlideShow1
          slideObj={slideData}
          close={closeQuickSlideShow}
          initialIndex={showFullView?.index}
        />
      )}
      {pdfOptions?.isOpen && pdfOptions?.url && (
        <PDFViewer url={pdfOptions?.url} handleClose={handleClosePdf} />
      )}
    </div>
  );
};

export default NewAlbumListView;
