import React from "react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation("faq");

  return (
    <>
      <div className="faq-page inner-page">
        <div className="container">
          <h1 className="policies-title">{t("faq_title")}</h1>
          <div className="policies-date">{t("faq_last_update")}</div>
          <div className="admin-content-area">
            <dl className="default">
              <dt>
                <span className="counter-span"></span>{t('faq_qn_1')}
              </dt>
              <dd>
              {t("faq_ans_1")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_2")}
              </dt>
              <dd>
              {t("faq_ans_2")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_3")}
              </dt>
              <dd>
              {t("faq_ans_3")} 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_4")}
              </dt>
              <dd>
              {t("faq_ans_4")} 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_5")}

              </dt>
              <dd>
              {t("faq_ans_5")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_6")}
              </dt>
              <dd>
              {t("faq_ans_6")}

              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_7")}

              </dt>
              <dd>
              {t("faq_ans_7")} 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_8")}
              </dt>
              <dd>
              {t("faq_ans_8")} 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_9")}

              </dt>
              <dd>
              {t("faq_ans_9")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_10")}

              </dt>
              <dd>
              {t("faq_ans_10")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_11")}
              </dt>
              <dd>
              {t("faq_ans_11")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_12")}

              </dt>
              <dd>
              {t("faq_ans_12")} 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_13")}

              </dt>
              <dd>
                <p>{t("faq_ans_13")}</p>
                <ol className="lower-alpha">
                  <li>
                  {t("faq_ans_13_a")}

                  </li>
                  <li>
                  {t("faq_ans_13_b")}
                  </li>
                  <li>
                  {t("faq_ans_13_c")}
                  </li>
                  <li>
                  {t("faq_ans_13_d")}
                  </li>
                  <li>
                  {t("faq_ans_13_e")}
                  </li>
                  <li>
                  {t("faq_ans_13_f")}

                  </li>
                </ol>
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_ans_14")}
              </dt>
              <dd>
              {t("faq_ans_14")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_15")}
              </dt>
              <dd>
              {t("faq_ans_15")}
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_16")}
              </dt>
              <dd>
              {t("faq_ans_16")}. 
              </dd>
              <dt>
                <span className="counter-span"></span>{t("faq_qn_17")}

              </dt>
              <dd>
              {t("faq_ans_17")}
              </dd>
            </dl>
            <i>{t("faq_dated")}</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
