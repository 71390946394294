import React, { useState } from "react";
import Icons from "../Layouts/Icons";
import CommonModal from "../Modals/commonModal";
import Style from "./ShareModal.module.scss";
import { Dropdown } from "react-bootstrap";
import { useEffect } from "react";
import download from "downloadjs";

const ShareModal = ({
  openShare,
  setOpenShare,
  from,
  secretToken,
  path,
  handleDuration,
  image,
  albumId,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [expireTime, setExpireTime] = useState("Select hour");
  const [showDurationAlert, setShowDurationAlert] = useState(false);

  useEffect(() => {
    if (!openShare) {
      setExpireTime("Select hour");
      setShowDurationAlert(false);
    }
  }, [openShare]);

  const QA_SHARE_URL = process.env.REACT_APP_SHAREAPI_LIVE_QA;
  const BASE_SHARE_URL = process.env.REACT_APP_SHAREAPI_LIVE;
  const WEBC_SHARE_URL = process.env.REACT_APP_SHAREAPI;

  let MainURL =
    process.env.REACT_APP_DEV_ENV == "PRODUTION"
      ? BASE_SHARE_URL
      : process.env.REACT_APP_DEV_ENV == "DEMO"
      ? WEBC_SHARE_URL
      : QA_SHARE_URL;

  let url = `${MainURL}${path}/${secretToken}`;

  let albumUrl = `${MainURL}${path}/${albumId}/${secretToken}`;

  let shareUrl = albumId ? albumUrl : url;

  // const copyTextToClipboard = async (text) => {
  //   if ("clipboard" in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand("copy", true, text);
  //   }
  // };

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator?.clipboard.writeText(text);
    } else {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    }
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };
  const handleLinkwrapclick = () => {
    if (expireTime === "Select hour") {
      setShowDurationAlert(true);
    }
  };

  const handleSelectDuration = (value) => {
    setExpireTime(value);
    handleDuration(value);
    setShowDurationAlert(false);
  };

  const handleShareImageOnWhatsApp = async () => {
    // let imageUrl = image?.src?.original
    // const shareUrl = `whatsapp://send?text=${encodeURIComponent("newImage")}&attachment=${encodeURIComponent(imageUrl)}`;
    // // Check if the WhatsApp application is installed
    // if (typeof navigator !== 'undefined' && navigator.share) {
    //   // Use the Web Share API if available
    //   navigator.share({
    //     title: 'Share on WhatsApp',
    //     text: "newImage",
    //     url: imageUrl,
    //   }).catch((error) => {
    //   });
    // } else {
    //   // Open the share URL in a new tab
    //   window.open(shareUrl, '_blank');
    // }
  };

  return (
    <CommonModal
      className={`${Style.share_modal}`}
      style={{ zIndex: "9999999" }}
      showModal={openShare}
      hide={() => setOpenShare(!openShare)}
      content={
        <>
          <h2 className={Style.share_modal_title}>Share to...</h2>
          <ul className={Style.share_modal_options}>
            {from !== "zoomView" ? (
              <li className={`d-flex ${Style.share_modal_option_duration}`}>
                <div>Link expires after</div>
                <div>
                  <Dropdown style={{ width: "100%" }}>
                    <Dropdown.Toggle className={Style.language_bar_toggle}>
                      {expireTime}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {durationData?.map((item, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => handleSelectDuration(item.value)}
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {showDurationAlert && (
                    <div className={Style.alert_not_select_duration}>
                      <p>Please select sharing duration</p>
                      <button
                        className={Style.alert_not_select_duration_btn}
                        onClick={() => setShowDurationAlert(false)}
                      >
                        <Icons color="#fff" size={7} icon={"icon-close"} />
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ) : (
              ""
            )}
            <div
              className={
                from !== "zoomView" &&
                expireTime === "Select hour" &&
                Style.shareLinks_notselect_Duration
              }
              onClick={handleLinkwrapclick}
            >
              <li className={Style.Share_link_wrap}>
                <div className={Style.share_modal_options_button}>
                  <Icons icon={"share-whatsapp"} size={16} />
                  <a
                    href={`https://api.whatsapp.com/send?text=${shareUrl}&type=custom_url&app_absent=0`}
                  >
                    Share to Whatsapp
                  </a>
                </div>
              </li>
              {/* <li>
              <button className={Style.share_modal_options_button}>
                <Icons icon={"share-email"} size={16} />
                <div>Share via Email</div>
              </button>
            </li> */}
              <li className={Style.Share_link_wrap}>
                <div className={Style.Share_link_wrap_row}>
                  <Icons icon={"share-copy-link"} size={16} />
                  <div className={Style.Share_link_wrap_url}>{shareUrl}</div>
                  <button
                    className={`btn btn-primary`}
                    onClick={() => handleCopyClick(shareUrl)}
                  >
                    Copy
                  </button>
                </div>
                {isCopied && (
                  <span className={Style.clip_to_board}>copied</span>
                )}
              </li>
              {/* <li className={Style.Share_link_wrap}>
                <div className={Style.Share_link_wrap_label}>
                  <Icons color='#085CFD' size={16} icon={'icon-image'} />
                  <span>Share as Image</span>
                </div>
                <ul className={Style.Share_link_list}>
                  <li>
                    <button className={Style.Share_link_btn} onClick={() => handleShareImageOnWhatsApp()}>
                      <span className={`${Style.Share_link_iconwrap} whatsapp`}>
                        <Icons color='#fff' size={12} icon={'share-whatsapp'} />
                      </span>
                      <span>Whatsapp</span>
                    </button>
                  </li>
                  <li>
                    <button className={Style.Share_link_btn}>
                      <span className={`${Style.Share_link_iconwrap} gmail`}>
                        <Icons color='#fff' size={12} icon={'share-email'} />
                      </span>
                      <span>Email Address</span>
                    </button>
                  </li>
                </ul>
              </li> */}
            </div>
          </ul>
        </>
      }
    />
  );
};

export default ShareModal;

let durationData = [
  {
    value: "1 hour",
    label: "1 hour",
  },
  {
    value: "2 hour",
    label: "2 hour",
  },
  {
    value: "5 hour",
    label: "5 hour",
  },
  {
    value: "24 hour",
    label: "24 hour",
  },
  {
    value: "48 hour",
    label: "48 hour",
  },
  {
    value: "7 days",
    label: "7 days",
  },
  {
    value: "14 days",
    label: "14 days",
  },
  {
    value: "Lifetime",
    label: "Lifetime",
  },
];
