import { Dropdown } from "react-bootstrap";
import Style from "./HistoryItem.module.scss";
import Icons from "../../Layouts/Icons";
import { useState } from "react";
import { useHistoryItem } from "./useHistoryItem";

const HistoryItem = ({
  data,
  // handleChange,
  changeTitle,
  // setChangeTitle,
  className,
  setActionRestore,
  setActionDelete,
  onSelectItem,
  isActive,
  ...props
}) => {

  const { title, editData, isFetchingVersionTitle, setTitle, setEditData, handleChangeTitle, handleDoubleClick } = useHistoryItem(data)

  return (
    <div
      className={`${Style.HistoryItem} ${className} ${isActive && "active"}`}
      {...props}
      onClick={() => {
        if (!editData?.showEdit) onSelectItem(data);
      }}
    >
      <div className={Style.HistoryItem_content}>
        {
          <span onDoubleClick={() => handleDoubleClick(data?.id)}
          >
            {!editData?.showEdit ? (
              <span
                // type="text"
                className={`${Style.HistoryItem_content_name}`}

              // value={title}
              >
                {title}
              </span>
            ) : (
              editData?.showEdit && (
                <input
                  type="text"
                  className={`
                ${Style.HistoryItem_content_name}
                ${editData?.showEdit && Style.HistoryItem_content_name_editActive}`}
                  placeholder={"enter title here"}
                  value={title}
                  onChange={handleChangeTitle}
                  onBlur={() => setEditData({
                    showEdit: false,
                    versionId: null
                  })}
                />
              )
            )}

            {data?.id == editData?.versionId &&
              <span className={Style.loader}>
                {isFetchingVersionTitle == "loading" ? "saving..." : ""}
              </span>
            }
          </span>
        }
        {
          data?.default == 1 &&
          <p className={Style.version_status}>
            Current Version
          </p>
        }
        {/* {data?.date && (
          <p className={Style.HistoryItem_content_date}
            onClick={() => {
              if (!editData?.showEdit) onSelectItem(data);
            }}
          >{data?.date}</p>
        )} */}
        {data?.status && (
          <p className={Style.HistoryItem_content_current_version}>
            {data?.status}
          </p>
        )}
      </div>
      {
        editData?.showEdit ?
          <div className={Style.close_btn} onClick={() => setEditData({
            showEdit: false,
            versionId: null
          })}>
            X
          </div>
          : ""
      }
      <div className={`${Style.HistoryItem_dropdown} ${editData?.showEdit ? Style.btn_show : ""}`}>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <Icons color="#000" size={16} icon={"icon-option-dots"} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setActionRestore(true)}>
              Restore this version
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setEditData({
              showEdit: true,
              versionId: data?.id
            })}>
              Add title
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActionDelete(true)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
};

export default HistoryItem;
