import React from "react";
import ReactDOM from "react-dom";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import Style from "./pdfViewer.module.scss";
import Icons from "../Layouts/Icons";
import { useDimensions } from "../../Logic/Dimensions";
import { useSocial } from "../../Logic/useSocial";
import ShareModal from "../ShareModal";

const PDFViewer = ({ url, data, handleClose }) => {
  const windowDimensions = useDimensions();
  const { fetchSocialDetails, secretToken, openShare, setOpenShare } =
    useSocial();

  const handleOpenShareModal = (id) => {
    setOpenShare(!openShare);
    fetchSocialDetails(id);
  };

  return ReactDOM.createPortal(
    <>
      <div className={Style.viewer}>
        <div className={Style.pdf_header}>
          <div className={Style.pdf_header_left}>
            <button className={Style.pdf_header_btn} onClick={handleClose}>
              <Icons
                icon={"back-arrow"}
                size={windowDimensions.width >= 1279 ? 20 : 14}
              />
            </button>
            {/* <button className={`btn btn-link ${Style.shareBtn}`} onClick={() => handleOpenShareModal(data?.currentId)}>
                            <Icons icon={"share2"} size={14} ></Icons>share
                        </button> */}
          </div>
        </div>

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: "100%", width: "600px", marginInline: "auto" }}>
            <Viewer fileUrl={url} />
          </div>
        </Worker>
      </div>
      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="zoomView"
        secretToken={secretToken}
        path={"view-content"}
        image={data?.currentId}
      />
    </>,
    document.getElementById("slideshow-root")
  );
};

export default PDFViewer;
