import React from "react";
import FullViewImage from "../../FullViewImage";
import ThumbImage from "../ThumbImage";
import Style from "../FullScreenPreviewNew.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  Lazy,
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  EffectCoverflow,
  EffectFade,
} from "swiper";
import { useState } from "react";

const SlideViewer = ({
  swiperRef,
  data,
  initialSlideIndex,
  setFullView,
  fullViewThumbs,
  setFullViewThumbs,
  handleSlideChange,
  fullViewThumbIndex,
  handleZoom,
  slideChangeHandler,
  initialIndex,
  slideCount,
  total,
  frames,
}) => {
  const [isActiveIndex, setIsActiveIndex] = useState(0);

  return (
    <>
      {/* <div className="custom_fraction" /> */}
      <div class="custom_fraction swiper-pagination-fraction swiper-pagination-horizontal">
        <span class="swiper-pagination-current">{isActiveIndex + 1}</span> /
        <span class="swiper-pagination-total">
          {" "}
          {total !== undefined ? total : 0}
        </span>
      </div>
      <Swiper
        ref={swiperRef}
        initialSlide={initialSlideIndex}
        onSwiper={setFullView}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        // pagination={{
        //   el: ".custom_fraction",
        //   type: "fraction",
        // }}
        effect={"fade"}
        spaceBetween={10}
        //  loop={true}
        navigation={true}
        thumbs={{ swiper: fullViewThumbs }}
        modules={[Lazy, FreeMode, Navigation, Thumbs, EffectFade, Pagination]}
        lazy={true}
        className={Style.full_view_slider}
        onSlideChange={(swiperCore) => {
          const { activeIndex } = swiperCore;
          handleSlideChange(activeIndex);
          setIsActiveIndex(activeIndex);
        }}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {index == fullViewThumbIndex ? (
                <FullViewImage
                  allData={data}
                  imgIndex={index}
                  initialIndex={fullViewThumbIndex}
                  count={slideCount}
                  data={item}
                  onClick={() => handleZoom()}
                  show={fullViewThumbIndex}
                  frameData={frames}
                />
              ) : (
                ""
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={Style.full_view_slider_thumb_wrap}>
        <Swiper
          onSwiper={setFullViewThumbs}
          spaceBetween={0}
          slidesPerView={"auto"}
          freeMode={false}
          watchSlidesProgress={true}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: "11",
          }}
          modules={[
            Lazy,
            FreeMode,
            Navigation,
            Thumbs,
            Pagination,
            EffectCoverflow,
          ]}
          className={Style.full_view_slider_thumb}
          draggable={false}
          allowTouchMove={false}
          preventClicks={true}
          centeredSlides={true}
          onSlideChange={(e) => slideChangeHandler(e)}
        >
          {data?.map((item, index) => {
            let newThumb = item?.src?.thumbnail
              ? item?.src?.thumbnail
              : item?.src?.thumbnail2;
            return (
              <SwiperSlide
                data-index={index}
                key={index}
                className={
                  index - fullViewThumbIndex >= 6
                    ? `slide_num_next_7`
                    : fullViewThumbIndex - index >= 6
                    ? `slide_num_prev_7`
                    : index > fullViewThumbIndex
                    ? `slide_num_next_${index - fullViewThumbIndex}`
                    : `slide_num_prev_${fullViewThumbIndex - index}`
                }
              >
                {index >= initialIndex - 5 && index <= initialIndex + 5 ? (
                  <ThumbImage id={item?.id} url={newThumb} />
                ) : (
                  ""
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default SlideViewer;
