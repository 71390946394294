import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  musicList: [
    {
      id: 6,
      name: "Music 6",
      file_name:
        "https://api.familyphotosalbum.com/storage/music/20231031_6540937c36982.mp3",
      path: "20231031_6540937c36982.mp3",
    },
  ],
  slideActions: {
    isOpen: false,
    type: "all",
  },
  searchSlideActions: {
    isOpen: false,
    type: "all",
  },
};

export const getMusicList = createAsyncThunk(
  "quickSlideShow/getMusicList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`music-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const quickSlideShowSlice = createSlice({
  name: "quickSlideShow",
  initialState,
  reducers: {
    openSlideShow: (state, action) => {
      state.slideActions = {
        isOpen: action.payload.open,
        type: action.payload.type,
      };
    },

    openSearchSlideShow: (state, action) => {
      state.searchSlideActions = {
        isOpen: action.payload.open,
        type: action.payload.type,
      };
    },
  },

  extraReducers: {
    [getMusicList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMusicList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.musicList = action.payload.data.result;
    },
    [getMusicList.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export const { openSlideShow, openSearchSlideShow } =
  quickSlideShowSlice.actions;

export default quickSlideShowSlice.reducer;
