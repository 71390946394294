import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logout,
} from "../store/slices/authSlice";

import { showLang, getMediaByID } from "../store/slices/imageViewerSlice";

export const useImageViewer = () => {
  const dispatch = useDispatch();
  const { media_id } = useParams();

  const [ImageViewerData, setImageViewerData] = useState([]);

  /****
   * Calling Media API by ID
   ****/

  useEffect(() => {
    CallMediaByID(media_id);
  }, [media_id]);

  const CallMediaByID = (id) => {
    dispatch(getMediaByID(id))
      .unwrap()
      .then((data) => {
        let res = data.data.result;
        setImageViewerData([res]);
      });
  };


  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  return {
    CallMediaByID,
    ImageViewerData,
  };
};
