import { useState } from "react";
import { getAxiosInstance } from "../../api";


export const useProfileshare = () => {
    const [openShare, setOpenShare] = useState(false);
    const [token, setToken] = useState("");
    const [expiresAt, setExpiresAt] = useState("");



    const generateToken = async (expiresAtValue) => {
        const api = await getAxiosInstance();
        const res = await api.get(`/token-generate?expired_at=${expiresAtValue}`);
        if (res.status == 200) {
            setToken(res?.data?.result)
        }
    }

    const handleDuration = (value) => {
        setExpiresAt(value)
        generateToken(value)
    }

    const handleShareProfile = () => {
        setOpenShare(true)
        generateToken(expiresAt)
    }

    return {
        token,
        openShare,
        setOpenShare,
        handleShareProfile,
        handleDuration
    }
}