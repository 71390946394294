import { useState, useEffect, useRef, useCallback } from "react";
import { getAxiosInstance } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  preUploadImage,
  updateActiveUploadPercentage,
  updateActiveIndex,
  updateUploadedImage,
  getFailedItems,
  singleImageUpload,
  addToThumbs,
  clearThumbs,
  callFunction,
  failedImages,
  duplicatedImages,
} from "../store/slices/gallerySlice";
import { useParams } from "react-router-dom";
import {
  openMiniUploadBox,
  openUploadModalAction,
} from "../store/slices/Gallery/fileUploadSlce";
import { updatePlanPercentage } from "../store/slices/userprofileSlice";

const TEST_URL = process.env.REACT_APP_APIURL;
const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

const TEMP_BASE_URL = "https://api.familyphotosalbum.com/api/v1/";

let MainURL =
  process.env.REACT_APP_DEV_ENV == "PRODUTION" ? BASE_URL : TEST_URL;

let images = [];
let uploadedImages = [];
let i = 0;

let newThumbs = [];

var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.avif|\.heic)$/i;

const useUploadImageModal = () => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoadingFirstItem, setIsLoadingFirstItem] = useState(false);
  const { galleryAllTab } = useSelector((state) => state);
  const {
    activeUploadIndex,
    isSingleImageUpload,
    activeUploadPercent,
    faileditems,
    thumbs,
    currentPage,
    failedImageIndexs,
    currentAlbumToImageUploaded,
    duplicatedImageIndexs,
  } = useSelector((state) => state.gallery);

  const { openMiniBox, openUploadModal } = useSelector(
    (state) => state.fileUpload
  );

  var date = new Date();
  const dispatch = useDispatch();

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let imgArry = [...e.dataTransfer.files];
    let newFilteredImgArry = imgArry?.filter((item, index) => {
      if (allowedExtensions.exec(item?.name)) {
        return item;
      }
    });

    if (allowedExtensions.exec(e.dataTransfer.files[0]?.name) == null) {
      setErrorMessage("Invalid file format");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }

    if (!isUploading && newFilteredImgArry?.length > 0) {
      uploadImage(newFilteredImgArry[images.length - activeUploadIndex]);
    }
    images = [...images, ...newFilteredImgArry];
    let keyArr = Object.keys(newFilteredImgArry);
    let fileArr = [];
    keyArr.map((a) => fileArr.push(newFilteredImgArry[a]));
    fileArr.map((a) => {
      resizeImage(a);
    });

    setIsDraggingOver(false);
  };

  useEffect(() => {
    if (!openMiniBox && openUploadModal && !isUploading && thumbs?.length > 0) {
      images = [];
      dispatch(updateActiveIndex(0));
      dispatch(clearThumbs());
    }
  }, [openUploadModal]);

  const handleImageChange = (e) => {
    let imgArry = [...e.target.files];

    if (allowedExtensions.exec(e.target.files[0]?.name) == null) {
      setErrorMessage("Invalid file format");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }

    let newFilteredImgArry = imgArry?.filter((item, index) => {
      if (allowedExtensions.exec(item?.name)) {
        return item;
      }
    });
    if (!isUploading && newFilteredImgArry?.length > 0) {
      uploadImage(newFilteredImgArry[images.length - activeUploadIndex]);
    }
    images = [...images, ...newFilteredImgArry];

    let keyArr = Object.keys(newFilteredImgArry);
    let fileArr = [];
    keyArr.map((a) => fileArr.push(newFilteredImgArry[a]));
    fileArr.map((a) => resizeImage(a));
  };

  useEffect(() => {
    return () => {
      dispatch(openMiniUploadBox(false));
      images = [];
      dispatch(updateActiveIndex(0));
    };
  }, []);

  const callbackInc = useCallback(() => {
    if (images.length > activeUploadIndex) {
      uploadImage(images[activeUploadIndex]);
    } else {
      setIsUploading(false);
    }
  }, [activeUploadIndex]);

  useEffect(() => {
    callbackInc();
  }, [callbackInc]);

  useEffect(() => {
    if (!openUploadModal && isUploading) {
      dispatch(openMiniUploadBox(true));
    }
  }, [openUploadModal]);

  const closeUploadModalWhenDone = () => {
    images = [];
    dispatch(openUploadModalAction(false));
    dispatch(updateActiveIndex(0));
    dispatch(clearThumbs());
  };

  const closeUploadModal = () => {
    dispatch(openUploadModalAction(false));
  };

  /********************  CANVAS  *******************/
  const resizeImage = (file) => {
    if (file) {
      let imageFile = file;
      let imageName = file.name;

      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, 300, 180);
          var dataUrl = canvas.toDataURL(imageFile.type);

          let thumbData = {
            id: i++,
            dataUrl: dataUrl,
            name: file.name,
            size: file.size,
            status: "idle",
          };

          dispatch(addToThumbs(thumbData));
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  };

  /********************  CANVAS  *******************/
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch(updateActiveUploadPercentage(percentCompleted));
    },
  };

  const uploadImage = async (file) => {
    if (activeUploadIndex == 0) {
      setIsLoadingFirstItem(true);
    }

    let data = new FormData();
    data.append("image", file);
    if (currentAlbumToImageUploaded != "") {
      data.append("album_id", currentAlbumToImageUploaded);
    }
    setIsUploading(true);

    const api = await getAxiosInstance();
    api
      .post(`${MainURL}upload-images`, data, config)
      .then((res) => {
        if (activeUploadIndex == 0) {
          setIsLoadingFirstItem(false);
        }
        dispatch(updateActiveIndex(activeUploadIndex + 1));

        if (currentAlbumToImageUploaded == "" && res.data.code == 200) {
          updateThumbStateAfterSuccesFullUpload(activeUploadIndex);
          dispatch(
            updateUploadedImage({
              data: res.data.result,
            })
          );
          setIsUploading(false);
        } else if (currentAlbumToImageUploaded == "" && res.data.code == 403) {
          dispatch(duplicatedImages(activeUploadIndex));
        }

        dispatch(updatePlanPercentage(galleryAllTab?.resultData?.total));
        let img = {
          id: res.data.result.id,
          favourite: 0,
          tags: [],
          position: null,
          metadata: res.data.result.metadata,
          src: res.data.result.src,
        };

        if (currentAlbumToImageUploaded != "") {
          updateAlbum(img, currentAlbumToImageUploaded);
        }
        setIsUploading(false);
      })
      .catch((err) => {
        // dispatch(failedImages(activeUploadIndex));
      });
  };

  const cancelUpload = (fileData, index) => {
    images = images.filter((item, i) => {
      return i !== index;
    });
    removeThumbsFromState(index);
  };

  const updateThumbStateAfterSuccesFullUpload = (itemIndex) => {
    const updateThumbs = (state) => {
      state.thumbs[itemIndex].status = "completed";
    };
    dispatch(callFunction(updateThumbs));
  };

  const removeThumbsFromState = (i) => {
    const removeThumbs = (state) => {
      state.thumbs = state.thumbs.filter((item, index) => index !== i);
    };
    dispatch(callFunction(removeThumbs));
    // images = [];
  };

  const addImagesToState = () => {
    const addSavedImages = (state) => {
      state.sections.all = {
        ...state.sections.all,
        data: [...uploadedImages, ...state.sections.all.data],
      };
    };
    dispatch(callFunction(addSavedImages));
  };

  const updateAlbum = (data, albumId) => {
    const updateAlbumStateAfterImageUpload = (state) => {
      state.sections.album.list[albumId].images = [
        ...state.sections.album.list[albumId].images,
        data,
      ];
      state.sections.album.list[albumId].total++;
    };
    dispatch(callFunction(updateAlbumStateAfterImageUpload));
  };

  return {
    images,
    thumbs,
    isDraggingOver,
    isUploading,
    setIsDraggingOver,
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    activeUploadIndex,
    activeUploadPercent,
    addImagesToState,
    uploadedImages,
    cancelUpload,
    closeUploadModal,
    closeUploadModalWhenDone,
    failedImageIndexs,
    errorMessage,
    isLoadingFirstItem,
    duplicatedImageIndexs,
  };
};

export default useUploadImageModal;

// location.pathname?.split("/")[3]
