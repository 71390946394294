import Style from "./Slideshow.module.scss";

import CommonModal from "../Modals/commonModal";
import GalleryBlankPage from "../BlankGallery";
import ImageGallery from "../Gallery/ImageGallery";
import SlideshowList from "../SlideshowList";
import Icons from "../Layouts/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useLayoutEffect, useRef } from "react";
import useGallery from "../../Logic/useGallery";
import useSlideShowEdit from "../../Logic/useSlideShowEdit";
import { useKey, useSingleKey } from "../../utils/KeyBoardShortCuts";
import Album from "../Album";
import { DashboardLoader } from "../DashboardLoader";

import { useSlideshow } from "./useSlideshow";

const Slideshow = () => {

  const { Selections, SlideShows, NodataContent, slideRef, status } =
    useSlideshow();

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);



  // useKey("KeyS", handleOpenPreSelect);
  // useKey("KeyX", handleUnSelectAllImage);
  // useKey("KeyA", handleSelectAllImages);
  // useSingleKey("Escape", handleEscape);

  return (
    <div className={Style.slideshow}>
      {Selections?.AddSlideShowSelection?.selction ? (
        <Album />
      ) : (
        SlideShows?.resultData?.data?.length > 0 && (
          <>
            <div className={Style.slideshow_list}>
              {SlideShows?.resultData?.data?.map((item, index) => {
                const lastIndex = SlideShows?.resultData?.data?.length - 1;
                return (
                  <SlideshowList
                    lastIndex={lastIndex}
                    index={index}
                    ref={slideRef}
                    item={item}
                    key={index}
                  />
                );
              })}
            </div>
          </>
        )
      )}


      {(!SlideShows?.resultData?.data || SlideShows?.resultData?.data == 0) && status == "succeeded" && NodataContent?.title != undefined && !Selections?.AddSlideShowSelection?.selction && (
        <GalleryBlankPage
          title={NodataContent?.title}
          description={NodataContent?.description}
          buttonText={NodataContent?.buttonText}
          handleClick={{}}
        />
      )}



      {status == "loading" ? (
        <DashboardLoader data={SlideShows?.resultData?.data} />
      ) : (
        ""
      )}

    </div>
  );
};

export default Slideshow;
