import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  error: null,
  resultData: {},
};

export const getStaredList = createAsyncThunk(
  "profileGallery/getStaredList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`stared`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToStared = createAsyncThunk(
  "profileGallery/addToStared",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-to-stared`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrangeStared = createAsyncThunk(
  "profileGallery/reArrangeStared",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`rearrange-stared`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileGallerySlice = createSlice({
  name: "profileGallery",
  initialState,
  reducers: {
    rearrange: (state, action) => {
      let draggedIndex = state.resultData.data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );
      let changedIndex = state.resultData.data.findIndex(
        (item) => item.id == action.payload.changedItem.id
      );

      state.resultData.data[draggedIndex].position =
        action.payload.changedItem.position;

      state.resultData.data[changedIndex].position =
        action.payload.draggedItem.position;

      let data1 = state.resultData.data[changedIndex];
      let data2 = state.resultData.data[draggedIndex];

      state.resultData.data[draggedIndex] = data1;
      state.resultData.data[changedIndex] = data2;

    },

    updateProfileGallery: (state, action) => {
      if (state.resultData.data) {
        state.resultData.data = state.resultData.data.filter(
          (item, index) => item?.id !== action.payload
        );
      }
    },
  },

  extraReducers: {
    [getStaredList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getStaredList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = action.payload.data.result;
    },
    [getStaredList.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addToStared.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData.data = action.payload.data.result;
    },

    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = "";
        if (action.payload.data.result.id) {
          imgIndex = current(state?.resultData?.data).findIndex(
            (obj) => obj.id === action.payload.data.result.id
          );
        } else {
          imgIndex = current(state?.resultData?.data).findIndex(
            (obj) => obj.id === action.payload.data.result.image_id
          );
        }
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags;
          state.resultData.data[imgIndex].tags = tagData;
        }
      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = state?.resultData?.data.findIndex(
          (obj) => obj.id === action.payload.data.result.id
        );
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result.tags;
          state.resultData.data[imgIndex].tags = tagData;
        }
      }
    },
    ["tags/addDescription/fulfilled"]: (state, action) => {
      if (state?.resultData?.data) {
        let imgIndex = current(state?.resultData?.data).findIndex(
          (obj) => obj.id === action.meta.arg.image_id
        );
        if (imgIndex !== -1) {
          state.resultData.data[imgIndex].description = action.meta.arg.description;
        }
      }

    },

    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },
  },
});

export const { rearrange, updateProfileGallery } = profileGallerySlice.actions;

export default profileGallerySlice.reducer;
