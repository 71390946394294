import React, { useEffect, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import { DashboardLoader } from "../DashboardLoader";
import Icons from "../Layouts/Icons";
import useImageEditComponent from "../../Logic/useImageEditComponent";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Style from "../ImageEdit/ImageEdit.module.scss";

const ImageEditComponent = () => {
  let navigate = useNavigate();

  const { isSaving, setIsSaving, imageData, setImageData, saveImage } =
    useImageEditComponent();
  const { path, itemId, image_id } = useParams();
  const checkPath = (path) => {
    let data = {};
    if (path == "album") {
      data = {
        name: "Album",
        url: `/dashboard/album/${itemId}`,
      };
    } else {
      data = {
        name: "Gallery",
        url: "/dashboard",
      };
    }
    return data;
  };

  const handleClickPath = () => {
    navigate(-1);
  };

  return (
    <div style={{ width: "100%", height: "92vh" }} className="filter-wrap">
      <a
        onClick={() => handleClickPath()}
        className="btn outline-light backto-gallery-btn"
        title={"Family Photos"}
      >
        <Icons icon={"back-arrow"} size={15} /> Back to {checkPath(path)?.name}
      </a>
      {isSaving == true ? (
        <DashboardLoader data={[]} />
      ) : (
        <>
          {imageData && (
            <FilerobotImageEditor
              source={imageData}
              onSave={(editedImageObject, designState) => {
                saveImage(editedImageObject);
              }}
              Text={{ text: "double click to edit text..." }}
              Rotate={{ angle: 90, componentType: "buttons" }}
              defaultTabId={TABS.ANNOTATE} // or 'Annotate'
              defaultToolId={TOOLS.TEXT} // or 'Text'
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImageEditComponent;
