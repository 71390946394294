import Style from "./SlideHeader.module.scss";
import Icons from "../../../../components/Layouts/Icons";
import { useDimensions } from "../../../../Logic/Dimensions";
import { useSlideHeader } from "./useSlideHeader";
import { useSlideShowEdit1 } from "../useSlideShowEdit1";
import { useSelector } from "react-redux";

const SlideHeader = ({ slideData, slides }) => {
  const windowDimensions = useDimensions();

  const {
    handleChange,
    inputFocusRef,
    isEditable,
    setIsEditable,
    enableEditing,
    handleClickGoBack,
    handleSave,
    slideDetail,
  } = useSlideHeader(slideData, slides);
  const { isEdited } = useSelector((state) => state.slideshow);
  return (
    <div className={Style.slideshow_header}>
      <button className={Style.back_button} onClick={() => handleClickGoBack()}>
        <Icons icon={"back-arrow"} size={20} />
        {windowDimensions.width >= 1200 && <span>Back</span>}
      </button>
      <div className={Style.slideshow_name}>
        <button
          className={Style.slideshow_rename_btn}
          onClick={() => enableEditing()}
        >
          <Icons icon="icon-edit-slideshow" size={22} />
        </button>
        <input
          name="name"
          placeholder="Type here..."
          value={slideData?.name}
          onChange={handleChange}
          spellCheck={false}
          disabled={!isEditable}
          ref={inputFocusRef}
          onBlur={() => setIsEditable(false)}
          onKeyDown={(e) => {
            e.key === "Enter" && e.target.blur();
          }}
        />
        {!isEditable && slideData?.name?.length >= 16 ? "..." : ""}
      </div>
      <div className={Style.slideshow_actions}>
        {isEdited && (
          <button
            disabled={slides?.length === 0}
            className={`${Style.slideshow_save} btn btn-border`}
            onClick={() => handleSave()}
          >
            {/* {isSlideShowSaving === "loading" ? "Saving..." : "Save"} */}
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default SlideHeader;
