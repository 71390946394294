const useDeleteRelations = (data, mainData) => {
  const isEndChild = () => {
    let tempData = mainData.filter(
      (m) => m.fid == data.id || m.mid == data.id || m.pids?.includes(data.id)
    );
    if (tempData.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const isEndPartner = () => {
    let hasFatherOrMother = data.mid || data.fid;
    let tempData = mainData.filter((m) => m.fid == data.id || m.mid == data.id);
    if (hasFatherOrMother || tempData.length != 0) {
      return false;
    } else {
      return true;
    }
  };

  const isEndParent = () => {
    let hasFatherOrMother = data.mid || data.fid;
    let children = mainData.filter((m) => m.fid == data.id || m.mid == data.id);
    let hasMoreThanOnePartner = data.pids?.length > 1;
    let hasPartnerForChildren = false;
    if (data.pids?.length == 1) {
      let partnerWithSameChild = mainData.filter(
        (m) =>
          (m.fid == data.id && m.mid == data.pids[0]) ||
          (m.mid == data.id && m.fid == data.pids[0])
      );
      hasPartnerForChildren = partnerWithSameChild.length == children.length;
    } else {
      hasPartnerForChildren = true;
    }
    if (
      hasFatherOrMother ||
      children.length > 1 ||
      hasMoreThanOnePartner ||
      !hasPartnerForChildren ||
      data.pids?.length > 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const dependencyCheck = () => {
    let idArray = [];
    let tempData = JSON.parse(JSON.stringify(mainData));
    let is_end_child = isEndChild();
    let is_end_partner = isEndPartner();
    let is_end_parent = isEndParent();

    if (is_end_child) {
      idArray.push(data.family_member_id);
    }

    if (is_end_partner) {
      idArray.push(data.family_member_id);
      let partnerIndex = tempData?.findIndex((d) => d.pids?.includes(data.id));
      let newPids = tempData?.[partnerIndex]?.pids;

      newPids?.splice(
        tempData[partnerIndex]?.pids?.findIndex((d) => d == data.id),
        1
      );
      tempData[partnerIndex] = {
        ...tempData[partnerIndex],
        pids: newPids,
      };
    }

    if (is_end_parent) {
      idArray.push(data.family_member_id);
      let partnerIndex = tempData.findIndex((d) => d.pids?.includes(data.id));
      let newPids = tempData[partnerIndex]?.pids;
      newPids?.splice(
        tempData[partnerIndex]?.pids?.findIndex((d) => d == data.id),
        1
      );
      tempData[partnerIndex] = {
        ...tempData[partnerIndex],
        pids: newPids,
      };
      mainData.map((m, i) => {
        if (m.fid == data.id) {
          tempData[i].fid = null;
        } else if (m.mid == data.id) {

          tempData[i].mid = null;
        }
      });
    }

    idArray.map((id) => {
      let index = tempData?.findIndex((d) => d.family_member_id == id);

      if (index != -1) {
        tempData.splice(index, 1);
      }
    });

    return { tempData, idArray, is_end_parent, is_end_child, is_end_partner };
  };

  return { dependencyCheck };
};

export default useDeleteRelations;
